import { useMemo } from 'react';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';

const getSubdomain = () => {
  let host = window.location.hostname;

  // TODO: Refactore code to make it like a map, key value
  // const mapOfDomains = {
  //   'portal.teleleaf.com': 'teleleaf',
  //   ...
  // }
  // return mapOfDomains[host];

  if (host == 'portal.heally.tech' || host == 'dsadsadad.dsadsadadsa.localhost') {
    return 'batman';
  }

  if (host == 'portal.xivix.co') {
    return 'xivix';
  }

  if (host == 'portal.teleleaf.com') {
    return 'teleleaf';
  }

  if (host == 'portal.joinminimal.com') {
    return 'minimal';
  }

  if (host == 'portal.leafymate.com') {
    return 'leafymate';
  }
  
  if (host == 'portal.leafydoc.com') {
    return 'leafydoc';
  }

  if (host == 'portal.wellnesswag.com') {
    return 'wellnesswag';
  }

  if (host == 'portal.utahcanna.org') {
    return 'utahcanna';
  }

  let parts = host.split('.');
  let domainLength = 3;
  if (parts.find(part => part === 'stage') !== undefined) {
    domainLength = 5;
  }

  if (parts?.length >= domainLength) {
    return parts[0];
  }
  return null;
};

const checkOnLocalStage = () => {
  const hostname = window.location.hostname;

  return hostname.indexOf('stage') >= 1 || hostname.indexOf('localhost') >= 1;
};

export const goToLocationHref = link => {
  window.location.href = link;
  return;
};

export const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const subdomain = getSubdomain();
  const isLocalStage = checkOnLocalStage();

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      },
      match,
      location,
      history,
      subdomain,
      subdomains: window.location.hostname?.split('.'),
      isPatient: location.pathname.indexOf('/patient_admin') >= 0,
      isVshop: location.pathname.indexOf('/vshop') >= 0,
      isLocalStage,
    };
  }, [params, match, location, history]);
};
