import React, { createContext, useContext, useState } from 'react';
import { request } from 'modules/client';

export const StateContext = createContext(null);

export default function AuthPatientProvider({ children }) {
  const DEFAULT_STATE = {
    data: [],
    error: null,
    status: 'idle',
  };
  const [password, setPassword] = useState({ status: 'idle', error: null });

  const resetPassword = values => {
    setPassword({ status: 'running', error: null });
    request(`/api/v2/registration/patients/password`, { method: 'POST', payload: values })
      .then(() => setPassword({ status: 'ready' }))
      .catch(error => setPassword({ status: 'error', error }));
  };

  const contextValue = {
    resetPassword,
    password,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useAuthPatient() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useAuthPatient must be used with AuthPatientProvider');
  }
  return context;
}
