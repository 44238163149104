import React, { createContext, useContext, useState } from 'react';
import { request } from 'modules/client';

import { dataFormatter } from 'utils/connectPartnerConfigs';

export const StateContext = createContext(null);

export default function VisitProvider({ children }) {
  const DEFAULT_STATE = {
    status: 'idle',
    data: null,
    error: '',
  };
  const [creditCard, setCreditCard] = useState(DEFAULT_STATE);

  const initialSetup = async () => {
    return request(`/v1/patient_admin/visit/initial_setup?visit_type=online`).then(response =>
      dataFormatter.deserialize(response),
    );
  };

  const fetchCreditCardToken = async () => {
    return request(`/v1/patient_admin/visit/card_token`).then(response =>
      dataFormatter.deserialize(response),
    );
  };

  const fetchCreditCardDetails = async () => {
    setCreditCard({ status: 'running', data: null, error: '' });
    return request(`/v1/patient_admin/visit/card`)
      .then(response => {
        const data = dataFormatter.deserialize(response);
        setCreditCard(prevState => ({ ...prevState, data, status: 'ready' }));
      })
      .catch(err => setCreditCard({ status: 'error', data: null, error: err }));
  };

  const deleteCreditCard = async () => {
    return request(`/v1/patient_admin/visit/card`, { method: 'DELETE' }).then(response => response);
  };

  const addCreditCard = async payload => {
    return request(`/v1/patient_admin/visit/card`, { method: 'POST', payload }).then(response =>
      dataFormatter.deserialize(response),
    );
  };

  const createPayment = async () => {
    return request(`/v1/patient_admin/visit/payment`, { method: 'POST' }).then(
      response => response,
    );
  };

  const applyDiscountCode = async payload => {
    return request(`/v1/patient_admin/visit/coupon_setup`, { method: 'POST', payload }).then(
      response => dataFormatter.deserialize(response),
    );
  };

  const updateCreditCard = data => setCreditCard(prevState => ({ ...prevState, data }));

  const contextValue = {
    creditCard,
    initialSetup,
    fetchCreditCardToken,
    fetchCreditCardDetails,
    deleteCreditCard,
    addCreditCard,
    createPayment,
    applyDiscountCode,
    updateCreditCard,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useVisit() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useVisit must be used with VisitProvider');
  }
  return context;
}
