import React, { createContext, useContext } from 'react';

import { request } from 'modules/client';
import { dataFormatter } from 'utils/connectPartnerConfigs';

const StateContext = createContext(null);

export default function MarketingProvider({ children }) {
  const sendSwitchTrack = async payload => {
    return request(`/api/v2/marketing/patient/track/switch`, { method: 'POST', payload }).then(
      response => dataFormatter.deserialize(response),
    );
  };

  const contextValue = {
    sendSwitchTrack,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useMarketing() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useMarketing must be used with MarketingProvider');
  }
  return context;
}
