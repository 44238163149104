import React from 'react';

export default function SvgComponent({ style, width, height }) {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 6.333A3.17 3.17 0 0015.833 9.5 3.17 3.17 0 0019 12.667 3.17 3.17 0 0022.166 9.5 3.17 3.17 0 0019 6.333zm0 4.75c-.873 0-1.584-.71-1.584-1.583s.71-1.583 1.584-1.583c.872 0 1.583.71 1.583 1.583s-.71 1.583-1.583 1.583zM21.375 14.25h-6.333a.791.791 0 00-.792.792v3.166c0 .438.354.792.792.792h.791v11.875c0 .438.354.792.792.792h4.75a.791.791 0 00.792-.792V15.042a.791.791 0 00-.792-.792zm-.792 15.833h-3.166V18.208a.791.791 0 00-.792-.791h-.792v-1.584h4.75v14.25z"
        fill="var(--primaryColor)"
      />
      <path
        d="M19 0C8.524 0 0 8.524 0 19s8.524 19 19 19 19-8.524 19-19S29.476 0 19 0zm0 36.417C9.396 36.417 1.583 28.604 1.583 19 1.583 9.396 9.396 1.583 19 1.583c9.604 0 17.417 7.813 17.417 17.417 0 9.604-7.813 17.417-17.417 17.417z"
        fill="var(--primaryColor)"
      />
    </svg>
  );
}
