import React from 'react';

export default function Edit({ width, height, style, colorAlpha }) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      fill="none"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.48 14h.095l4.57-.993a.45.45 0 00.237-.118l7.742-7.734c.566-.563.881-1.33.876-2.128a3.072 3.072 0 00-.876-2.152A2.985 2.985 0 0010.993 0 2.914 2.914 0 008.863.9l-7.72 7.733a.426.426 0 00-.142.213l-.994 4.564a.52.52 0 00.142.45c.087.088.207.14.332.141zM10.994.946c1.138 0 2.06.921 2.06 2.057v.024a1.986 1.986 0 01-.592 1.443L9.549 1.537c.383-.38.903-.593 1.444-.59zM8.886 2.223l2.912 2.909-6.748 6.716-2.912-2.885 6.748-6.74zM1.759 9.91l2.344 2.341-3.007.662.663-3.003z"
        fill={colorAlpha ? `${colorAlpha}` : '#B0B5B6'}
      />
    </svg>
  );
}
