const languages = {
  'en-US': 'en',
  'he-IL': 'he',
};

export const languageOptions = {
  en: {
    timeFormat: '12',
    timeFormatAlt: 'h:mm A',
    dateFormat: 'MM-DD-YYYY',
    dateFormatAlt: 'MM/DD/YYYY',
    dateFormatFull: 'MMM DD, YYYY LT',
    dateFormatShort: 'MMM DD YYYY',
    value: 'en-US',
    direction: 'left',
    currency: '$',
  },
  he: {
    timeFormat: '24',
    timeFormatAlt: 'HH:mm',
    dateFormat: 'DD-MM-YYYY',
    dateFormatAlt: 'DD/MM/YYYY',
    dateFormatFull: 'DD MMM, YYYY HH:mm',
    dateFormatShort: 'DD MMM YYYY',
    value: 'he-IL',
    direction: 'right',
    currency: '₪',
  },
};

export const getLanguage = () => {
  try {
    return window.localStorage.getItem('appLang') || languages[window.navigator.language] || 'en';
  } catch (e) {
    return 'en';
  }
};
