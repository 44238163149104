import React, { createContext, useContext, useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';

import { useRouter } from 'hooks/router.hook';
import { useMessage } from 'hooks/message.hook';
import { isValidColor } from 'utils/checkValidColor';
import {
  sendOtherAnalyticsAndServicesForPartner,
  GTM_TAGS_FOR_TELELEAF_PARTNER,
} from 'modules/analytics';
import { isEmpty } from 'modules/validationFields';

import { request } from 'modules/client';
import { usePartnerSettingState } from 'state/partnerSettingState';
import { useGtagManager } from 'state/gTagManager';
import { useTrackingPixels } from 'state/trackingPixels';

import { dataFormatter, createZopimChat, globalHeallyGTM } from 'utils/connectPartnerConfigs';
import { defaultTitle } from 'utils/defaultTitle';
import LangContext from './language';

export const StateContext = createContext(null);

export default function PartnerConfigsStateProvider({ children }) {
  const heallyPartnerUuidProd = '7682ff8a-3603-4239-8997-7c86c1318f41';
  const heallyPartnerUuidStage = '8f765550-644a-4631-907b-ffb9b045c4f1';
  const PATIENT_VSHOP_AUTH_ROUTES = [
    '/f/vshop-signIN',
    '/f/vshop-signIN/more_info/the_higherpath_form',
    '/f/vshop-signIN/more_info/clothes_form',
    '/f/vshop-shortreg',
  ];
  const PATIENT_VSHOP_SCHEDULE_ROUTES = [
    '/f/vshop-schedule',
    '/f/vshop-schedule/confirmation',
    '/f/vshop-book_appt',
    '/f/vshop-iframe_test',
  ];

  const [partnerConfigs, setPartnerConfigs] = useState(null);
  const [loadingConfigs, setLoadingConfigs] = useState(true);
  const [fontFamily, setFontFamily] = useState('Poppins');
  const [partnerIdentified, setPartnerIdentified] = useState(false);
  const [isErrorFetchPartnerData, setIsErrorFetchPartnerData] = useState(false);
  const { setBrandColor } = usePartnerSettingState();
  const { subdomain, pathname, isLocalStage } = useRouter();
  const message = useMessage();

  const { initGtagData } = useGtagManager();
  const { initRedditPixel, initFacebookPixel, connectTrackingScriptToHtml } = useTrackingPixels();

  const {
    currentLangData: { titles },
  } = useContext(LangContext);

  const getPartnerSubdomainPermission = () => {
    if (PATIENT_VSHOP_AUTH_ROUTES.includes(pathname) || subdomain)
      return { partnerName: subdomain, isHeallyPartner: false };
    return {
      partnerName: isLocalStage ? heallyPartnerUuidStage : heallyPartnerUuidProd,
      isHeallyPartner: true,
    };
  };

  useEffect(() => {
    // MARK: Need to update this useEffect:
    //       1. PartnerIdentified should be simple variable ( no use from useState );
    //       2. Update title - make a separatly function
    if (partnerIdentified) {
      const partnerName = partnerConfigs?.name || 'Heally';
      const title = titles ? defaultTitle(pathname, titles) : null;
      document.title = title ? `${title} | ${partnerName}` : partnerName;
    }
  }, [partnerConfigs, pathname, titles]);

  useEffect(() => {
    let faviconLink = document.querySelector("link[rel~='icon']");
    const { partnerName, isHeallyPartner } = getPartnerSubdomainPermission();
    const subdomainQueryParam = isHeallyPartner
      ? `uuid=${partnerName}`
      : `subdomain=${partnerName}`;

    if (partnerName) {
      setLoadingConfigs(true);
      request(
        `/v1/partner_admin/profiles/public_info?${subdomainQueryParam}&include=partner_branding,partner_config.registration_custom_questions,schedule_visit_types.form_template`,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      )
        .then(response => {
          const data = dataFormatter.deserialize(response);

          if (isEmpty(data)) {
            setIsErrorFetchPartnerData(true);
            return;
          }

          const { partner_branding, partner_config } = data;
          setPartnerConfigs(data);
          setPartnerIdentified(true);

          if (partner_branding) {
            const partnerSubdomain = partner_branding?.basic_subdomain || partnerName;
            const nameService = 'wickedreports';
            const color = isValidColor(partner_branding?.button_color);

            setBrandColor(color);
            document.documentElement.style.setProperty('--primaryColor', color);
            faviconLink.href = partner_branding?.favicon;

            sendOtherAnalyticsAndServicesForPartner(partnerSubdomain, nameService);
          }

          if (partner_config?.show_chat_widget) {
            createZopimChat();
          }

          /* Start block with connect ads metrics */

          /*
            592   - Teleleaf
            1027  - Synergy Health Medical Evaluations
            1162  - Green Mind Physicians Semaglutide
            739   - CannaCare Docs
            851   - Relaxed Clarity
          */

          if (partner_config?.gtm_code) {
            // This's statemenet need for teleleaf partner with connect several gtm codes
            if (data?.id === '592') {
              GTM_TAGS_FOR_TELELEAF_PARTNER.forEach(gtmCode =>
                TagManager.initialize({ gtmId: gtmCode }),
              );
            }
            TagManager.initialize({ gtmId: partner_config?.gtm_code });

            if (
              partner_config?.aw_conversion_id &&
              partner_config?.tracking?.some(item => !isEmpty(item?.snippet))
            ) {
              initGtagData({
                awConversionID: partner_config?.aw_conversion_id,
                tracking: partner_config?.tracking,
                isEnabledTracking: true,
              });
            }
          }

          if (partner_config?.ga_code) {
            ReactGA.initialize(partner_config?.ga_code);
          }

          if (data?.id === '1027') {
            initRedditPixel('a2_di3gm1c592uo', { debug: false });
          }

          if (data?.id === '1162') {
            initFacebookPixel('1599067277505529');
          }

          if (data?.id === '739' || data?.id === '851') {
            connectTrackingScriptToHtml(
              'https://tags.cnna.io/?segmentId=q_HjUSRyi67fR5BwlGci2A&appId=4730a163-8c3f-4c99-8c93-295f115ccc9f',
            );
          }

          /* End block with connect ads metrics */
        })
        .catch(err => {
          if (err?.response) return message(err?.response?.error);
        })
        .finally(() => setLoadingConfigs(false));
    } else {
      createZopimChat();
      TagManager.initialize({ gtmId: globalHeallyGTM });
      setLoadingConfigs(false);
      setPartnerIdentified(true);
    }
  }, []);

  let contextValue = {
    isErrorFetchPartnerData,
    partnerConfigs,
    loadingConfigs,
    fontFamily,
    setFontFamily,
    PATIENT_VSHOP_AUTH_ROUTES,
    PATIENT_VSHOP_SCHEDULE_ROUTES,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function usePartnerConfigsState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('usePartnerConfigsState mus be use within PartnerConfigsStateProvider');
  }

  return context;
}
