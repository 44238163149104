import React from 'react';
import styled from 'styled-components';
import Video from 'twilio-video';

import Row from 'components/Grid/GridMD/RowMD';
import Col from 'components/Grid/GridMD/ColMD';

const Container = styled.div`
  width: 100%;
  margin-top: 2.5rem;
`;

const Typography = styled.div`
  font-style: normal;
  font-weight: ${({ header }) => (header ? '400' : '300')};
  font-size: ${({ header }) => (header ? '18px' : '14px')};
  color: #324243;
  margin: 0.5rem 0rem;
  text-align: center;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const UnsupportedBrowserWarning = ({ children, translate }) => {
  if (!Video.isSupported && !window.webkitRTCPeerConnection) {
    return (
      <Container>
        <Row>
          <Col s={12} l={12} m={12} xl={12}>
            <MessageContainer>
              <div className="ui-error">
                <svg viewBox="0 0 87 87" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group-2" transform="translate(2.000000, 2.000000)">
                      <circle
                        id="Oval-2"
                        stroke="rgba(252, 191, 191, .5)"
                        stroke-width="4"
                        cx="41.5"
                        cy="41.5"
                        r="41.5"
                      ></circle>
                      <circle
                        className="ui-error-circle"
                        stroke="#F74444"
                        stroke-width="4"
                        cx="41.5"
                        cy="41.5"
                        r="41.5"
                      ></circle>
                      <path
                        className="ui-error-line1"
                        d="M22.244224,22 L60.4279902,60.1837662"
                        id="Line"
                        stroke="#F74444"
                        stroke-width="3"
                        stroke-linecap="square"
                      ></path>
                      <path
                        className="ui-error-line2"
                        d="M60.755776,21 L23.244224,59.8443492"
                        id="Line"
                        stroke="#F74444"
                        stroke-width="3"
                        stroke-linecap="square"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <Typography header>{translate?.error_browser_header}</Typography>
              <Typography>{translate?.error_browser_description}</Typography>
            </MessageContainer>
          </Col>
        </Row>
      </Container>
    );
  }

  return children;
};

export default UnsupportedBrowserWarning;
