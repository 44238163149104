import React from "react";

export default function Logout({ width, height, style }) {
  return (
    <svg style={style} width={width} height={height} fill="none" viewBox="0 0 20 15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 2.501V12.5C0 13.876 1.138 15 2.533 15h8.183c1.395 0 2.533-1.124 2.533-2.501v-1.62a.498.498 0 00-.502-.495.498.498 0 00-.501.495v1.62c0 .833-.69 1.514-1.534 1.514H2.533C1.69 14.013 1 13.333 1 12.5V2.5C1 1.668 1.69.987 2.533.987h8.183c.844 0 1.534.68 1.534 1.514v1.62c0 .273.224.495.501.495a.498.498 0 00.502-.495V2.5C13.253 1.124 12.115 0 10.72 0H2.533C1.138 0 0 1.12 0 2.501z"
        fill="#334047"
      />
      <path
        d="M15.721 11.226a.5.5 0 00.355.145.5.5 0 00.355-.145l3.422-3.38a.487.487 0 000-.697L16.431 3.77a.502.502 0 00-.706 0 .487.487 0 000 .697l2.57 2.537H8.917a.498.498 0 00-.502.496c0 .274.225.495.502.495h9.374l-2.57 2.538a.482.482 0 000 .693z"
        fill="#334047"
      />
    </svg>
  );
}