import React, { createContext, useContext } from 'react';
import Jsona from 'jsona';

import { request } from 'modules/client';

export const StateContext = createContext(null);

const dataFormatter = new Jsona();

export default function SchedulingPatientsProvider({ children }) {
  const getAppointmentInfo = async id => {
    return request(`/api/v2/scheduling/patient/visits/${id}`).then(data =>
      dataFormatter.deserialize(data),
    );
  };

  const updateScheduleVisit = async (id, schedule_visit) => {
    if (!id) {
      throw new Error('Appt ID not found');
    }

    return request(`/api/v2/scheduling/patient/visits/${id}`, {
      method: 'PUT',
      payload: { schedule_visit },
    }).then(response => dataFormatter.deserialize(response));
  };

  const createScheduleVisit = async schedule_visit => {
    return request('/api/v2/scheduling/patient/visits', {
      method: 'POST',
      payload: { schedule_visit },
    }).then(response => dataFormatter.deserialize(response));
  };

  const createPatientReadyForCheckIn = async params => {
    return request('/api/v2/scheduling/patient/check_in/report_arrival', {
      method: 'POST',
      payload: { ...params },
    }).then(response => dataFormatter.deserialize(response));
  };

  const cancelScheduleVisit = async id => {
    return request(`/api/v2/scheduling/patient/visits/${id}`, {
      method: 'DELETE',
    });
  };

  const contextValue = {
    getAppointmentInfo,
    createScheduleVisit,
    createPatientReadyForCheckIn,
    cancelScheduleVisit,
    updateScheduleVisit,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useSchedulingPatientsState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useSchedulingPatientsState must be use within SchedulingPatientsProvider');
  }

  return context;
}
