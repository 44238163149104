import _ from 'lodash';
import React, { cloneElement, isValidElement } from 'react';

import cx from 'classnames';

export const createShorthand = (Component, mapValueToProps, val, options = {}) => {
  if (typeof Component !== 'function' && typeof Component !== 'string') {
    throw new Error('createShorthand() Component must be a string or function');
  }

  if (_.isNil(val) || _.isBoolean(val)) return null;

  const valIsString = _.isString(val);
  const valIsNumber = _.isNumber(val);
  const valIsFunction = _.isFunction(val);
  const valIsReactElement = isValidElement(val);
  const valIsPropsObject = _.isPlainObject(val);
  const valIsPrimitiveValue = valIsString || valIsNumber || _.isArray(val);

  if (!valIsFunction && !valIsReactElement && !valIsPropsObject && !valIsPrimitiveValue) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(`Received ${typeof val}`);
    }

    return null;
  }

  const { defaultProps = {} } = options;

  const usersProps = (valIsReactElement && val.props)
    || (valIsPropsObject && val)
    || (valIsPrimitiveValue && mapValueToProps(val));

  let { overrideProps = {} } = options;
  overrideProps = _.isFunction(overrideProps)
    ? overrideProps({ ...defaultProps, ...usersProps })
    : overrideProps;

  const props = { ...defaultProps, ...usersProps, ...overrideProps };

  if (defaultProps.className || overrideProps.className || usersProps.className) {
    const mergedClassesNames = cx(
      defaultProps.className,
      overrideProps.className,
      usersProps.className
    );
    props.className = _.uniq(mergedClassesNames.split(' ')).join(' ');
  }

  if (defaultProps.style || overrideProps.style || usersProps.style) {
    props.style = { ...defaultProps.style, ...usersProps.style, ...overrideProps.style };
  }

  if (_.isNil(props.key)) {
    const { childKey } = props;
    const { autoGenerateKey = true } = options;

    if (!_.isNil(childKey)) {
      props.key = typeof childKey === 'function' ? childKey(props) : childKey;
      delete props.childKey;
    } else if (autoGenerateKey && (valIsString || valIsNumber)) {
      props.key = val;
    }
  }

  if (valIsReactElement) return cloneElement(val, props);

  if (valIsPrimitiveValue || valIsPropsObject) return <Component {...props} />;

  if (valIsFunction) return val(Component, props, props.children);
};

export const createShorthandFactory = (Component, mapValueToProps) => {
  if (typeof Component !== 'function' && typeof Component !== 'string') {
    throw new Error('createShorthandFactory() Component must be a string or function.');
  }

  return (val, options) => createShorthand(Component, mapValueToProps, val, options);
};

export const createHTMLInput = createShorthandFactory('input', val => ({ type: val }));
export const createHTMLLable = createShorthandFactory('label', val => ({ children: val }));
export const createHTMLLabel = createShorthandFactory('label', val => ({ children: val }));
export const createHTMLParagraph = createShorthandFactory('p', val => ({ children: val }));
