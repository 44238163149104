import React, { Component, Children, cloneElement } from 'react'
import cx from 'classnames';
import idgen from 'utils/idgen';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.idx = props.id || `dropdown${idgen()}`;
    this.renderTrigger = this.renderTrigger.bind(this);
    this.renderItems = this.renderItems.bind(this);
  }

  componentDidMount() {
    const { options } = this.props;

    if (typeof M !== undefined) {
      this.instance = window.M.Dropdown.init(
        document.querySelectorAll(this._trigger),
        options
      )[0];
    }
  }

  componentWillUnmount() {
    if (this.instance) {
      this.instance.destroy();
    }
  }

  renderTrigger() {
    const { trigger } = this.props;

    return cloneElement(trigger, {
      'data-target': this.idx,
      ref: t => (this._trigger = `[data-target=${this.idx}]`),
      className: cx(trigger.props.className, 'dropdown-trigger')
    });
  }

  renderItems() {
    const { children } = this.props;

    return Children.map(children, element => {
      if (!element) return null;
      if (element.type.name === 'Divider') {
        return <li key={idgen()} className="divider" tabIndex="-1" />;
      } else {
        return <li key={idgen()}>{element}</li>;
      }
    });
  }

  render() {
    const { className, ...props } = this.props;
    delete props.trigger;
    delete props.options;
    return (
      <>
        {this.renderTrigger()}
        <ul
          {...props}
          className={cx('dropdown-content', className)}
          id={this.idx}
        >
          {this.renderItems()}
        </ul>
      </>
    );
  }
}

Dropdown.defaultProps = {
  options: {
    alignment: 'left',
    autoTrigger: true,
    constrainWidth: false,
    container: null,
    coverTrigger: true,
    closeOnClick: true,
    hover: false,
    inDuration: 150,
    outDuration: 250,
    onOpenStart: null,
    onOpenEnd: null,
    onCloseStart: null,
    onCloseEnd: null
  }
};

export default Dropdown;