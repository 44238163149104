export const EXCEPTION_FROM_STATUS_VISIT = ['rescheduled', 'cancelled', 'expired'];
export const ACTIVE_ASYNC_APPTS = ['uncompleted', 'pending', 'confirmed', 'unconfirmed'];

export const base64ToString = encodedStr => {
  const decoded = window.atob
    ? window.atob(encodedStr)
    : Buffer.from(encodedStr, 'base64').toString('binary');
  const decoder = new TextDecoder();
  const utf8Decoded = decoder.decode(
    new Uint8Array(decoded.length).map((_, i) => decoded.charCodeAt(i)),
  );
  return utf8Decoded;
};

export const stringToBase64 = str => {
  const encoder = new TextEncoder();
  const data = encoder.encode(str);
  const base64 = window.btoa(String.fromCharCode(...new Uint8Array(data)));
  return base64;
};

export const deepClone = obj => {
  if (!window.structuredClone) {
    return JSON.parse(JSON.stringify(obj));
  }

  return window.structuredClone(obj);
};

export const getItemFromLocalStorage = key => {
  try {
    const item = window.localStorage.getItem(key);

    if (item) {
      return JSON.parse(item);
    }
    return '';
  } catch (e) {
    return '';
  }
};

export const setItemInLocalStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    return '';
  }
};

export const scrollToBottomOnMobile = () => {
  const isMobile = window.innerWidth <= 768;

  if (isMobile) {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }
};

export const getUrlParamValue = (param = '') => {
  const params = new URL(document.location).searchParams;
  return params.get(param) || '';
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const delayCallFn = (ms, fn) =>
  new Promise(resolve => {
    const timeout = setTimeout(() => {
      if (fn) fn();
      clearTimeout(timeout);
      resolve();
    }, ms);
  });
