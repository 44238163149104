import React from 'react';
import { ErrorBoundary } from '@sentry/react';

import Message from '../Message';

const ErrorBoundaryCmp = ({ children }) => {
  return (
    <ErrorBoundary
      fallback={({ error }) => {
        if (/ChunkLoadError/.test(error.name)) {
          return (
            <Message
              type="warning"
              header="new app version"
              subheader={
                <span>
                  Please refresh the <a onClick={() => window.location.reload()}>page</a> or return
                  to the{' '}
                  <a
                    onClick={() => (window.location.href = '/patient_admin/patient_users/sign_in')}
                  >
                    login
                  </a>{' '}
                  page
                </span>
              }
            />
          );
        }

        return (
          <Message
            type="error"
            header="we are sorry, the page has unexpected error!"
            subheader="Please contact support via live chat below."
          />
        );
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export { ErrorBoundaryCmp };
