import React from "react";

export default function Visit({ width, height, style }) {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.297 1.227V.414A.386.386 0 009.14.106m.156 1.121L9.14.106s0 0 0 0m.156 1.121h.025m-.025 0h.025M9.142.106a.407.407 0 00-.347-.068m.346.068L8.795.038m.527 1.19h6.254c.221 0 .4.174.399.388v18.768a.394.394 0 01-.399.389h-1.029a.394.394 0 01-.399-.39c0-.213.178-.388.4-.388h.629V2.005H9.297V3.237h4.221c.221 0 .4.174.4.389v14.748a.394.394 0 01-.4.39H9.297V19.995h3.932c.221 0 .4.175.4.389 0 .214-.179.389-.4.389H9.297V21.586a.385.385 0 01-.146.3.405.405 0 01-.331.081L.346 20.312l-.005.025.005-.025a.391.391 0 01-.321-.381V5.354c0-.214.178-.39.399-.39.22 0 .399.176.399.39V19.61l.02.005 7.626 1.49.03.005V.921l-.031.008L.842 2.915l-.02.005v1.038c0 .215-.177.39-.398.39a.394.394 0 01-.399-.39V2.621a.39.39 0 01.296-.376L8.795.038h0m.527 1.19L8.795.037m4.324 4.002v-.025H9.297v13.97H13.119V4.04z"
        fill="var(--primaryColor)"
        stroke="#F6FBFA"
        strokeWidth=".05"
      />
    </svg>
  );
}
