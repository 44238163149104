/* eslint-disable import/prefer-default-export */
export const numberToWordMap = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
  11: 'eleven',
  12: 'twelve',
  13: 'thirteen',
  14: 'fourteen',
  15: 'fifteen',
  16: 'sixteen',
};

export const useKeyOnly = (val, key) => val && key;

export const useValueAndKey = (val, key) => val && val !== true && `${val} ${key}`;

export const useKeyOrValueAndKey = (val, key) => val && (val === true ? key : `${val} ${key}`);

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useTextAlignProp = val =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  val === 'justified' ? 'justified' : useValueAndKey(val, 'aligned');

export const useVerticalAlignProp = val => useValueAndKey(val, 'aligned');

export const numberToWord = value => {
  const type = typeof value;
  if (type === 'string' || type === 'number') return numberToWordMap[value] || value;
  return '';
};

export const useWidthProp = (val, widhtClass = '', canEqual = false) => {
  if (canEqual && val === 'equal') return 'equal width';

  const valType = typeof val;
  if ((valType === 'string' || valType === 'number') && widhtClass)
    return `${numberToWord(val)} ${widhtClass}`;
  return numberToWord(val);
};

export const useMultipleProp = (val, key) => {
  if (!val || val === true) return null;

  return val
    .replace('large screen', 'large-screen')
    .replace(/ vertically/g, '-vertically')
    .split(' ')
    .map(prop => `${prop.replace('-', ' ')} ${key}`)
    .join(' ');
};
