import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { usePartnerConfigsState } from 'state/partnerConfigs';
import LangContext from 'state/language';

import { useRouter } from 'hooks/router.hook';

import RippleLoader from 'components/Preloader/RippleLoader';

import NotFound from './NotFound';
import VShopAuthRouter from './Auth';
import VShopScheduleRouter from './Schedule';
import { defaultTitle } from 'utils/defaultTitle';

const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PatientVShopRouter = ({ dispatch }) => {
  const [loadingFont, setLoadingFont] = useState(true);
  const { pathname, subdomain } = useRouter();
  const {
    isErrorFetchPartnerData,
    partnerConfigs,
    loadingConfigs,
    setFont,
    PATIENT_VSHOP_AUTH_ROUTES,
    PATIENT_VSHOP_SCHEDULE_ROUTES,
  } = usePartnerConfigsState();

  const {
    currentLangData: { main_layout, titles },
  } = useContext(LangContext);

  useEffect(() => {
    if (subdomain && partnerConfigs?.partner_branding?.font) {
      const fontName = _.upperFirst(`${subdomain}Font`);
      const fontUrl = partnerConfigs?.partner_branding?.font;
      const partnerFont = new FontFace(fontName, `url(${fontUrl})`);

      partnerFont
        .load()
        .then(loadedFont => {
          document.fonts.add(loadedFont);
          setFont(fontName);
          setLoadingFont(false);
        })
        .catch(error => {
          console.error(`Failed to load font: ${error}`);
          setLoadingFont(false);
        });
    } else {
      setLoadingFont(false);
    }
  }, [subdomain, partnerConfigs]);

  useEffect(() => {
    if (!loadingConfigs) {
      const partnerName = partnerConfigs?.name || 'Heally';
      const title = titles ? defaultTitle(pathname, titles) : null;
      document.title = title ? `${title} | ${partnerName}` : partnerName;
    }
  }, [partnerConfigs, pathname, titles]);

  if (loadingConfigs || loadingFont) {
    return (
      <CenterContainer>
        <RippleLoader color="var(--primaryColor)" />
      </CenterContainer>
    );
  }

  if (isErrorFetchPartnerData) {
    return <NotFound message={main_layout?.content?.vshop_schedule?.partner_not_found} />;
  }

  if (PATIENT_VSHOP_AUTH_ROUTES.indexOf(pathname) >= 0) {
    return <VShopAuthRouter dispatch={dispatch} subdomain={subdomain} />;
  }

  if (PATIENT_VSHOP_SCHEDULE_ROUTES.indexOf(pathname) >= 0) {
    return <VShopScheduleRouter dispatch={dispatch} />;
  }

  return <NotFound message={main_layout?.content?.vshop_schedule?.partner_not_found} />;
};

export default PatientVShopRouter;
