import Jsona from 'jsona';
import { request } from 'modules/client';
import { createContext, useContext, useState } from 'react';

export const StateContext = createContext(null);

const dataFormatter = new Jsona();

export default function HomePatientProvider({ children }) {
  const DEFAULT_STATE = {
    data: [],
    error: null,
    status: 'idle',
  };

  const [patientSummary, setPatientSummary] = useState({ ...DEFAULT_STATE, data: {} });

  const fetchPatientSummary = (noStatusChange = false) => {
    setPatientSummary(prevState => ({
      ...prevState,
      error: null,
      status: noStatusChange ? prevState.status : 'running',
    }));
    request('/api/v2/scheduling/patient/todo')
      .then(response => {
        const data = dataFormatter.deserialize(response);

        setPatientSummary({
          data,
          error: null,
          status: 'ready',
        });
      })
      .catch(error =>
        setPatientSummary(prevState => ({
          ...prevState,
          error,
          status: noStatusChange ? prevState.status : 'error',
        })),
      );
  };

  const updatePatientSummary = data => setPatientSummary(prevState => ({ ...prevState, data }));

  const contextValue = {
    patientSummary,
    setPatientSummary,
    fetchPatientSummary,
    updatePatientSummary,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useHomePatientState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useHomePatientState must be used with HomePatientProvider');
  }
  return context;
}
