import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useRouter } from 'hooks/router.hook';
import { usePartnerConfigsState } from 'state/partnerConfigs';

import Navbar from 'components/Navbar';
import Select from 'components/Select/SelectMD';

const StyledImage = styled.img`
  width: auto;
  height: 38px;
  filter: ${({ offFilter }) => (offFilter ? 'none' : 'brightness(1000%)')};
  top: 50%;
  transform: translate(-50%, -50%) !important;
`;

const StyledNavbar = styled(Navbar)`
  background-color: #fff;
  box-shadow: none;
`;

const ContainerLanguage = styled.div`
  > div {
    > div {
      max-width: 68px;
      > select {
        display: none !important;
      }
      > input {
        border-bottom: none !important;
        margin: 0px !important;
        color: #615d5d !important;
        font-weight: 200 !important;
        font-size: 1rem !important;
      }
      > ul {
        top: 0px !important;
        > li {
          font-weight: 200 !important;
          font-size: 1rem !important;
        }
      }
      > svg {
        fill: #615d5d !important;
      }
    }
  }
`;

const heallyLogo = require('images/heally_teal_logo.png');

const AuthHeader = ({ handleChangeLanguage, lang, disableLink }) => {
  const { push, subdomains } = useRouter();
  const { partnerConfigs } = usePartnerConfigsState();

  const isIlSubdomain = useMemo(() => {
    const subdomain = subdomains.filter(
      el =>
        el?.toLowerCase() === 'heally' ||
        el?.toLowerCase() === 'erx-0002' ||
        el?.toLowerCase() === 'localhost',
    );

    if (subdomain?.length > 0) return true;
    return false;
  }, [subdomains]);

  const logo = partnerConfigs?.partner_branding
    ? partnerConfigs?.partner_branding?.logo
    : heallyLogo;

  return (
    <StyledNavbar
      alignLinks="right"
      brand={
        <StyledImage
          offFilter={
            partnerConfigs?.partner_branding && partnerConfigs?.partner_branding?.logo && true
          }
          onClick={disableLink ? () => null : () => push('/f/patient_admin')}
          src={logo}
        />
      }
      centerLogo
      options={{
        draggable: false,
        edge: 'left',
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: null,
        onOpenEnd: null,
        onOpenStart: null,
        outDuration: 200,
        preventScrolling: true,
      }}
      centeredChildren
      language={
        isIlSubdomain && (
          <ContainerLanguage
            style={{
              position: 'absolute',
              right: '20%',
              width: '75px',
              top: '50%',
              transform: 'translate(20%, -50%)',
            }}
          >
            {/* <Select
              id="Select-9"
              multiple={false}
              onChange={value => handleChangeLanguage(value)}
              options={{
                classes: '',
                dropdownOptions: {
                  alignment: 'left',
                  autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: true,
                  coverTrigger: true,
                  hover: false,
                  inDuration: 150,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  outDuration: 250,
                },
              }}
              value={lang}
            >
              <option value="en">en-US</option>
              <option value="he">he-IL</option>
            </Select> */}
          </ContainerLanguage>
        )
      }
    ></StyledNavbar>
  );
};

export default AuthHeader;
