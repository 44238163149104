import React, { createContext, useContext, useState } from 'react';
import Jsona from 'jsona';
import { request } from 'modules/client';

export const StateContext = createContext(null);

const dataFormatter = new Jsona();

export default function PatientProvider({ children }) {
  const DEFAULT_STATE = {
    data: {
      back_image: {
        current: null,
        preview: '',
      },
      front_image: {
        current: null,
        preview: '',
      },
      id_number: '',
      back_image_required: false,
    },
    status: 'idle',
    error: null,
  };
  const [patientPrevInfo, setPatientPrevInfo] = useState(DEFAULT_STATE);

  const getStateWithPatientIds = data => {
    const { back_image, front_image, id_number, back_image_required } = data;

    return {
      back_image: {
        current: null,
        preview: back_image?.url ? `${back_image?.url}?${new Date().getTime()}` || '' : null,
      },
      front_image: {
        current: null,
        preview: front_image?.url ? `${front_image?.url}?${new Date().getTime()}` || '' : null,
      },
      id_number: id_number || '',
      back_image_required,
    };
  };

  const getPatientInfo = () => {
    setPatientPrevInfo({ data: {}, status: 'running', error: null });
    return request('/api/v2/patient/patient_id')
      .then(res => {
        const payload = dataFormatter.deserialize(res);
        const data = getStateWithPatientIds(payload);

        setPatientPrevInfo({
          data,
          status: 'ready',
          error: null,
        });
      })
      .catch(error => setPatientPrevInfo(prevState => ({ ...prevState, status: 'error', error })));
  };

  const uploadSignature = async formData => {
    return request('/api/v2/patient/signatures/update', {
      method: 'POST',
      formData,
    });
  };

  const updatePatientId = async formData => {
    return request('/api/v2/patient/patient_id', {
      method: 'POST',
      formData,
    }).then(response => dataFormatter.deserialize(response));
  };

  const updatePatientServiceData = async payload => {
    return request('/api/v2/patient/service_data', { method: 'PUT', payload });
  };

  const updatePatientPrevInfo = res => {
    const data = getStateWithPatientIds(res);

    setPatientPrevInfo(prevState => ({ ...prevState, data }));
  };

  const contextValue = {
    getPatientInfo,
    patientPrevInfo,
    updatePatientId,
    uploadSignature,
    updatePatientPrevInfo,
    updatePatientServiceData,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function usePatientProvider() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('usePatientProvider must be used with PatientProvider');
  }
  return context;
}
