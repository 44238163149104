import { all, call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'modules/client';

import { ActionTypes } from '../constants';

export function* fetchEvaluationCountryAndStates() {
  try {
    const response = yield call(
      request,
      '/v1/evaluation_country_states'
    )

    const countries = {};

    response.countries.forEach(country => {
      countries[country.code] = { ...country };
    });

    yield put({
      type: ActionTypes.FETCH_EVALUATION_COUNTRY_AND_STATES_SUCCESS,
      payload: {
        data: countries,
      }
    })
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_EVALUATION_COUNTRY_AND_STATES_FAIL,
      payload: error
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.FETCH_EVALUATION_COUNTRY_AND_STATES, fetchEvaluationCountryAndStates)
  ]);
}
