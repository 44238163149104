const checkValid = new RegExp(
  /^(#(?:[0-9a-f]{2}){2,4}|#[0-9a-f]{3}|(?:rgba?|hsla?)\((?:\d+%?(?:deg|rad|grad|turn)?(?:,|\s)+){2,3}[\s\/]*[\d\.]+%?\))$/i,
);
const isValidColor = (color) => {
  if (color && checkValid.test(color)) {
    return color;
  } else {
    return '#5cc2b1';
  }
};

export { isValidColor };
