import React, { createContext, useContext } from 'react';

import { dataFormatter } from 'utils/connectPartnerConfigs';
import { getRequestError, request } from 'modules/client';

export const StateContext = createContext(null);

export default function PatientScheduleApptsProvider({ children }) {
  const createScheduleVisitFromWidget = (schedule_visit, token) => {
    return fetch('/api/v2/scheduling/patient/visits/widget', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...schedule_visit }),
    }).then(async response => {
      if (response.status > 399) {
        return getRequestError(response);
      }

      const data = await response.json();

      return { ...dataFormatter.deserialize(data) };
    });
  };

  const createScheduleVisit = schedule_visit => {
    return request('/api/v2/scheduling/patient/visits', {
      method: 'POST',
      payload: { ...schedule_visit },
    }).then(response => dataFormatter.deserialize(response));
  };

  const contextValue = {
    createScheduleVisit,
    createScheduleVisitFromWidget,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function usePatientScheduleApptsState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('usePatientScheduleApptsState must be use within PatientScheduleApptsProvider');
  }

  return context;
}
