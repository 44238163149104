import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useConnectionOptions from 'hooks/twilio/connectionOptions.hook';
import { useMessage } from 'hooks/message.hook';

import { VideoProvider } from 'components/Twilio/VideoProvider';
// import { ChatProvider } from 'components/Twilio/ChatProvider/ChatProvider';
import UnsupportedBrowserWarning from 'components/Twilio/components/UnsupportedBrowserWarning';

import AppStateProvider, { useAppState } from '../../state';

const VisitApp = ({ children, translate }) => {
  const { error, setError, createLogPapertrail } = useAppState();
  const connectionOptions = useConnectionOptions();
  const message = useMessage();
  const { go } = useHistory();

  useEffect(() => {
    if (error) {
      createLogPapertrail({
        status: `Twilio error: ${error}`,
      });
      message(error);
      go(0);
    }
  }, [error]);

  return (
    <UnsupportedBrowserWarning translate={translate}>
      <VideoProvider options={connectionOptions} onError={setError}>
        {children}
      </VideoProvider>
    </UnsupportedBrowserWarning>
  );
};

const VisitContainer = ({ children, translate }) => {
  return (
    <AppStateProvider>
      <VisitApp children={children} translate={translate} />
    </AppStateProvider>
  );
};

export default VisitContainer;
