import keyMirror from 'fbjs/lib/keyMirror';

export const ActionTypes = keyMirror({
  FETCH_PATIENT_IDENTIFICATION: undefined,
  FETCH_PATIENT_IDENTIFICATION_SUCCESS: undefined,
  FETCH_PATIENT_IDENTIFICATION_FAIL: undefined,
  UPDATE_PATIENT_ID_NUMBER: undefined,
  UPDATE_PATIENT_ID_NUMBER_SUCCESS: undefined,
  UPDATE_PATIENT_ID_NUMBER_FAIL: undefined,
  UPDATE_PATIENT_FACE_PHOTO: undefined,
  UPDATE_PATIENT_FACE_PHOTO_SUCCESS: undefined,
  UPDATE_PATIENT_FACE_PHOTO_FAIL: undefined,
  UPDATE_PATIENT_ID_PHOTO: undefined,
  UPDATE_PATIENT_ID_PHOTO_SUCCESS: undefined,
  UPDATE_PATIENT_ID_PHOTO_FAIL: undefined,
  RESET_PREVIOUS_ID_STATE: undefined,
});

export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};
