import React from 'react';

export default function House({ width, height, style }) {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 .1c.47 0 .912.183 1.244.515l8.243 8.242.07.075c.614.69.59 1.751-.071 2.412a1.747 1.747 0 01-1.244.515h-.405v6.165l-.003.104A2.078 2.078 0 0115.76 20.1h-3.225l-.057-.003a.491.491 0 01-.434-.488v-4.754l-.002-.078a1.095 1.095 0 00-1.092-1.016H9.049l-.078.002a1.095 1.095 0 00-1.016 1.092v4.754l-.003.057a.491.491 0 01-.488.434H4.24l-.104-.002a2.079 2.079 0 01-1.972-2.073v-6.166H1.71l-.135-.01a1.75 1.75 0 01-1.06-.505A1.76 1.76 0 01.51 8.86L8.756.615l.079-.074A1.745 1.745 0 0110 .101zm0 .982l-.077.004a.771.771 0 00-.472.223L1.21 9.548l-.056.061a.778.778 0 00.589 1.267l.91.001.058.004a.491.491 0 01.433.487v6.656l.003.078c.04.567.514 1.016 1.092 1.016h2.733v-4.263l.003-.104a2.079 2.079 0 012.073-1.972h1.902l.104.002a2.079 2.079 0 011.972 2.074v4.263h2.734l.078-.002a1.095 1.095 0 001.016-1.091v-6.657l.003-.057a.49.49 0 01.488-.434h.896l.077-.003a.78.78 0 00.471-1.325l-8.24-8.24-.058-.051A.772.772 0 0010 1.082z"
        fill="var(--primaryColor)"
      />
    </svg>
  );
}
