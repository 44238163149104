import React, { useState } from 'react';
import moment from 'moment';

import 'moment/locale/he';

import { request } from 'modules/client';
import { getLanguage, languageOptions } from 'modules/languages';

const LangContext = React.createContext(null);

export default LangContext;

export function LangProvider({ children }) {
  const [currentLangData, setCurrentLangData] = useState({});
  const [languageConfigs, setLanguageConfigs] = useState({});
  const [loadingLang, setLoadingLang] = useState(true);
  const [lang, setLang] = useState(getLanguage());
  const [languageError, setLanguageError] = useState(null);

  const updateBody = ln => {
    if (ln === 'he') {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }
  };

  const switchLang = ln => {
    if (!ln) return;
    setLang(ln);

    updateBody(ln);
    moment.locale(ln);
  };

  const updateLanguageState = (currentLocale, localization) => {
    switchLang(currentLocale);
    setCurrentLangData(localization);
    setLanguageConfigs(languageOptions[currentLocale]);
    updateBody(currentLocale);
  };

  const changeLanguage = async (language, userScope) => {
    setLoadingLang(true);
    try {
      const { current_locale, localization } = await request('/locale', {
        method: 'POST',
        payload: { locale: language, user_scope: userScope },
      });
      updateLanguageState(current_locale, localization);
    } catch (err) {
      setLanguageError(err);
    } finally {
      setLoadingLang(false);
    }
  };

  const fetchLanguage = async () => {
    setLoadingLang(true);
    try {
      const response = await request('/v1/patient_admin/locale');
      updateLanguageState(lang, response);
    } catch (err) {
      setLanguageError(true);
    } finally {
      setLoadingLang(false);
    }
  };

  return (
    <LangContext.Provider
      value={{
        lang,
        switchLang,
        currentLangData,
        setCurrentLangData,
        changeLanguage,
        fetchLanguage,
        loadingLang,
        languageError,
        languageConfigs,
      }}
    >
      {children}
    </LangContext.Provider>
  );
}
