import React, { Suspense, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ErrorBoundaryCmp } from 'components/ErrorBoundary';
import styled from 'styled-components';
import { connect } from 'react-redux';

import RippleLoader from 'components/Preloader/RippleLoader';

import AuthHeader from 'containers/Patient/PatientMainLayout/auth';

import LangContext from 'state/language';

import { lazyWithRetry } from 'utils/rendering';

import NotFound from '../NotFound';

const SignUp = lazyWithRetry(() => import('pages/Patient/SignUp'));
const FormTypes = lazyWithRetry(() => import('pages/Patient/SignUp/PatientFromPartner/Steps'));

const Main = styled.main`
  margin: 2.5rem 0rem 1.5rem 0rem;
  display: flex;
  flex: auto;
  height: 100%;
  position: relative;
  min-height: 1084px;

  @media (min-width: 768px) {
    min-height: 1280px;
  }

  @media (min-width: 1024px) {
    flex: 1 0 auto;
    margin: 2rem 0rem 0rem 0rem;
    min-height: auto;
  }
`;

const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.45);
  transition: all 0.3s ease-in-out;
  z-index: 82;
`;

const PatientAuthRoutes = ({ subdomain, query, dispatch, partner, auth }) => {
  const userScope = 'patient_admin';

  const {
    loadingLang,
    currentLangData: { auth_layout },
    lang,
    changeLanguage,
    languageConfigs,
  } = useContext(LangContext);

  const renderContent = ({ component: Component, isIframe }) => {
    return (
      <ErrorBoundaryCmp>
        <Component
          subdomain={subdomain}
          query={query}
          dispatch={dispatch}
          partner={partner}
          auth={auth}
          translate={auth_layout}
          languageConfigs={languageConfigs}
          isIframe={isIframe}
        />
      </ErrorBoundaryCmp>
    );
  };

  if (loadingLang) {
    return (
      <CenterContainer>
        <RippleLoader color="var(--primaryColor)" />
      </CenterContainer>
    );
  }

  if (!subdomain) return <NotFound message="Partner not found" />;

  return (
    <>
      <AuthHeader
        partnerCropProfile={partner?.cropProfile}
        lang={lang}
        handleChangeLanguage={value => changeLanguage(value, userScope)}
      />
      <Main>
        <Suspense
          fallback={
            <CenterContainer>
              <RippleLoader color="var(--primaryColor)" />
            </CenterContainer>
          }
        >
          <Switch>
            <Route
              exact
              path="/f/patient_admin/sign_up"
              render={() => renderContent({ component: SignUp })}
            />
            <Route
              exact
              path="/f/vshop-signIN/more_info/:form_type"
              render={() => renderContent({ component: FormTypes })}
            />
            <Route
              path="/f/vshop-shortreg"
              render={() => renderContent({ component: SignUp, isIframe: true })}
            />
            <Route path="/f/vshop-signIN" render={() => renderContent({ component: SignUp })} />
          </Switch>
        </Suspense>
      </Main>
    </>
  );
};

const mapStateToProps = state => ({
  partner: state?.partner,
  auth: state?.auth,
});

export default connect(mapStateToProps)(PatientAuthRoutes);
