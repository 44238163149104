import React, { createContext, useContext, useState, useRef } from 'react';
import moment from 'moment';
import Jsona from 'jsona';

import { request } from 'modules/client';
import { isEmpty, validatePhoneNumber } from 'modules/validationFields';
import { capitalizeFirstLetter, transformString } from 'modules/format';
import { deepClone } from 'modules/common';

export const StateContext = createContext(null);

const dataFormatter = new Jsona();

export default function DocumentFlowProvider({ children }) {
  const DETAILED_ADDRESS_FIELDS = ['city', 'state', 'zip_code', 'apartment'];
  const FORM_TYPE_WITH_LIST_DATA = ['FormSelect', 'FormCheckbox', 'FormRadio'];

  const childFieldsRef = useRef({});
  const patientProfileRef = useRef({});
  let aborter = null;
  const DEFAULT_STATE = {
    data: null,
    status: 'idle',
    error: null,
  };

  const [questionsWithConditions, setQuestionsWithConditions] = useState([]);
  const [questionsWithRequred, setQuestionsWithRequired] = useState({});
  const [draftParams, setDraftParams] = useState({});
  const [dataForSignature, setDataForSignature] = useState({
    answers: {},
    listDisplayedQuestions: [],
  });
  const [savingForm, setSavingForm] = useState('idle');
  const [form, setForm] = useState(DEFAULT_STATE);

  const craeteEasySignIn = async payload => {
    return request(`/api/v2/document_flow/patient/auth/easy_sign_in`, {
      method: 'POST',
      payload,
    }).then(response => dataFormatter.deserialize(response));
  };

  const createPayloadWithAddress = data => {
    const payload = {
      document: {
        draft_params: {},
      },
    };

    for (const field in data) {
      payload.document.draft_params[field] = {
        data: typeof data[field] === 'string' ? { value: data[field] } : data?.value,
        type: field === 'address' ? 'FormAddress' : 'FormTextField',
      };
    }

    return payload;
  };

  const createPayload = (field, data) => {
    return {
      document: {
        draft_params: {
          [field]: {
            data: FORM_TYPE_WITH_LIST_DATA.includes(data?.type)
              ? data?.value
              : { value: data?.value },
            type: data?.type,
          },
        },
      },
    };
  };

  const formatDate = date => {
    if (!isEmpty(date)) {
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    }
    return '';
  };

  const createDraftParams = (draftParams = {}, extraParams = {}) => {
    const draft = { ...draftParams };

    for (const param in extraParams) {
      if (!draft[param]) {
        draft[param] = extraParams[param];
      }
    }

    return draft;
  };

  const setValueForField = (question, draftField) => {
    if (typeof draftField === 'object' && draftField !== null) {
      if (question?.type === draftField?.type) {
        if (FORM_TYPE_WITH_LIST_DATA.includes(draftField?.type)) {
          if (draftField?.type === 'FormCheckbox') {
            return !isEmpty(draftField?.data) && question?.options && Array.isArray(draftField?.data)
              ? draftField.data.filter(item =>
                  question?.options?.some(option => item?.value === option?.value),
                )
              : [];
          }
          return !isEmpty(draftField?.data) &&
            question?.options?.some(option => draftField?.data?.value === option?.value)
            ? draftField?.data
            : {};
        }
        return { value: draftField?.data?.value };
      }
      return FORM_TYPE_WITH_LIST_DATA.includes(draftField?.type) ? null : { value: '' };
    }

    return question?.type === 'FormCheckbox' && typeof draftField === 'string'
      ? null
      : question?.type === 'FormSelect' || question?.type === 'FormRadio'
      ? draftField
      : { value: draftField };
  };

  const createStateForDetailedAddress = (obj, acc, draftParams, patientProfile) => {
    const detailedAddress = acc?.options?.[0]?.childFields;

    if (detailedAddress) {
      for (const field of detailedAddress) {
        obj[(field?.attribute_name)] = {
          data: patientProfile
            ? { value: patientProfile?.[field?.attribute_name] }
            : draftParams[(field?.attribute_name)]
            ? setValueForField(field, draftParams[(field?.attribute_name)])
            : { value: '' },
          required: field?.required,
          error: '',
          type: field?.type,
        };
      }
    }
  };

  const applyValuesByQuestionsAnswer = (formSection, draftParams, patientProfile) => {
    const copyDraftParams = { ...draftParams };
    const paramsFormPatientProfile = formSection.reduce((obj, acc) => {
      const defaultAttributeName = acc?.default?.attribute_name;

      if (defaultAttributeName) {
        if (defaultAttributeName === 'address') {
          obj[defaultAttributeName] = {
            data: { value: patientProfile?.[defaultAttributeName] },
            required: acc?.required,
            error: '',
            type: acc?.type,
          };

          if (acc?.options?.length > 0) {
            createStateForDetailedAddress(obj, acc, copyDraftParams, patientProfile);
          }
        } else {
          obj[defaultAttributeName] = {
            data:
              defaultAttributeName === 'birthdate'
                ? { value: formatDate(patientProfile?.[defaultAttributeName]) }
                : acc?.type === 'FormSelect' || acc?.type === 'FormRadio'
                ? patientProfile?.[defaultAttributeName]
                  ? {
                      value: transformString(patientProfile?.[defaultAttributeName]),
                      label: capitalizeFirstLetter(patientProfile?.[defaultAttributeName]),
                    }
                  : {}
                : { value: patientProfile?.[defaultAttributeName] || '' },
            required: acc?.required,
            error: '',
            type: acc?.type,
          };
        }
      } else if (acc?.attribute_name === 'address' && acc?.options?.length > 0) {
        createStateForDetailedAddress(obj, acc, copyDraftParams);
      } else if (copyDraftParams[(acc?.attribute_name)]) {
        obj[(acc?.attribute_name)] = {
          data: setValueForField(acc, copyDraftParams[(acc?.attribute_name)]),
          required: acc?.required,
          error: '',
          type: acc?.type,
        };
      } else if (
        (acc?.type === 'FormCheckbox' || acc?.type === 'FormRadio') &&
        acc?.options?.length > 0
      ) {
        obj[(acc?.attribute_name)] = {
          data:
            acc?.type === 'FormRadio'
              ? acc?.options?.find(option => option?.selected)
              : acc?.options?.filter(option => option?.selected),
          required: acc?.required,
          error: '',
          type: acc?.type,
        };
      }
      return obj;
    }, {});

    for (const field in paramsFormPatientProfile) {
      copyDraftParams[field] = { ...paramsFormPatientProfile[field] };
    }
    return copyDraftParams;
  };

  const fetchDynamicForm = async id => {
    if (!id) {
      throw new Error('Form ID is required');
    }
    setForm(prevState => ({ ...prevState, status: 'running' }));
    return request(`/api/v2/document_flow/patient/forms/${id}`)
      .then(response => {
        const data = dataFormatter.deserialize(response);
        const patientProfile = data?.patient || {};
        const patientID = patientProfile?.patient_id || {};
        const patientPersonal = patientProfile?.personal || {};
        const patientExtraInfo = patientProfile?.patientExtraInfo || {};

        const defaultDraftParams = createDraftParams(
          data?.draft_params,
          patientProfile?.extra_params,
        );
        const mergePatientPersonalInfo = {
          ...patientProfile,
          ...patientPersonal,
          ...patientExtraInfo,
          ...patientID,
        };

        const formContent = JSON.parse(data?.form_template?.content);
        const formSection = formContent?.pages[0]?.sections;

        const draftParams = applyValuesByQuestionsAnswer(
          formSection,
          defaultDraftParams,
          mergePatientPersonalInfo,
        );

        const mainDraftParams = formSection
          .filter(section => section.required)
          .reduce((obj, { attribute_name, value, required, type }) => {
            obj[attribute_name] = { data: value, required, error: '', type };
            return obj;
          }, {});

        const childFields = formSection
          .filter(section => section?.options)
          .map(({ options }) => options.filter(({ childFields }) => childFields))
          .flat()
          .flat()
          .map(({ childFields }) => childFields)
          .flat()
          .filter(({ attribute_name }) => !DETAILED_ADDRESS_FIELDS.includes(attribute_name))
          .reduce((obj, acc) => {
            obj[(acc?.attribute_name)] = {
              data: setValueForField(acc, draftParams[(acc?.attribute_name)]),
              required: acc?.required,
              error: '',
              type: acc?.type,
            };
            return obj;
          }, {});
        childFieldsRef.current = childFields;

        if (Object.keys(draftParams)?.length > 0) {
          for (const field in draftParams) {
            const item = mainDraftParams[field]
              ? { ...mainDraftParams[field], ...draftParams[field] }
              : childFields[field]
              ? { ...childFields[field] }
              : { ...draftParams[field] };

            if (item?.data) {
              mainDraftParams[field] = item;
            }

            // if (childFieldsRef.current[field]) {
            //   childFieldsRef.current[field] = data;
            // }
          }
        }

        // const questionsWithConditions = formSection?.filter(data => data?.conditions);

        setForm({ data, status: 'ready', error: null });
        // setQuestionsWithConditions(questionsWithConditions);
        setDraftParams(mainDraftParams);
        patientProfileRef.current = mergePatientPersonalInfo;
      })
      .catch(error => {
        console.error(error)
        const { response, status } = error;

        setForm({ data: [], status: 'error', error: { response, status } });
      });
  };

  const updateDynamicForm = async (formId, payload) => {
    if (aborter) aborter.abort();

    if (!formId) {
      throw new Error('Form ID is required');
    }
    aborter = new AbortController();
    const signal = aborter.signal;

    setSavingForm('running');
    return fetch(`/api/v2/document_flow/patient/documents/${formId}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      signal,
      headers: new Headers({ 'content-type': 'application/json' }),
    })
      .then(() => {
        aborter = null;
        setSavingForm('ready');
      })
      .catch(() => setSavingForm('error'));
  };

  const signFormDocument = (formId, payload) => {
    if (!formId) {
      throw new Error('Form ID is required');
    }

    return request(`/api/v2/document_flow/patient/documents/${formId}/sign`, {
      method: 'POST',
      payload,
    });
  };

  const updateLocallyDraftParams = (field, { value, error, type }) => {
    const defaultParams = { data: {}, error: '' };
    const draft = draftParams[field]
      ? {
          ...draftParams,
          [field]: {
            ...draftParams[field],
            data: FORM_TYPE_WITH_LIST_DATA.includes(type) ? value : { value },
            error,
            type,
          },
        }
      : {
          ...draftParams,
          [field]: {
            ...defaultParams,
            data: FORM_TYPE_WITH_LIST_DATA.includes(type) ? value : { value },
            error,
            type,
          },
        };

    setDraftParams(draft);

    return draft;
  };

  const updateLocallyDraftParamsWithAddress = params => {
    const draft = { ...draftParams };

    for (const field in params) {
      draft[field] = { data: { value: params[field] }, error: '' };
    }

    setDraftParams(draft);

    return draft;
  };

  const updateDraftParams = (field, data, isAddress = false, isUpdateDynamicForm = true) => {
    const draft = isAddress
      ? updateLocallyDraftParamsWithAddress(data)
      : updateLocallyDraftParams(field, data);

    const payload = isAddress ? createPayloadWithAddress(data) : createPayload(field, data);

    for (const field in draft) {
      childFieldsRef.current[field] = { ...draft[field] };
    }

    if (isUpdateDynamicForm) {
      updateDynamicForm(form?.data?.id, payload);
    }
  };

  const isEmptyTableRows = data => {
    try {
      const rows = JSON.parse(data);

      if (Array.isArray(rows)) {
        return (
          Array.isArray(rows) && rows?.every(obj => obj?.row?.every(element => element === ''))
        );
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  const checkTypeOfQuestionOnErrors = (element, draftField, draftValue) => {
    if (element) {
      if (draftField?.required && (isEmpty(draftValue) || isEmptyTableRows(draftValue)))
        return 'This field is required';
      if (
        draftField?.type === 'FormDate' &&
        draftValue?.length > 0 &&
        !moment(draftValue, 'MM/DD/YYYY', true).isValid()
      )
        return 'Invalid date';
      if (
        draftField?.type === 'FormPhone' &&
        draftValue?.length > 0 &&
        !validatePhoneNumber(draftValue)
      )
        return 'Invalid phone number';
    } else {
      return '';
    }
  };

  const checkFieldsOnErrors = () => {
    const draft = deepClone(draftParams);

    for (const field in draft) {
      const element = document.getElementById(field);
      const draftValue = FORM_TYPE_WITH_LIST_DATA.includes(draft[field]?.type)
        ? draft[field]?.data
        : draft[field]?.data?.value;

      const error = checkTypeOfQuestionOnErrors(element, draft[field], draftValue);

      if (error) {
        draft[field] = { ...draft[field], error };
      } else {
        draft[field] = { ...draft[field], error: '' };
      }
    }

    if (childFieldsRef.current && Object.keys(childFieldsRef.current)?.length > 0) {
      for (const field in childFieldsRef.current) {
        const element = document.getElementById(field);
        const childFieldDraftValue = FORM_TYPE_WITH_LIST_DATA.includes(
          childFieldsRef.current[field]?.type,
        )
          ? childFieldsRef.current[field]?.data
          : childFieldsRef.current[field]?.data?.value;

        const error = checkTypeOfQuestionOnErrors(
          element,
          childFieldsRef.current[field],
          childFieldDraftValue,
        );

        if (error) {
          draft[field] = { ...childFieldsRef.current[field], error };
        } else {
          draft[field] = { ...childFieldsRef.current[field], error: '' };
        }
      }
    }

    setDraftParams(draft);
    return draft;
  };

  const getValuesByQuerySelector = selector => {
    const elements = document.querySelectorAll(`[${selector}]`);

    if (elements) {
      return Array.from(elements)?.map(node => node.getAttribute(`${selector}`));
    }

    return [];
  };

  const prepareDataForSignatureForm = () => {
    const draft = deepClone(draftParams);
    const dataForSignature = {};

    // MARK: We fetch all elements which view on page
    const listDisplayedQuestions = getValuesByQuerySelector('data-question-id');

    for (const field in draft) {
      const element = document.getElementById(field);

      if (element && element.hasAttribute('data-question-id')) {
        const attributeValue = element.getAttribute('data-question-id');

        if (listDisplayedQuestions.includes(attributeValue)) {
          dataForSignature[field] = { ...draft[field] };
        }
      }
    }

    setDataForSignature({ answers: dataForSignature, listDisplayedQuestions });
  };

  const fetchDocumentsList = async params => {
    return request(`/api/v2/document_flow/patient/signed_documents${params}`).then(res => {
      return {
        data: dataFormatter.deserialize(res),
        meta: res?.meta,
      };
    });
  };

  const uploadFile = async formData => {
    return request('/api/v2/document_flow/patient/signed_documents/upload_file', {
      method: 'POST',
      formData,
    });
  };

  const fetchDocumentRequest = async id => {
    return request(`/api/v2/document_flow/patient/signed_documents/${id}`).then(res =>
      dataFormatter.deserialize(res),
    );
  };

  const signDocumentRequest = async payload => {
    return request('/api/v2/document_flow/patient/signed_documents/sign_document', {
      method: 'POST',
      payload,
    });
  };

  const clearDraftParams = async (documentId, formId, refetchDynamicForm = false) => {
    return request(`/api/v2/document_flow/patient/documents/${documentId}/delete_draft_params`, {
      method: 'DELETE',
    }).then(() => {
      if (refetchDynamicForm) {
        fetchDynamicForm(formId);
      }
    });
  };

  const uploadDocumentFiles = async formData => {
    return request('/api/v2/document_flow/patient/document_files', {
      method: 'POST',
      formData,
    }).then(res => dataFormatter.deserialize(res));
  };

  const removeDocumentFile = id => {
    if (!id) {
      throw new Error('Unable to find document ID');
    }
    return request(`/api/v2/document_flow/patient/document_files/${id}`, { method: 'DELETE' });
  };

  const contextValue = {
    craeteEasySignIn,
    fetchDynamicForm,
    updateDynamicForm,
    updateDraftParams,
    checkFieldsOnErrors,
    signFormDocument,
    form,
    savingForm,
    questionsWithConditions,
    questionsWithRequred,
    draftParams,
    patientProfileRef,
    fetchDocumentsList,
    uploadFile,
    fetchDocumentRequest,
    signDocumentRequest,
    prepareDataForSignatureForm,
    dataForSignature,
    clearDraftParams,
    uploadDocumentFiles,
    removeDocumentFile,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useDocumentFlow() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useDocumentFlow must be used with DocumentFlow');
  }
  return context;
}
