import React, { createContext, useContext, useState } from 'react';
import RedditPixel from 'react-reddit-pixel';
import ReactFbq from 'react-fbq';

export const StateContext = createContext(null);

export default function TrackingPixelsProvider({ children }) {
  const [redditPixel, setRedditPixel] = useState(null);

  const viewImagePixel = imageSrc => {
    const image = document.createElement('img');
    image.src = imageSrc;
    image.width = 1;
    image.height = 1;
    image.alt = '';

    document.body.appendChild(image);
  };

  const connectImagePixel = (partnerId, event) => {
    if (Number(partnerId) === 1012) {
      if (event === 'payment') {
        const imageSrc =
          'https://ad.doubleclick.net/ddm/activity/src=12972466;type=conver;cat=verte001;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=;gdpr_consent=;ord=1;num=1?';

        viewImagePixel(imageSrc);
      }

      if (event === 'short_registration') {
        const imageSrc =
          'https://ad.doubleclick.net/ddm/activity/src=12972466;type=conver;cat=verte000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=;gdpr_consent=;ord=1;num=1?';

        viewImagePixel(imageSrc);
      }
    }
  };

  const initRedditPixel = (advertiserId, options = { debug: false }) => {
    RedditPixel.init(advertiserId, options);
    RedditPixel.pageVisit();

    setRedditPixel(RedditPixel);
  };

  const initFacebookPixel = id => {
    ReactFbq.initialize({ id });
    ReactFbq.pageView();
  };

  const connectTrackingScriptToHtml = src => {
    const script = document.createElement('script');

    script.src = src;

    document.head.appendChild(script);
  };

  const contextValue = {
    connectImagePixel,
    initRedditPixel,
    initFacebookPixel,
    connectTrackingScriptToHtml,
    redditPixel,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useTrackingPixels() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useTrackingPixels must be used with TrackingPixelsProvider');
  }
  return context;
}
