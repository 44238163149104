export const capitalizeFirstLetter = string =>
  string && typeof string === 'string' ? string.charAt(0).toUpperCase() + string.slice(1) : '';

export const formatBrowserDate = date => {
  const parsedDate = new Date(date);

  // Extract the date components
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
  const day = String(parsedDate.getDate()).padStart(2, '0');

  // Format the date as MM-DD-YYYY
  return `${month}-${day}-${year}`;
};

export const transformString = string =>
  typeof string === 'string'
    ? string
        ?.toLowerCase()
        ?.split(' ')
        ?.join('_')
    : '';

export const formatPrice = price => {
  // Convert price to a string with two decimal places
  let formattedPrice = Number(price).toFixed(2);

  // Remove trailing zeros and decimal point if the price is an integer
  formattedPrice = formattedPrice.replace(/\.00$/, '');

  // Add commas for thousands separator
  formattedPrice = formattedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return formattedPrice;
};
