import React, { createContext, useContext } from 'react';
import { request } from 'modules/client';
import { dataFormatter } from 'utils/connectPartnerConfigs';

export const StateContext = createContext(null);

export default function VisitFlowProvider({ children }) {
  const fetchColoringBooks = async () => {
    return request(`/api/v2/visit_flow/patient/coloring_books`).then(response => response);
  };

  const addColoringBooks = async payload => {
    return request(`/api/v2/visit_flow/patient/coloring_books`, { method: 'POST', payload }).then(
      response => response,
    );
  };

  const fetchPatientSettings = async () => {
    return request(`/api/v2/visit_flow/patient/settings`).then(response => response);
  };

  const fetchFormsVisitTypes = async () => {
    return request(`/api/v2/visit_flow/patient/visit_types`).then(response =>
      dataFormatter.deserialize(response),
    );
  };

  const contextValue = {
    fetchColoringBooks,
    addColoringBooks,
    fetchPatientSettings,
    fetchFormsVisitTypes,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useVisitFlow() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useVisitFlow must be used with VisitFlowProvider');
  }
  return context;
}
