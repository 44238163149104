import React from 'react';
import styled from 'styled-components';

import Success from 'components/Alert/Success';
import Warning from 'components/Alert/Warning';
import Error from 'components/Alert/Error';

const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ background }) => (background ? 'rgba(255, 255, 255, .55)' : 'transparent')};
  z-index: 12;
`;

const Header = styled.div`
  font-size: 13px;
  color: #324243;
  font-style: normal;
  font-weight: 400;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
  text-transform: uppercase;
  @media (min-width: 768px) {
    text-align: unset;
  }
`;

const Subheader = styled.div`
  font-size: 13px;
  color: #324243;
  font-style: normal;
  font-weight: 300;
`;

const Message = ({ header = '', subheader, type = 'error' }) => {
  return (
    <CenterContainer>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {type === 'error' && <Error />}
        {type === 'warning' && <Warning />}
        {type === 'success' && <Success />}
        <div>
          <Header>{header}</Header>
        </div>
        <div style={{ marginTop: '0.5rem' }}>
          <Subheader>{subheader}</Subheader>
        </div>
      </div>
    </CenterContainer>
  );
};

export default Message;
