import React, { createContext, useContext, useState } from 'react';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';

import { useGtagManager } from 'state/gTagManager';

import { request } from 'modules/client';
import { isEmpty } from 'modules/validationFields';
import { GTM_TAGS_FOR_TELELEAF_PARTNER } from 'modules/analytics';

import { useTrackingPixels } from 'state/trackingPixels';

import { dataFormatter, createZopimChat } from 'utils/connectPartnerConfigs';

export const StateContext = createContext(null);

export default function ProfileProvider({ children }) {
  const DEFAULT_STATE = {
    data: [],
    status: 'idle',
    error: null,
  };

  const [profile, setProfile] = useState(DEFAULT_STATE);

  const { initGtagData } = useGtagManager();
  const { initRedditPixel, initFacebookPixel } = useTrackingPixels();

  const fetchProfileInformation = query => {
    setProfile({ data: [], status: 'running', error: null });
    request(`/v1/patient_admin/session${query}`)
      .then(response => {
        const data = dataFormatter.deserialize(response);
        const partnerUser = data?.partner_user;

        if (partnerUser?.partner_config?.gtm_code) {
          // This's statemenet need for teleleaf partner with connect several gtm codes
          if (partnerUser?.id === '592') {
            GTM_TAGS_FOR_TELELEAF_PARTNER.forEach(gtmCode =>
              TagManager.initialize({ gtmId: gtmCode }),
            );
          }
          TagManager.initialize({ gtmId: partnerUser?.partner_config?.gtm_code });

          if (
            partnerUser?.partner_config?.aw_conversion_id &&
            partnerUser?.partner_config?.tracking?.some(item => !isEmpty(item?.snippet))
          ) {
            initGtagData({
              awConversionID: partnerUser?.partner_config?.aw_conversion_id,
              tracking: partnerUser?.partner_config?.tracking,
              isEnabledTracking: true,
            });
          }
        }

        if (partnerUser?.partner_config?.ga_code) {
          ReactGA.initialize(partnerUser?.partner_config?.ga_code);
        }

        if (partnerUser?.id === '1249') {
          initRedditPixel('a2_di3gm1c592uo', { debug: false });
        }

        if (partnerUser?.id === '1162') {
          initFacebookPixel('1599067277505529');
        }

        if (partnerUser?.partner_config?.show_chat_widget) {
          createZopimChat();
        }

        setProfile({ data, status: 'ready', error: null });
      })
      .catch(error => setProfile({ status: 'error', error }));
  };

  const contextValue = {
    fetchProfileInformation,
    profile,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useProfile() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useProfile must be used with ProfileProvider');
  }
  return context;
}
