import React from "react";

export default function Transaction({ width, height, style }) {
  return (
    <svg style={style} width={width} height={height} fill="none" viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 2.356v9.288c0 .748-.598 1.356-1.333 1.356H2.333A1.346 1.346 0 011 11.644V7.576c0-.15.12-.27.267-.27.147 0 .266.12.266.27v4.068c0 .449.36.814.8.814h13.334c.44 0 .8-.365.8-.814V2.356a.808.808 0 00-.8-.814H2.333c-.44 0-.8.365-.8.814V3.44H15.2c.147 0 .267.121.267.27 0 .15-.12.272-.267.272H1.533v1.763H15.2c.147 0 .267.121.267.27 0 .15-.12.272-.267.272H1.267A.269.269 0 011 6.017V2.356C1 1.608 1.598 1 2.333 1h13.334C16.402 1 17 1.608 17 2.356zm-2.133 7.05c0-.695-.761-1.118-1.338-.739.566.943-.104 2.164-1.196 2.164-.772 0-1.4-.64-1.4-1.424 0-1.169 1.315-1.838 2.234-1.143.922-.695 2.233-.023 2.233 1.143 0 .785-.628 1.424-1.4 1.424a.269.269 0 01-.267-.272c0-.15.12-.27.267-.27a.875.875 0 00.867-.882zm-1.667 0a.875.875 0 00-.867-.88.875.875 0 00-.866.88c0 .487.388.882.866.882a.875.875 0 00.867-.881zm-9.767-.575h2.234a.269.269 0 00.266-.272c0-.15-.12-.27-.266-.27H3.433a.269.269 0 00-.266.27c0 .15.12.272.266.272zm0 1.694h2.234a.269.269 0 00.266-.27c0-.15-.12-.272-.266-.272H3.433a.269.269 0 00-.266.271c0 .15.12.271.266.271z"
        fill={'var(--primaryColor)'}
        stroke={'var(--primaryColor)'}
        strokeWidth=".2"
      />
    </svg>
  );
}
