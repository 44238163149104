import React, { useState, createContext, useContext } from 'react';
import moment from 'moment-timezone';
import Jsona from 'jsona';

import { isEmpty } from 'modules/validationFields';
import { request, compileQuery, parseError, encodeQueryData } from 'modules/client';

export const StateContext = createContext(null);

const dataFormatter = new Jsona();

export default function SchedulingGuestProvider({ children }) {
  const DEFAULT_STATE = {
    data: [],
    status: 'idle',
    message: null,
  };
  const [iframeFreeDays, setIframeFreeDays] = useState({
    ...DEFAULT_STATE,
    groupedByDays: [],
    preSelectDate: null,
  });
  const [guestCoupon, setGuestCoupon] = useState({
    ...DEFAULT_STATE,
    data: null,
    name: '',
    type: '',
  });

  const [doctors, setDoctors] = useState(DEFAULT_STATE);
  const [visitTypes, setVisitTypes] = useState(DEFAULT_STATE);

  const sortedByOrder = data => data?.sort((a, b) => a?.order - b?.order);

  const groupedByCategrory = (payload, formatter) => {
    const uniqueId = Date.now();
    const otherCategory = {
      type: 'category',
      id: uniqueId?.toString(),
      name: 'Other',
    };

    const listCategories = dataFormatter.deserialize({
      data: payload.included.filter(obj => obj.type === 'category'),
    });

    const listVisitTypes = listCategories.map(value => ({
      ...value,
      listVisitTypes: sortedByOrder(
        formatter?.filter(item => item.categories.find(el => el.id === value.id)),
      ),
    }));

    const listVisitTypesWithOtherCategory = formatter.filter(
      item => item?.categories?.length === 0,
    );

    if (listVisitTypesWithOtherCategory?.length > 0)
      return sortedByOrder([
        ...listVisitTypes,
        { ...otherCategory, listVisitTypes: listVisitTypesWithOtherCategory },
      ]);

    return sortedByOrder(listVisitTypes);
  };

  const groupByDays = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {});
  const groupByTZDays = (data, timezone) =>
    data.reduce((acc, obj) => {
      // Convert time string to moment object with the correct timezone
      const date = moment.tz(obj.time, timezone).format('MM-DD-YYYY'); // Adjust timezone if needed

      // Initialize the array if this date hasn't been seen before
      if (!acc[date]) {
        acc[date] = [];
      }

      // Add the object to the array for this date
      acc[date].push(obj);

      return acc;
    }, {});

  const getPreSelectDate = (currentSelectDate, queryStartDate, firstAvailableDate, timezone) => {
    const momentCurrentSelectDate = timezone
      ? moment.tz(currentSelectDate, timezone)
      : moment(currentSelectDate);

    const momentQueryStartDate = timezone
      ? moment.tz(queryStartDate, timezone)
      : moment(queryStartDate);

    const momentToday = timezone ? moment.tz(timezone) : moment();

    if (currentSelectDate) return momentCurrentSelectDate.format('MM-DD-YYYY');
    if (queryStartDate) {
      // Duplicate set first available date. Because calendar return first date if switch months
      if (firstAvailableDate) return firstAvailableDate;
      if (momentQueryStartDate.isSame(moment(), 'month')) return momentToday.format('MM-DD-YYYY');
      return momentQueryStartDate.format('MM-DD-YYYY');
    }
    if (firstAvailableDate) return firstAvailableDate;
    return momentToday.format('MM-DD-YYYY');
  };

  const fetchGuestDoctors = async payload => {
    const params = payload ? `?${encodeQueryData(payload)}` : '';

    setDoctors(prevState => ({ ...prevState, status: 'running', error: null }));

    return request(`/api/v2/scheduling/guest/doctors${params}`)
      .then(response => {
        const data = dataFormatter.deserialize(response);

        setDoctors(prevState => ({
          ...prevState,
          data,
          status: 'ready',
          error: null,
        }));
      })
      .catch(err => {
        setDoctors({
          data: [],
          status: 'error',
          error: parseError(err?.response?.error),
        });
      });
  };

  const validateGuestCoupon = async query => {
    const params = query ? `?${compileQuery(query)}` : '';

    return request(`/api/v2/payments/guest/coupons/validate${params}`).then(({ data }) => data);
  };

  const validateGuestPromoCoupon = async query => {
    const params = query ? `?${compileQuery(query)}` : '';

    return request(`/api/v2/payments/guest/promo_coupons/validate${params}`).then(
      ({ data }) => data,
    );
  };

  const validateGuestCoupons = async query => {
    setGuestCoupon(prevState => ({ ...prevState, status: 'running' }));

    validateGuestPromoCoupon(query)
      .then(data => {
        setGuestCoupon({
          data,
          status: 'ready',
          name: query?.coupon,
          message: null,
          type: 'promoCoupon',
        });
      })
      .catch(() => {
        validateGuestCoupon(query)
          .then(data => {
            setGuestCoupon({
              data,
              status: 'ready',
              name: query?.coupon,
              message: null,
              type: 'coupon',
            });
          })
          .catch(err => {
            setGuestCoupon({
              data: null,
              status: 'error',
              name: '',
              message: parseError(err?.response?.error),
              type: '',
            });
          });
      });
  };

  const iframeFetchFreeDays = async (query, currentSelectDate, formatByTZ) => {
    const params = query ? `?${compileQuery(query)}` : '';

    setIframeFreeDays(prevState => ({ ...prevState, status: 'running', message: null }));
    return request(`/api/v2/scheduling/guest/available_times${params}`)
      .then(({ data }) => {
        const groupedByDays = formatByTZ
          ? groupByTZDays(data, formatByTZ)
          : groupByDays(data, v => moment(v?.time).format('MM-DD-YYYY'));
        const firstAvailableDate = !isEmpty(groupedByDays) ? Object.keys(groupedByDays)[0] : null;
        const queryStartDate = query?.start_date;

        const preSelectDate = getPreSelectDate(
          currentSelectDate,
          queryStartDate,
          firstAvailableDate,
          formatByTZ,
        );

        setIframeFreeDays({
          data,
          groupedByDays,
          preSelectDate,
          status: 'ready',
          message: null,
        });
      })
      .catch(err => {
        console.log('err', err);
        setIframeFreeDays({
          data: [],
          groupedByDays: [],
          preSelectDate: null,
          status: 'error',
          message: parseError(err?.response?.error),
        });
      });
  };

  const fetchGuestVisitTypes = async (payload, joinByCategory = true) => {
    const params = payload ? `?${encodeQueryData(payload)}` : '';

    setVisitTypes(prevState => ({ ...prevState, status: 'running', error: null }));
    return request(`/api/v2/scheduling/guest/visit_types${params}`)
      .then(response => {
        const hasCategories =
          response?.included?.some(item => item?.type === 'category') && joinByCategory;
        const formatter = dataFormatter.deserialize(response);
        const data = hasCategories ? groupedByCategrory(response, formatter) : formatter;

        setVisitTypes(prevState => ({ ...prevState, data, status: 'ready' }));
      })
      .catch(err => {
        setVisitTypes({
          data: [],
          status: 'error',
          error: parseError(err?.response?.error),
        });
      });
  };

  const fetchGuestVisitType = async (id, params, joinByCategory = true) => {
    if (!id) {
      throw new Error('Visit type id not found');
    }

    const query = params ? `?${encodeQueryData(params)}` : '';

    setVisitTypes(prevState => ({ ...prevState, status: 'running', error: null }));
    return request(`/api/v2/scheduling/guest/visit_types/${id}${query}`)
      .then(response => {
        const hasCategories =
          response?.included?.some(item => item?.type === 'category') && joinByCategory;
        const formatter = dataFormatter.deserialize(response);
        const coverToArray = [{ ...formatter }];
        const data = hasCategories ? groupedByCategrory(response, coverToArray) : coverToArray;

        setVisitTypes(prevState => ({ ...prevState, data, status: 'ready' }));
      })
      .catch(err => {
        setVisitTypes({
          data: [],
          status: 'error',
          error: parseError(err?.response?.error),
        });
      });
  };

  const fetchAvailableDoctors = async (query = '') => {
    const params = query ? `?${encodeQueryData(query)}` : '';

    setDoctors(prevState => ({ ...prevState, status: 'running', message: null }));
    return request(`/api/v2/scheduling/guest/available_times/doctors${params}`)
      .then(response => {
        const data = dataFormatter.deserialize(response);

        setDoctors(prevState => ({ ...prevState, data, status: 'ready' }));
      })
      .catch(err =>
        setDoctors({ data: [], status: 'error', message: parseError(err?.response?.error) }),
      );
  };

  const setDefaultFreeDaysState = () =>
    setIframeFreeDays({ ...DEFAULT_STATE, groupedByDays: [], preSelectDate: null });

  const setDefaultAvailableDoctors = () => setDoctors(DEFAULT_STATE);

  const contextValue = {
    fetchGuestDoctors,
    iframeFreeDays,
    iframeFetchFreeDays,
    guestCoupon,
    validateGuestCoupon,
    validateGuestPromoCoupon,
    validateGuestCoupons,
    doctors,
    fetchGuestVisitTypes,
    fetchGuestVisitType,
    visitTypes,
    setDefaultFreeDaysState,
    setDefaultAvailableDoctors,
    fetchAvailableDoctors,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useSchedulingGuest() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useSchedulingGuest must be used with SchedulingGuestProvider');
  }
  return context;
}
