import React from 'react';

const Success = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className="ui-success">
        <svg viewBox="0 0 87 87" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group-3" transform="translate(2.000000, 2.000000)">
              <circle
                id="Oval-2"
                stroke="#4cc963"
                strokeWidth="4"
                cx="41.5"
                cy="41.5"
                r="41.5"
              ></circle>
              <circle
                className="ui-success-circle"
                id="Oval-2"
                stroke="#4cc963"
                strokeWidth="4"
                cx="45.5"
                cy="44.5"
                r="41.5"
              ></circle>
              <polyline
                className="ui-success-path"
                id="Path-2"
                stroke="#4cc963"
                strokeWidth="4"
                points="19 38.8036813 31.1020744 54.8046875 63.299221 28"
              ></polyline>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Success;
