import React from 'react';

export default function Male({ width, height, style, fill }) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      fill="none"
      viewBox="0 0 20 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.571 20.068a1.913 1.913 0 01-1.645-.924.46.46 0 10-.787.479c.245.402.577.727.963.96l-.477 2.165-1.15-1.325c.208-.24.158-.52-.022-.677a.46.46 0 00-.65.046l-.809.93-2.04-2.265c.174-.334.28-.7.31-1.08 3.946 1.161 7.894-1.817 7.894-5.909V8.662a.46.46 0 10-.92 0v3.806A5.243 5.243 0 0110 17.705a5.243 5.243 0 01-5.237-5.237V8.561c1.122-.064 2.077-.769 2.417-2.046a15.75 15.75 0 006.321 2.339.46.46 0 10.128-.912 14.838 14.838 0 01-6.528-2.59.461.461 0 00-.727.378c0 .012-.004 1.154-.87 1.675-.548.33-1.102.223-1.197.222a.46.46 0 00-.462.407c-.005.045-.003-.107-.003 3.684a.95.95 0 01-.518-.842v-5.61c0-.602.49-1.093 1.094-1.093a.46.46 0 00.427-.289 4.098 4.098 0 013.823-2.588h3.948a4.122 4.122 0 014.118 4.117v5.463a.46.46 0 00.92 0V5.413A5.044 5.044 0 0012.616.375H8.668a5.012 5.012 0 00-4.563 2.902 2.018 2.018 0 00-1.702 1.99v5.609c0 .88.616 1.621 1.444 1.82a6.167 6.167 0 003.506 5.331v.122c0 .513-.2.995-.564 1.357-.644.642-1.456.562-1.36.562A4.75 4.75 0 00.677 24.82v2.72a.46.46 0 10.92 0v-2.72a3.83 3.83 0 013.832-3.832c.201 0 .403-.02.601-.063l.643 2.915a.46.46 0 00.797.203l.907-1.044-.56 4.483a.46.46 0 00.913.115l.648-5.182c.03.032.285.32.318.348.19.167.48.15.649-.04l.276-.318.649 5.192a.46.46 0 10.914-.115l-.561-4.484.907 1.045a.46.46 0 00.797-.203l.643-2.916c.195.042.396.064.601.064a3.83 3.83 0 013.832 3.832v2.72a.46.46 0 00.92 0v-2.72a4.75 4.75 0 00-4.752-4.752zm-7.196 2.68l-.477-2.166a2.86 2.86 0 00.492-.377l1.116 1.24-1.13 1.303z"
        fill={fill || '#324243'}
      />
    </svg>
  );
}
