import React from 'react';

import { useRouter } from 'hooks/router.hook';

import IntermediateRoutes from './Intermediate';
import PatientMainRouter from './Main';
import IntegrationsRoutes from './Integrations';

const INTERMEDIATE_ROUTES = [
  '/f/patient_admin/reset_password',
  '/f/patient_admin/intermediate/credit_card',
  '/f/patient_admin/intermediate/birthdate',
  '/f/patient_admin/intermediate/check_in',
  '/f/patient_admin/intermediate/patient_id',
  '/f/patient_admin/intermediate/document',
  '/f/patient_admin/intermediate/manage_appt',
];

const INTEGRATIONS_ROUTES = 'callback/sezzle';

const MainRoutes = ({ dispatch }) => {
  const { pathname } = useRouter();

  if (pathname.indexOf(INTEGRATIONS_ROUTES) >= 0) {
    return <IntegrationsRoutes />;
  }

  if (INTERMEDIATE_ROUTES.indexOf(pathname) >= 0) {
    return <IntermediateRoutes dispatch={dispatch} />;
  }

  return <PatientMainRouter />;
};

export default MainRoutes;
