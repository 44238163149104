import React from 'react';

export default function ChevronRight({ style, fill }) {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="18px"
      fill={fill || '#000000'}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    </svg>
  );
}