import { all, call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'modules/client';
import _ from 'lodash';

import { ActionTypes } from '../constants/partner';

const baseUrl = '/v1/partner_admin';

export function* fetchPartnerProfileCropped({ payload }) {
  const { subdomain } = payload;

  try {
    const response = yield call(request, `${baseUrl}/cropped/profiles/${subdomain}`);

    yield put({
      type: ActionTypes.FETCH_PARTNER_PROFILE_CROPPED_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_PARTNER_PROFILE_CROPPED_FAIL,
      payload: error,
    });
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.FETCH_PARTNER_PROFILE_CROPPED, fetchPartnerProfileCropped)]);
}
