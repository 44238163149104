import React from "react";

export default function Cheeseburger({ width, height, style }) {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 .5A.5.5 0 01.5 0h15a.5.5 0 010 1H.5A.5.5 0 010 .5zm0 5A.5.5 0 01.5 5h15a.5.5 0 010 1H.5a.5.5 0 01-.5-.5zM.5 10a.5.5 0 000 1h15a.5.5 0 000-1H.5z"
        fill="var(--primaryColor)"
        fillRule="evenodd"
      />
    </svg>
  );
}