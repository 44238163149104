import React, { Suspense, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import AuthPatientProvider from 'state/patient/auth';
import DocumentFlowProvider from 'state/documentFlow';
import SchedulingPatientsProvider from 'state/scheduling/patients';
import PatientProvider from 'state/patient';
import VisitFlowProvider from 'state/visitFlow';
import MedicalDocumentProvider from 'state/patient/medicalDocument';
import PaymentProvider from 'state/patient/payment';
import PatientProfileCouponProvider from 'state/patient/profile/coupon';

import RippleLoader from 'components/Preloader/RippleLoader';
import { ErrorBoundaryCmp } from 'components/ErrorBoundary';

import IntermediateLayout from 'containers/Patient/IntermediateLayout';

import { lazyWithRetry } from 'utils/rendering';

import LangContext from 'state/language';

const ForgotPassword = lazyWithRetry(() => import('pages/Patient/Auth/ForgotPassword'));
const IntermediateCreditCard = lazyWithRetry(() => import('pages/Patient/Intermediate/CreditCard'));
const IntermediateOfficeCheckIn = lazyWithRetry(() =>
  import('pages/Patient/Intermediate/OfficeCheckIn'),
);
const IntermediatePatientId = lazyWithRetry(() => import('pages/Patient/Intermediate/PatientId'));
const Birthdate = lazyWithRetry(() => import('pages/Patient/Auth/Birthdate'));
const IntermediateMedicalDocument = lazyWithRetry(() =>
  import('pages/Patient/Intermediate/MedicalDocument'),
);
const ManageAppt = lazyWithRetry(() => import('pages/Patient/Intermediate/ManageAppt'));

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  margin: 3.5rem 0rem 0rem 0rem;
  position: relative;
  height: 100%;
  @media only screen and (max-width: 649px) {
    min-height: 460px;
    margin: 2.5rem 0rem 1.5rem 0rem;
    align-items: initial;
    flex: auto;
  }
`;

const IntermediateRoutes = () => {
  const {
    currentLangData: { main_layout },
  } = useContext(LangContext);

  const renderContent = ({ component: Component }) => {
    return (
      <ErrorBoundaryCmp>
        <Main>
          <Component />
        </Main>
      </ErrorBoundaryCmp>
    );
  };

  return (
    <>
      <AuthPatientProvider>
        <Suspense fallback={<RippleLoader color="var(--primaryColor)" />}>
          <Switch>
            <Route
              path="/f/patient_admin/reset_password"
              render={() => renderContent({ component: ForgotPassword })}
            />
            <Route
              path="/f/patient_admin/intermediate/credit_card"
              render={() => (
                <IntermediateLayout>
                  <VisitFlowProvider>
                    <PaymentProvider>
                      <PatientProvider>
                        <PatientProfileCouponProvider>
                          <IntermediateCreditCard />
                        </PatientProfileCouponProvider>
                      </PatientProvider>
                    </PaymentProvider>
                  </VisitFlowProvider>
                </IntermediateLayout>
              )}
            />
            <Route
              path="/f/patient_admin/intermediate/check_in"
              render={() => (
                <IntermediateLayout>
                  <SchedulingPatientsProvider>
                    <IntermediateOfficeCheckIn />
                  </SchedulingPatientsProvider>
                </IntermediateLayout>
              )}
            />
            <Route
              exact
              path="/f/patient_admin/intermediate/birthdate"
              render={() => (
                <ErrorBoundaryCmp>
                  <DocumentFlowProvider>
                    <Main>
                      <Birthdate />
                    </Main>
                  </DocumentFlowProvider>
                </ErrorBoundaryCmp>
              )}
            />
            <Route
              exact
              path="/f/patient_admin/intermediate/patient_id"
              render={() => (
                <ErrorBoundaryCmp>
                  <IntermediateLayout>
                    <PatientProvider>
                      <IntermediatePatientId translate={main_layout?.content?.update_id} />
                    </PatientProvider>
                  </IntermediateLayout>
                </ErrorBoundaryCmp>
              )}
            />
            <Route
              exact
              path="/f/patient_admin/intermediate/document"
              render={() => (
                <ErrorBoundaryCmp>
                  <IntermediateLayout>
                    <MedicalDocumentProvider>
                      <IntermediateMedicalDocument
                        translate={main_layout?.content?.upload_document}
                      />
                    </MedicalDocumentProvider>
                  </IntermediateLayout>
                </ErrorBoundaryCmp>
              )}
            />
            <Route
              exact
              path="/f/patient_admin/intermediate/manage_appt"
              render={() => (
                <ErrorBoundaryCmp>
                  <IntermediateLayout>
                    <SchedulingPatientsProvider>
                      <ManageAppt />
                    </SchedulingPatientsProvider>
                  </IntermediateLayout>
                </ErrorBoundaryCmp>
              )}
            />
          </Switch>
        </Suspense>
      </AuthPatientProvider>
    </>
  );
};

export default IntermediateRoutes;
