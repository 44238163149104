import React, { useState, useRef, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { request } from 'modules/client';
import { addAlpha } from 'modules/theme';

import { useRouter } from 'hooks/router.hook';
import { useMessage } from 'hooks/message.hook';
import useOnClickOutside from 'hooks/onClckOutside.hook';

import { usePartnerSettingState } from 'state/partnerSettingState';

import {
  LogoutIcon,
  CheeseburgerIcon,
  UserIcon,
  HomeIcon,
  MessageIcon,
  RecordIcon,
  TransactionIcon,
  VisitIcon,
  EditIcon,
  HouseIcon,
} from 'images/SvgIcons';

import Dropdown from 'components/Dropdown/DropdownMD';
import Select from 'components/Select/SelectMD';

const fade = keyframes`
  to {
    opacity: 1;
  }
`;

const fadeUp = keyframes`
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeDown = keyframes`
  to {
    transform: translateY(20px);
    opacity: 0;
  }
`;

const Main = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
  filter: ${({ small }) => (small ? 'blur(4px)' : 'none')};
  @media (min-width: 600px) {
    filter: none;
  }
`;

const Layout = styled.div`
  position: relative;
  ${({ direction }) => (direction === 'right' ? 'padding-right: 0px' : 'padding-left: 0px')};
  ${({ direction }) => (direction === 'right' ? 'right: 0' : 'left: 0')};
  transition: all 0.2s ease-out;
  @media (min-width: 992px) {
    ${({ direction }) => (direction === 'right' ? 'padding-right: 68px' : 'padding-left: 68px')};
    ${({ direction }) => (direction === 'right' ? 'right: 0' : 'left: 0')};
  }

  @media (min-width: 1200px) {
    padding-left: ${({ small, disabledSidebar, direction }) =>
      direction === 'right' ? 'unset' : disabledSidebar ? '0px' : small ? '68px' : ' 264px'};
    padding-right: ${({ small, disabledSidebar, direction }) =>
      direction !== 'right' ? 'unset' : disabledSidebar ? '0px' : small ? '68px' : ' 264px'};
`;

const SideNav = styled.ul`
  position: absolute;
  z-index: 999;
  height: 100%;
  width: 288px;
  background-color: #ffffff;
  visibility: ${({ small, disabledSidebar }) =>
    disabledSidebar ? 'hidden' : small ? 'visible' : 'hidden'};
  transition: all 0.3s ease-in-out;
  ${({ direction }) => (direction === 'right' ? 'right: 0' : 'left: 0')};
  top: 0;
  margin: 0;
  z-index: 999;
  overflow-y: auto;
  will-change: transform;
  transform: translate3d(
    ${({ small, disabledSidebar, direction }) =>
      disabledSidebar ? '0' : small ? '0' : direction === 'right' ? '100%' : '-100%'},
    0,
    0
  );
  > li {
    &:first-child {
      margin-bottom: ${({ small }) => (small ? '0.5rem' : '0rem')};
    }
  }

  @media (min-width: 992px) {
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    margin: 0;
    height: calc(100% + 60px);
    height: -moz-calc(100%);
    padding-bottom: 60px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 999;
    overflow-y: auto;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    ${({ direction }) =>
      direction === 'right'
        ? 'border-left: 1px solid #ebebeb;'
        : 'border-right: 1px solid #ebebeb'};
    transition: all 0.5s;
    transform: translate3d(0, 0, 0);
    visibility: visible;
    width: ${({ small, disabledSidebar }) => (disabledSidebar ? '0px' : small ? '264px' : '68px')};
  }

  @media (min-width: 1200px) {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    width: ${({ small, disabledSidebar }) => (disabledSidebar ? '0px' : small ? '68px' : '264px')};
  }
  &::-webkit-scrollbar {
    background-color: #ffffff !important;
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-radius: 8px;
    border-left: 5px solid #fff !important;
    border-top: 8px solid #fff !important;
    outline: none;
  }
`;

const SideNavElement = styled.li`
  float: none;
  line-height: 48px;
  list-style-type: none;
  background-color: ${({ active, colorAlpha }) =>
    active ? (colorAlpha ? `${colorAlpha}` : '#f0f9f7') : '#ffffff'};
  transition: background-color 0.1s linear;
  width: 100%;
  &:not(:first-child) {
    &:hover {
      background-color: ${({ colorAlpha }) => (colorAlpha ? `${colorAlpha}` : '#f0f9f7')};
    }
  }
`;

const SideNavHeader = styled.div`
  position: relative;
  padding: 0 24px;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  height: 64px;
  align-items: center;
`;

const SideNavHeaderLogo = styled.div`
  height: 26px;
  width: auto;
  display: block;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: ${fade} 0.5s 0.2s ease both;
  z-index: 4;

  @media (min-width: 600px) {
    display: none;
  }

  @media (min-width: 992px) {
    display: ${({ small, disabledSidebar }) =>
      disabledSidebar ? 'none' : small ? 'block' : 'none'};
  }

  @media (min-width: 1200px) {
    display: ${({ small, disabledSidebar }) =>
      disabledSidebar ? 'none' : small ? 'none' : 'block'};
  }
`;

const SideNavHeaderLogoMobile = styled(SideNavHeaderLogo)`
  display: block;
  @media (min-width: 600px) {
    display: block;
  }

  @media (min-width: 992px) {
    display: ${({ small, disabledSidebar }) =>
      disabledSidebar ? 'block' : small ? 'none' : 'block'};
  }

  @media (min-width: 1200px) {
    display: ${({ small, disabledSidebar }) =>
      disabledSidebar ? 'block' : small ? 'block' : 'none'};
  }
`;

const BrandLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
`;

const SideNavHeaderBurger = styled.div`
  cursor: pointer;
  position: relative;
  &:hover {
    > svg {
      position: relative;
      z-index: 10;
    }
    &:after {
      content: ' ';
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 38px;
      height: 38px;
      background-color: ${({ colorAlpha }) => (colorAlpha ? `${colorAlpha}` : '#f0f9f7')};
    }
  }
`;

const SideNavHeaderBurgerMobile = styled.div`
  display: block;
  cursor: pointer;
  visibility: ${({ disabledSidebar }) => (disabledSidebar ? 'hidden' : 'visibility')};
  > svg {
    > path {
      fill: var(--primaryColor);
    }
  }
  @media (min-width: 992px) {
    display: none;
  }
`;

const SideNavHeaderUserMobile = styled.div`
  display: block;
  cursor: pointer;
  > ul {
    top: 0px !important;
    left: 0px !important;
  }
  @media (min-width: 992px) {
    display: none;
  }
`;

const SideNavUser = styled.div`
  position: relative;
  padding: 1rem 1rem 0;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 992px) {
    margin-bottom: ${({ small }) => (small ? '2rem' : '0.5rem')};
  }
  @media (min-width: 1200px) {
    margin-bottom: ${({ small }) => (small ? '0rem' : '2rem')};
  }
`;

const SideNavUserLogo = styled.div`
  height: auto;
  padding: 0;
`;

const UserLogo = styled.img`
  height: 64px;
  width: 64px;
  border-radius: 50%;
`;

const DefaultUserLogo = styled.div`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  border: 1px solid var(--primaryColor);
  position: relative;
  background-color: #f3f3f3;
  cursor: pointer;
  transition: all .25s ease;
  overflow: hidden;
  &:after {
    filter: blur(0);
  }
  &:not(:hover) {
    > div {
      background-color: ${({ colorAlpha }) => (colorAlpha ? `${colorAlpha}` : '#f0f9f7')};
      content: ' ';
      color: #324243;
      width: 100%;
      height: 50%;
      transform: translateY(0);
      opacity: 1;
      position: absolute;
      z-index: 10;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: ${fadeDown} .25s ease both;
    }
  }
  &:hover {
    > div {
      background-color: ${({ colorAlpha }) => (colorAlpha ? `${colorAlpha}` : '#f0f9f7')};
      content: ' ';
      color: #324243;
      width: 100%;
      height: 50%;
      position: absolute;
      z-index: 10;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
      transform: translateY(10px);
      opacity: 0;
      animation: ${fadeUp} .25s ease both;
      > svg {
        visibility: visible;
        display: block;
      }
    }
    &:after {
      filter: blur(2px);
    }
  }
  &:after {
    content: '${({ value }) => value}';
    color: #324243;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: ${({ small }) => (small ? '1rem' : '1.2875rem')};
    letter-spacing: 0.2px;
    z-index: 8;
  }

  @media (min-width: 992px) {
    height: 48px;
    width: 48px;
    &:after {
      font-size: 1rem;
    }
  }

  @media (min-width: 1200px) {
    height: ${({ small }) => (small ? '48px' : '64px')};
    width: ${({ small }) => (small ? '48px' : '64px')};
    &:after {
      font-size: 1.2875rem;
    }
  }
`;

const SideNavUserName = styled.div`
  margin-top: 11px;
  font-weight: 200;
  font-style: normal;
  color: #324243;
  font-size: 16px;
  line-height: 24px;
  display: block;
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    word-wrap: break-word;
    word-break: break-all;
  }

  @media (min-width: 600px) {
    display: ${({ small }) => (small ? 'block' : 'none')};
  }

  @media (min-width: 1200px) {
    display: ${({ small }) => (small ? 'none' : 'block')};
  }
`;

const SideNavLink = styled.a`
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  transition: all 0.3s ease-out;
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  color: var(--primaryColor);
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  height: 48px;
  line-height: 48px;
  padding: 0 8px 0px 8px;
  z-index: 99;
  &:hover {
    color: var(--primaryColor);
  }
  @media (min-width: 600px) {
    height: 48px;
    line-height: 48px;
  }
  @media (min-width: 992px) {
    height: 42px;
    line-height: 42px;
  }
  @media (min-width: 1200px) {
    height: 48px;
    line-height: 48px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: 50px;
  line-height: 1.6em;
  height: auto;
  padding: 0;
  width: 100%;
  @media (min-width: 992px) {
    padding: 0 0.75rem;
    height: auto;
  }
  @media (min-width: 1400px) {
    width: 1084px;
  }
`;

const Header = styled.nav`
  background-color: #ffffff;
  width: 100%;
  height: 64px;
  box-shadow: none;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
`;

const HeaderMobileItems = styled.div`
  displya: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderItems = styled.div`
  width: 100%;
  position: relative;
  max-width: 100%;
  display: none;
  @media (min-width: 600px) {
    display: none;
  }

  @media (min-width: 992px) {
    display: flex;
    justify-content: flex-end;
    padding: 0 1.5rem;
  }
`;

const HeaderItem = styled.div`
  margin: 0 1.5rem;
  display: flex;
  align-items: center;
  > a {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    outline: none;
    text-decoration: none;
    color: #334047;
    cursor: pointer;
  }
  > span {
    margin-right: 0.85rem;
    margin-left: 0.85rem;
    > i {
      line-height: 21px;
      height: 21px;
    }
  }
  &:hover {
    > a {
      color: var(--primaryColor);
    }
    > span {
      > i {
        > svg {
          > path {
            fill: var(--primaryColor);
          }
        }
      }
    }
  }
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Footer = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 64px;
  box-shadow: none;
  display: flex;
  position: relative;
  padding: 0 1.5rem;
  align-items: center;
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const FooterItem = styled.a`
  font-style: normal;
  font-weight: 200;
  font-size: 1rem;
  line-height: 24px;
  text-transform: capitalize;
  padding: 0 1rem;
  color: #615d5d;
  display: block;

  &:hover {
    color: var(--primaryColor)};
  }
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 600px) {
    flex-direction: row;
    align-items: unset;
  }
`;

const IconButton = styled.button`
  outline: none;
  background: none;
  box-shadow: none;
  border: none;
  position: absolute;
  top: 50%;
  ${({ direction }) => (direction === 'right' ? 'left: 0px' : 'right: 0px')};
  transform: ${({ direction }) =>
    direction === 'right' ? 'translate(18px, -50%)' : 'translate(-18px, -50%)'};
`;

const Title = styled.span`
  display: none;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 300;
  font-sytle: normal;
  max-width: 100%;
  line-height: 1.4rem;
  max-height: 1.4rem;
  color: var(--primaryColor);
  text-align: center;
  padding: 0px 5px;
  @media (min-width: 600px) {
    display: none;
  }
  @media (min-width: 992px) {
    display: ${({ small }) => (small ? 'none' : 'block')};
  }
  @media (min-width: 1200px) {
    display: ${({ small }) => (small ? 'block' : 'none')};
  }
`;

const ContainerLanguage = styled.div`
  > div {
    > div {
      max-width: 68px;
      > select {
        display: none !important;
      }
      > input {
        border-bottom: none !important;
        margin: 0px !important;
        color: #615d5d !important;
        font-weight: 200 !important;
        font-size: 1rem !important;
      }
      > ul {
        top: -55px !important;
        > li {
          font-weight: 300 !important;
          font-size: 1rem !important;
        }
      }
      > svg {
        fill: #615d5d !important;
        transform: rotate(180deg);
      }
    }
  }
`;

const heallyLogo = require('images/heally_teal_logo.png');

const URL_EXCEPTIONS = ['/f/patient_admin/visit'];

const PatientMainLayout = ({ children, userSession, translate, handleChangeLanguage, lang }) => {
  const history = useHistory();
  const [smallSidebar, setSmallSidebar] = useState(false);
  const partnerUser = userSession?.partner_user;
  const { push, location, subdomains } = useRouter();
  const message = useMessage();
  const sidebarRef = useRef();
  const { header, sidebar, footer } = translate;
  const { brandColor } = usePartnerSettingState();

  const styleVector = {
    style: {
      verticalAlign: 'middle',
    },
    width: 16,
    height: 20,
    brandColor: 'var(--primaryColor)',
  };

  const isIlSubdomain = useMemo(() => {
    const subdomain = subdomains.filter(
      el => el?.toLowerCase() === 'heally' || el?.toLowerCase() === 'erx-0002',
    );

    if (subdomain?.length > 0) return true;
    return false;
  }, [subdomains]);

  useOnClickOutside(sidebarRef, () => setSmallSidebar(false));

  const goToPage = link => {
    window.location.href = link;
  };

  const setPatientLogout = () => {
    request('/patient_admin/patient_users/sign_out', {
      method: 'DELETE',
    })
      .then(() => {
        window.location.href = '/patient_admin/patient_users/sign_in';
      })
      .catch(err => {
        message(err);
      });
  };

  const disabledSidebar = URL_EXCEPTIONS.includes(location?.pathname);

  return (
    <>
      <Layout
        small={smallSidebar}
        disabledSidebar={disabledSidebar}
        direction={lang === 'he' ? 'right' : 'left'}
      >
        <div ref={sidebarRef}>
          <SideNav
            small={smallSidebar}
            disabledSidebar={disabledSidebar}
            direction={lang === 'he' ? 'right' : 'left'}
          >
            <SideNavElement>
              <SideNavHeader>
                <SideNavHeaderBurger
                  onClick={() => setSmallSidebar(!smallSidebar)}
                  colorAlpha={addAlpha(`${brandColor}`, 0.1)}
                >
                  <CheeseburgerIcon width={16} height={11} style={{ verticalAlign: 'middle' }} />
                </SideNavHeaderBurger>
                <SideNavHeaderLogo small={smallSidebar} disabledSidebar={disabledSidebar}>
                  <BrandLogo
                    onClick={() => (window.location.href = '/f/patient_admin/home')}
                    src={partnerUser?.partner_branding?.logo || heallyLogo}
                  />
                </SideNavHeaderLogo>
              </SideNavHeader>
              <SideNavUser small={smallSidebar}>
                <SideNavUserLogo>
                  <DefaultUserLogo
                    onClick={e => {
                      e.stopPropagation();
                      window.location.href = '/patient_admin/profile';
                    }}
                    small={smallSidebar}
                    value={`${userSession?.first_name?.charAt(0) ||
                      ''}${userSession?.last_name?.charAt(0) || ''}`}
                    colorAlpha={addAlpha(`${brandColor}`, 0.25)}
                  >
                    <div>
                      <EditIcon
                        width={14}
                        height={14}
                        style={{ verticalAlign: 'middle' }}
                        colorAlpha={addAlpha(`${brandColor}`, 0.4)}
                      />
                    </div>
                  </DefaultUserLogo>
                </SideNavUserLogo>
                <SideNavUserName small={smallSidebar}>
                  {userSession?.first_name || ''} {userSession?.last_name || ''}
                </SideNavUserName>
              </SideNavUser>
            </SideNavElement>

            <SideNavElement
              className="waves-effect waves-light"
              active={location?.pathname === '/f/patient_admin/home'}
              colorAlpha={addAlpha(`${brandColor}`, 0.1)}
            >
              <SideNavLink
                onClick={() => {
                  setSmallSidebar(false);
                  push('/f/patient_admin/home');
                }}
              >
                <span style={{ marginRight: '16px', marginLeft: '16px' }}>
                  <HouseIcon {...styleVector} />
                </span>
                {sidebar?.home}
              </SideNavLink>
              <Title small={smallSidebar}>{sidebar?.home}</Title>
            </SideNavElement>

            <SideNavElement
              className="waves-effect waves-light"
              colorAlpha={addAlpha(`${brandColor}`, 0.1)}
            >
              <SideNavLink
                className="t-patient-sidemenu-profile"
                onClick={() => goToPage('/patient_admin/profile')}
              >
                <span style={{ marginRight: '16px', marginLeft: '16px' }}>
                  <HomeIcon {...styleVector} />
                </span>
                {sidebar?.profile}
              </SideNavLink>
              <Title small={smallSidebar}>{sidebar?.profile}</Title>
            </SideNavElement>

            {!partnerUser?.partner_config?.hide_document_from_patient && (
              <SideNavElement
                className="waves-effect waves-light"
                active={location?.pathname === '/f/patient_admin/signed_documents'}
                colorAlpha={addAlpha(`${brandColor}`, 0.1)}
              >
                <SideNavLink
                  onClick={() => {
                    setSmallSidebar(false);
                    push('/f/patient_admin/signed_documents');
                  }}
                >
                  <span style={{ marginRight: '16px', marginLeft: '16px' }}>
                    <RecordIcon {...styleVector} />
                  </span>
                  {sidebar?.documents}
                </SideNavLink>
                <Title small={smallSidebar}>{sidebar?.documents}</Title>
              </SideNavElement>
            )}

            {userSession?.menu_requested_forms && (
              <SideNavElement
                className="waves-effect waves-light"
                colorAlpha={addAlpha(`${brandColor}`, 0.1)}
              >
                <SideNavLink onClick={() => goToPage('/patient_admin/requested_forms')}>
                  <span style={{ marginRight: '16px', marginLeft: '16px' }}>
                    <RecordIcon {...styleVector} />
                  </span>
                  {sidebar?.requested_forms}
                </SideNavLink>
                <Title small={smallSidebar}>{sidebar?.requested_forms}</Title>
              </SideNavElement>
            )}

            {userSession?.show_messages_tab && (
              <SideNavElement
                className="waves-effect waves-light"
                colorAlpha={addAlpha(`${brandColor}`, 0.1)}
            >
              <SideNavLink
                  className="t-patient-sidemenu-messages"
                  onClick={() => goToPage('/patient_admin/messages')}
              >
                <span style={{marginRight: '16px', marginLeft: '16px'}}>
                  <MessageIcon {...styleVector} />
                </span>
                {sidebar?.messages}
              </SideNavLink>
              <Title small={smallSidebar}>{sidebar?.messages}</Title>
            </SideNavElement>
            )}

            {(partnerUser?.partner_config?.block_seeing_transactions_for_patient ||
              userSession?.show_transactions_tab) && (
              <SideNavElement
                className="waves-effect waves-light"
                active={location?.pathname === '/f/patient_admin/transactions'}
                colorAlpha={addAlpha(`${brandColor}`, 0.1)}
              >
                <SideNavLink
                  onClick={() => {
                    setSmallSidebar(false);
                    push('/f/patient_admin/transactions');
                  }}
                >
                  <span style={{ marginRight: '16px', marginLeft: '16px' }}>
                    <TransactionIcon {...styleVector} />
                  </span>
                  {sidebar?.transaction}
                </SideNavLink>
                <Title small={smallSidebar}>{sidebar?.transaction}</Title>
              </SideNavElement>
            )}

            {/* {partner_user?.partner_config?.allow_scheduling && */}
            {userSession?.schedule_tab && (
              <SideNavElement
                className="waves-effect waves-light"
                active={location?.pathname === '/f/patient_admin/schedule'}
                colorAlpha={addAlpha(`${brandColor}`, 0.1)}
              >
                <SideNavLink
                  onClick={() => {
                    setSmallSidebar(false);
                    push('/f/patient_admin/schedule');
                  }}
                >
                  <span style={{ marginRight: '16px', marginLeft: '16px' }}>
                    <VisitIcon {...styleVector} />
                  </span>
                  {sidebar?.appointments}
                </SideNavLink>
                <Title small={smallSidebar}>{sidebar?.appointments}</Title>
              </SideNavElement>
            )}

            {userSession?.schedule_tab && (
              <SideNavElement
                className="waves-effect waves-light"
                colorAlpha={addAlpha(`${brandColor}`, 0.1)}
              >
                <SideNavLink onClick={() => goToPage('/patient_admin/custom_forms')}>
                  <span style={{ marginRight: '16px', marginLeft: '16px' }}>
                    <RecordIcon {...styleVector} />
                  </span>
                  {sidebar?.custom_forms}
                </SideNavLink>
                <Title small={smallSidebar}>{sidebar?.requested_forms}</Title>
              </SideNavElement>
            )}
          </SideNav>
        </div>
        <Main small={smallSidebar}>
          <Header>
            <SideNavHeaderBurgerMobile
              style={lang === 'he' ? { paddingRight: '24px' } : { paddingLeft: '24px' }}
              onClick={() => setSmallSidebar(!smallSidebar)}
              disabledSidebar={disabledSidebar}
            >
              <CheeseburgerIcon width={16} height={11} style={{ verticalAlign: 'middle' }} />
            </SideNavHeaderBurgerMobile>
            <SideNavHeaderLogoMobile
              style={{
                top: '35%',
              }}
              small={smallSidebar}
              disabledSidebar={disabledSidebar}
            >
              <BrandLogo
                onClick={() => (window.location.href = '/f/patient_admin/home')}
                src={partnerUser?.partner_branding?.logo || heallyLogo}
              />
            </SideNavHeaderLogoMobile>
            <HeaderItems>
              <HeaderItem>
                <span>
                  <i>
                    <LogoutIcon
                      width={20}
                      height={12}
                      style={{ verticalAlign: 'middle', width: 20, height: 15 }}
                    />
                  </i>
                </span>
                <a onClick={() => setPatientLogout()}>{header?.logout}</a>
              </HeaderItem>
            </HeaderItems>
            <SideNavHeaderUserMobile style={{ paddingRight: '24px' }}>
              <Dropdown
                id="Dropdown_menu_28"
                style={{
                  minWidth: '100%',
                }}
                options={{
                  alignment: 'right',
                  autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: true,
                  container: null,
                  coverTrigger: true,
                  hover: false,
                  inDuration: 150,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  outDuration: 250,
                }}
                trigger={
                  <IconButton node="button" direction={lang === 'he' ? 'right' : 'left'}>
                    <UserIcon width={18} height={22} style={{ verticalAlign: 'middle' }} />
                  </IconButton>
                }
              >
                <HeaderItem>
                  <a
                    style={{
                      marginLeft: '2.35rem',
                      height: '56px',
                      lineHeight: '56px',
                      width: '100%',
                    }}
                    onClick={() => goToPage('/patient_admin/help')}
                  >
                    {header?.help}
                  </a>
                </HeaderItem>
                <HeaderItem>
                  <a
                    onClick={() => setPatientLogout()}
                    style={{ width: '100%', display: 'flex', alignItems: 'center' }}
                  >
                    <span>
                      <i>
                        <LogoutIcon
                          width={20}
                          height={12}
                          style={{
                            verticalAlign: 'middle',
                            width: 20,
                            height: 15,
                            marginRight: '1rem',
                          }}
                        />
                      </i>
                    </span>
                    {header?.logout}
                  </a>
                </HeaderItem>
              </Dropdown>
            </SideNavHeaderUserMobile>
          </Header>
          <ContentContainer>
            <Content>{children}</Content>
          </ContentContainer>
          <Footer>
            <FooterSection>
              {process.env.REACT_APP_SENTRY_RELEASE && (
                <FooterItem>Release: {process.env.REACT_APP_SENTRY_RELEASE}</FooterItem>
              )}
            </FooterSection>
            <FooterSection>
              {isIlSubdomain && (
                <ContainerLanguage>
                  <Select
                    id="Select-9"
                    multiple={false}
                    onChange={value => handleChangeLanguage(value)}
                    options={{
                      classes: '',
                      dropdownOptions: {
                        alignment: 'left',
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: true,
                        coverTrigger: true,
                        hover: false,
                        inDuration: 150,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 250,
                      },
                    }}
                    value={lang}
                  >
                    <option value="en">en-US</option>
                    <option value="he">he-IL</option>
                  </Select>
                </ContainerLanguage>
              )}
              <FooterLinks>
                {partnerUser?.partner_config.terms_of_use_present ? (
                  <FooterItem target="_blank" href={'/partner_terms?id=' + partnerUser?.id}>
                    {footer?.terms_of_use}
                  </FooterItem>
                ) : (
                  <FooterItem target="_blank" href="/extraterms">
                    {footer?.terms_of_use}
                  </FooterItem>
                )}
              </FooterLinks>
              <FooterItem>© {footer?.copyright} 2023</FooterItem>
            </FooterSection>
          </Footer>
        </Main>
      </Layout>
    </>
  );
};

export default PatientMainLayout;
