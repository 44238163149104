import React from 'react';

const Warning = () => {
  return (
    <div className="f-modal-icon f-modal-warning scaleWarning">
      <span className="f-modal-body pulseWarningIns"></span>
      <span className="f-modal-dot pulseWarningIns"></span>
    </div>
  );
};

export default Warning;
