import React, { useState, createContext, useContext } from 'react';
import Jsona from 'jsona';

import { request, encodeQueryData, parseError } from 'modules/client';

export const StateContext = createContext(null);

const dataFormatter = new Jsona();
const newBaseUrl = '/api/v2';

export default function SchedulingVisitTypesProvider({ children }) {
  const DEFAULT_STATE = {
    data: [],
    status: 'idle',
    error: null,
  };
  const [visitTypes, setVisitTypes] = useState(DEFAULT_STATE);

  const sortedByOrder = data => data?.sort((a, b) => a?.order - b?.order);

  const groupedByCategrory = (payload, formatter) => {
    const uniqueId = Date.now();
    const otherCategory = {
      type: 'category',
      id: uniqueId?.toString(),
      name: 'Other',
    };

    const listCategories = dataFormatter.deserialize({
      data: payload.included.filter(obj => obj.type === 'category'),
    });

    const listVisitTypes = listCategories.map(value => ({
      ...value,
      listVisitTypes: sortedByOrder(
        formatter?.filter(item => item.categories.find(el => el.id === value.id)),
      ),
    }));

    const listVisitTypesWithOtherCategory = formatter.filter(
      item => item?.categories?.length === 0,
    );

    if (listVisitTypesWithOtherCategory?.length > 0)
      return sortedByOrder([
        ...listVisitTypes,
        { ...otherCategory, listVisitTypes: listVisitTypesWithOtherCategory },
      ]);

    return sortedByOrder(listVisitTypes);
  };

  const fetchVisitTypes = async (payload, joinByCategory = true) => {
    const params = payload ? `?${encodeQueryData(payload)}` : '';

    setVisitTypes(prevState => ({ ...prevState, status: 'running', error: null }));
    return request(`${newBaseUrl}/scheduling/patient/visit_types${params}`)
      .then(response => {
        const hasCategories =
          response?.included?.some(item => item?.type === 'category') && joinByCategory;
        const formatter = dataFormatter.deserialize(response);
        const data = hasCategories ? groupedByCategrory(response, formatter) : formatter;

        setVisitTypes(prevState => ({ ...prevState, data, status: 'ready' }));
      })
      .catch(err => {
        setVisitTypes({
          data: [],
          status: 'error',
          error: parseError(err?.response?.error),
        });
      });
  };

  const fetchVisitType = async (id, params, joinByCategory = true) => {
    if (!id) {
      throw new Error('Visit type id not found');
    }

    const query = params ? `?${encodeQueryData(params)}` : '';

    setVisitTypes(prevState => ({ ...prevState, status: 'running', error: null }));
    return request(`${newBaseUrl}/scheduling/patient/visit_types/${id}${query}`)
      .then(response => {
        const hasCategories =
          response?.included?.some(item => item?.type === 'category') && joinByCategory;
        const formatter = dataFormatter.deserialize(response);
        const coverToArray = [{ ...formatter }];
        const data = hasCategories ? groupedByCategrory(response, coverToArray) : coverToArray;

        setVisitTypes(prevState => ({ ...prevState, data, status: 'ready' }));
      })
      .catch(err => {
        setVisitTypes({
          data: [],
          status: 'error',
          error: parseError(err?.response?.error),
        });
      });
  };

  const contextValue = {
    visitTypes,
    fetchVisitType,
    fetchVisitTypes,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useSchedulingVisitTypes() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useSchedulingVisitTypes must be used with SchedulingVisitTypesProvider');
  }
  return context;
}
