import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';

import { parseError } from 'modules/client';
import { ActionTypes, STATUS } from './PatientIdCheckConstants';

export const patientIdCheckState = {
  common: {
    status: STATUS.IDLE,
    data: {},
    code: 204,
    errors: {
      message: {
        title: '',
        description: '',
      },
      data: {},
    },
  },
  idNumber: {
    status: STATUS.IDLE,
    data: null,
  },
  idPhoto: {
    status: STATUS.IDLE,
    data: null,
  },
  facePhoto: {
    status: STATUS.IDLE,
    data: null,
  },
};

export default {
  patientIdCheck: handleActions(
    {
      [ActionTypes.FETCH_PATIENT_IDENTIFICATION]: (state, _) =>
        immutable(state, {
          common: {
            status: { $set: STATUS.RUNNING },
            data: { $set: {} },
            code: { $set: 204 },
            errors: {
              message: {
                title: { $set: '' },
                description: { $set: '' },
              },
              data: { $set: {} },
            },
          },
        }),

      [ActionTypes.FETCH_PATIENT_IDENTIFICATION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          common: {
            status: { $set: STATUS.READY },
            code: { $set: 200 },
            data: { $set: payload },
          },
          idNumber: {
            data: { $set: payload.id_number },
          },
          idPhoto: {
            data: { $set: payload.id_photo },
          },
          facePhoto: {
            data: { $set: payload.face_photo },
          },
        }),
      [ActionTypes.FETCH_PATIENT_IDENTIFICATION_FAIL]: (state, { payload }) =>
        immutable(state, {
          common: {
            status: { $set: STATUS.ERROR },
            data: { $set: {} },
            code: { $set: payload?.status },
            errors: {
              message: {
                title: { $set: 'Error' },
                description: { $set: parseError(payload?.response?.error) },
              },
            },
          },
        }),

      [ActionTypes.UPDATE_PATIENT_ID_NUMBER]: (state, _) =>
        immutable(state, {
          idNumber: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_PATIENT_ID_NUMBER_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          idNumber: {
            status: { $set: STATUS.READY },
            code: { $set: payload?.code },
            data: { $set: payload?.idNumber },
          },
        }),
      [ActionTypes.UPDATE_PATIENT_ID_NUMBER_FAIL]: (state, _) =>
        immutable(state, {
          idNumber: {
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_PATIENT_FACE_PHOTO]: (state, _) =>
        immutable(state, {
          facePhoto: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_PATIENT_FACE_PHOTO_SUCCESS]: (state, { code, preview }) =>
        immutable(state, {
          facePhoto: {
            status: { $set: STATUS.READY },
            code: { $set: code },
            preview: { $set: preview }
          },
        }),
      [ActionTypes.UPDATE_PATIENT_FACE_PHOTO_FAIL]: (state, _) =>
        immutable(state, {
          facePhoto: {
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_PATIENT_ID_PHOTO]: (state, _) =>
        immutable(state, {
          idPhoto: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_PATIENT_ID_PHOTO_SUCCESS]: (state, { code, preview }) =>
        immutable(state, {
          idPhoto: {
            status: { $set: STATUS.READY },
            code: { $set: code },
            data: { $set: preview }
          },
        }),
      [ActionTypes.UPDATE_PATIENT_ID_PHOTO_FAIL]: (state, _) =>
        immutable(state, {
          idPhoto: {
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.RESET_PREVIOUS_ID_STATE]: (state, { payload: { field } }) =>
        immutable(state, {
          [field]: {
            status: { $set: STATUS.IDLE },
            code: { $set: 204 },
          },
        }),
    },
    patientIdCheckState,
  ),
};
