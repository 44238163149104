import { createActions } from 'redux-actions';
import { ActionTypes } from './PatientIdCheckConstants';

export const {
  fetchPatientIdentification,
  updatePatientIdNumber,
  updatePatientFacePhoto,
  updatePatientIdPhoto,
  resetPreviousIdState,
} = createActions({
  [ActionTypes.FETCH_PATIENT_IDENTIFICATION]: () => ({}),
  [ActionTypes.UPDATE_PATIENT_ID_NUMBER]: value => ({ value }),
  [ActionTypes.UPDATE_PATIENT_FACE_PHOTO]: (value, preview) => ({ value, preview }),
  [ActionTypes.UPDATE_PATIENT_ID_PHOTO]: (value, preview) => ({ value, preview }),
  [ActionTypes.RESET_PREVIOUS_ID_STATE]: field => ({ field }),
});
