export const applyMaskPhoneNumber = (value, direction) => {
  const regexp = direction === 'right' ? /(\d{0,3})(\d{0,7})/ : /(\d{0,3})(\d{0,3})(\d{0,4})/;
  if (value) {
    const USNumber = value
      .toString()
      .replace(/\D/g, '')
      .match(regexp);

    value = !USNumber[2]
      ? USNumber[1]
      : direction === 'right'
      ? `${USNumber[1]}-${USNumber[2]}`
      : `(${USNumber[1]})-${USNumber[2]}${USNumber[3] ? `-${USNumber[3]}` : ''}`;
    return value;
  }
  return '';
};

export const applyMaskDate = value => {
  let v = value.replace(/\D/g, '').slice(0, 8);
  if (v.length >= 5) {
    return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
  } else if (v.length >= 3) {
    return `${v.slice(0, 2)}/${v.slice(2)}`;
  }
  return v;
};

export const validationEmail = email => {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm;
  return reg.test(email);
};

export const validatePhoneNumber = phoneNumber => {
  const patternWithSeparate = /^\(\d{3}\)-\d{3}-\d{4}$/;
  const patternWithoutSeparate = /^\(\d{3}\) \d{3}-\d{4}$/;
  return patternWithSeparate.test(phoneNumber) || patternWithoutSeparate.test(phoneNumber);
};

export const hasNumber = value => {
  return new RegExp(/[0-9]/).test(value);
};

const hasMixed = value => {
  return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
};

const hasSpecial = value => {
  return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
};

export const strengthPassword = count => {
  if (count < 2)
    return {
      color: '#F44336',
      text: 'Worst',
    };

  if (count < 3)
    return {
      color: '#ffeb3b',
      text: 'Bad',
    };

  if (count < 4)
    return {
      color: '#ff9800',
      text: 'Weak',
    };

  if (count < 5)
    return {
      color: '#8bc34a',
      text: 'Good',
    };

  if (count < 6)
    return {
      color: '#4CAF50',
      text: 'Strong',
    };
};

export const strengthIndicator = value => {
  let strengths = 0;

  if (value.length > 5) strengths++;

  if (value.length > 7) strengths++;

  if (hasNumber(value)) strengths++;

  if (hasSpecial(value)) strengths++;

  if (hasMixed(value)) strengths++;

  return strengths;
};

export const onlyLetters = value => {
  return /^[a-zA-Z]*$/g.test(value);
};

export const validationExpDate = (year, month) => {
  const date = new Date();

  const formatYear = Number(year);
  const formatMonth = Number(month);

  const curYear = date.getFullYear();
  const curMonth = date.getMonth() + 1;

  if (formatYear > curYear) return true;

  if (formatYear === curYear && formatMonth >= curMonth) return true;

  return false;
};

export const isEmpty = value => {
  return (
    value === null || // check for null
    value === undefined || // check for undefined
    value === '' || // check for empty string
    (Array.isArray(value) && value.length === 0) || // check for empty array
    (typeof value === 'object' && Object.keys(value).length === 0) // check for empty object
  );
};
