import React, { useState, createContext, useContext } from 'react';
import Jsona from 'jsona';

import { request, encodeQueryData, parseError } from 'modules/client';

export const StateContext = createContext(null);

const dataFormatter = new Jsona();
const newBaseUrl = '/api/v2';

export default function SchedulingDoctorsProvider({ children }) {
  const DEFAULT_STATE = {
    data: [],
    status: 'idle',
    error: null,
  };
  const [doctors, setDoctors] = useState(DEFAULT_STATE);

  const fetchDoctors = async payload => {
    const params = payload ? `?${encodeQueryData(payload)}` : '';

    setDoctors(prevState => ({ ...prevState, status: 'running', error: null }));
    return request(`${newBaseUrl}/scheduling/patient/doctors${params}`)
      .then(response => {
        const data = dataFormatter.deserialize(response);
        const sortedDataByLastName = data?.sort((a, b) =>
          a?.last_name?.localeCompare(b?.last_name),
        );

        setDoctors(prevState => ({
          ...prevState,
          data: sortedDataByLastName,
          status: 'ready',
          error: null,
        }));
      })
      .catch(err => {
        setDoctors({
          data: [],
          status: 'error',
          error: parseError(err?.response?.error),
        });
      });
  };

  const contextValue = {
    doctors,
    fetchDoctors,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useSchedulingDoctors() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useSchedulingDoctors must be used with SchedulingDoctorsProvider');
  }
  return context;
}
