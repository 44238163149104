import React, { createContext, useContext } from 'react';
import Jsona from 'jsona';
import { request } from 'modules/client';

const dataFormatter = new Jsona();

const StateContext = createContext(null);

export default function PatientProfileCouponProvider({ children }) {
  const applyCoupon = async data => {
    return request(`/api/v2/patient/coupons`, { method: 'PUT', payload: data }).then(response => {
      const data = dataFormatter.deserialize(response);
      const meta = response?.meta || {};

      return { ...data, ...meta };
    });
  };

  const applyPromoCoupon = async formData => {
    return request(`/api/v2/patient/promo_coupons`, { method: 'POST', formData }).then(response => {
      const data = dataFormatter.deserialize(response);
      const meta = response?.meta || {};

      return { ...data, ...meta };
    });
  };

  const contextValue = {
    applyPromoCoupon,
    applyCoupon,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function usePatientProfileCoupon() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('usePatientProfileCoupon must be used with PatientProfileCouponProvider');
  }
  return context;
}
