import React, { createContext, useContext, useReducer, useState } from 'react';

import useActiveSinkId from 'hooks/twilio/activeSinkId.hook';

import { settingsReducer, initialSettings } from 'reducers/twilio';
import { compileQuery, request, setPaymentMethod } from 'modules/client';

export const StateContext = createContext(null);

export default function AppStateProvider(props) {
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomType, setRoomType] = useState('peer-to-peer');
  const [dataMessage, setDataMessage] = useState();
  const [fullScreen, setFullScreen] = useState(false);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    dataMessage,
    setDataMessage,
    fullScreen,
  };

  contextValue = {
    ...contextValue,
    getDoctorToken: async (identity, roomName) => {
      return request(`/doctor_admin/visit/token?room_id=${roomName}`);
    },
    getPatientToken: async (identity, roomName) => {
      return request(`/patient_admin/visit/token?room_id=${roomName}`);
    },
    getPatientVisitConfigs: async () => {
      return request(`/patient_admin/visit/configs`);
    },
    joinDoctorQueue: async () => {
      return request('/doctor_admin/visit/queue?media_service=twilio');
    },
    joinPatientQueue: async () => {
      return request('/patient_admin/visit/queue?media_service=twilio');
    },
    createLogPapertrail: async data => {
      return request('/logger', { method: 'POST', payload: { data } });
    },
    updateDoctorSession: async () => {
      return request('/doctor_admin/doctor_session', { method: 'POST', payload: {} });
    },
    updatePatientSession: async () => {
      return request('/patient_admin/patient_session', { method: 'POST', payload: {} });
    },
    acceptIncomingPatient: async patientId => {
      return request(`/doctor_admin/visit/accept`, {
        method: 'POST',
        payload: {
          patient_id: patientId,
        },
      });
    },
    doctorStartVisit: async () => {
      return request('/doctor_admin/visit/start', { method: 'POST', payload: {} });
    },
    patientStartVisit: async () => {
      return request('/patient_admin/visit/start', { method: 'POST', payload: {} });
    },
    getDoctorQuickAnswerList: async () => {
      return request('/doctor_admin/visit/quick_answers');
    },
    createDoctorVisitInterrupt: async (status, reason) => {
      const formData = new FormData();
      formData.append('status', status);
      formData.append('reason', reason);

      return request('/doctor_admin/visit/interrupt', {
        method: 'POST',
        formData,
      });
    },
    createPatientVisitInterrupt: async (status, reason) => {
      const formData = new FormData();
      formData.append('status', status);
      formData.append('reason', reason);

      return request('/patient_admin/visit/interrupt', {
        method: 'POST',
        payload: { status, reason },
      });
    },
    fetchPartnerBanners: async () => {
      return request('/patient_admin/visit/banner');
    },
    addExtraCharge: async amount => {
      const formData = new FormData();
      formData.append('amount', amount);

      return request('/doctor_admin/visit/extra_charge', { method: 'POST', formData });
    },
    removeExtraCharge: async () => {
      const formData = new FormData();

      return request('/doctor_admin/visit/delete_extra_charge', { method: 'DELETE', formData });
    },
    fetchPatientInformation: async patientId => {
      return request(`/doctor_admin/visit/patient_info?patient_id=${patientId}`);
    },
    updateDoctorNotes: async notes => {
      const formData = new FormData();
      formData.append('notes', notes);

      return request(`/doctor_admin/visit/update_tmp_notes`, { method: 'POST', formData });
    },
    createDoctorComplete: async (
      notes,
      validUntil,
      specificsOther,
      limitExemptions,
      status,
      startDate,
      endDate,
      approvalMessage,
      scheduleVisitId,
    ) => {
      const formData = new FormData();

      formData.append('notes', notes);
      formData.append('valid_until', validUntil);
      formData.append('specifics_other', specificsOther);
      formData.append('limit_exemptions', limitExemptions);
      formData.append('status', status);
      formData.append('start_date', startDate);
      formData.append('end_date', endDate);
      formData.append('approval_message', approvalMessage);
      formData.append('schedule_visit_id', scheduleVisitId);

      return request(`/doctor_admin/visit/complete`, { method: 'POST', formData });
    },

    fetchIntakeForms: async () => {
      return request('/doctor_admin/visit/intake_forms');
    },
    checkPaymentVisit: async params => {
      const query = params ? `?${compileQuery(params)}` : '';
      return request(`/patient_admin/visit/check${query}`);
    },
    fetchDoctorNotes: async () => {
      return request('/doctor_admin/visit/tmp_notes');
    },
    sendVisitConnected: async () => {
      return request('/patient_admin/visit/connected', { method: 'POST', payload: {} });
    },
    fetchPatientVisitStatus: async () => {
      return request('/patient_admin/visit/status');
    },
    fetchDoctorVisitStatus: async () => {
      return request('/doctor_admin/visit/status');
    },
    fetchPatientExtraCharge: async () => {
      return request('/patient_admin/visit/extra_charge');
    },
    updateSession: async () => {
      return request('/doctor_admin/visit/update_session', { method: 'POST', payload: {} });
    },
    checkQueueStatus: async () => {
      return request('/doctor_admin/visit/queue_status');
    },
    rejectIncomingVisit: async patientId => {
      const formData = new FormData();

      formData.append('patient_id', patientId);

      return request('/doctor_admin/visit/reject', { method: 'POST', formData });
    },
    fetchVisitTypes: async () => {
      return request('/doctor_admin/visit/patient_visit_types');
    },
    updateVisitType: async visitTypeId => {
      return request('/doctor_admin/visit/update_patient_visit_types', {
        method: 'PUT',
        payload: { visit_type_id: visitTypeId },
      });
    },
    fetchListAppts: async patientId => {
      return request(`/api/v2/scheduling/doctor/visits/patient/${patientId}?status=active`);
    },
  };

  const getToken = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const createLogPapertrail = data => {
    return contextValue
      .createLogPapertrail(data)
      .then(res => {
        return res;
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider
      value={{ ...contextValue, getToken, createLogPapertrail, setFullScreen }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
