import React from 'react';
import { Router, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import Routes from 'router';

import theme from 'modules/theme';
import history from 'modules/history';

import PartnerSettingStateProvider from 'state/partnerSettingState';
import GTagManagerProvider from 'state/gTagManager';
import TrackingPixelsProvider from 'state/trackingPixels';
import LoggingProvider from 'state/logging';

import ToastMD from 'components/Toast/toastMD';
import ScrollToTop from './ScrollToTop';

import { LangProvider } from 'state/language';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
  position: relative;
  @media (min-width: 768px) {
    min-height: 100vh;
  }
`;

const App = () => {
  return (
    <Router history={history}>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <LoggingProvider>
          <PartnerSettingStateProvider>
            <TrackingPixelsProvider>
              <GTagManagerProvider>
                <AppWrapper>
                  <LangProvider>
                    <Route render={props => <Routes {...props} />} />
                    <ToastMD />
                  </LangProvider>
                </AppWrapper>
              </GTagManagerProvider>
            </TrackingPixelsProvider>
          </PartnerSettingStateProvider>
        </LoggingProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
