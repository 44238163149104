import React from 'react';

export default function ChevronCircle({ style, fill }) {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill={fill || '#000000'}
    >
      <rect fill="none" height="24" width="24" />
      <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M12,15.5L7.5,11l1.42-1.41L12,12.67 l3.08-3.08L16.5,11L12,15.5z" />
    </svg>
  );
}
