import React, { createContext, useContext, useState } from 'react';
import Jsona from 'jsona';
import { request, compileQuery } from 'modules/client';

export const StateContext = createContext(null);

const dataFormatter = new Jsona();

export default function PaymentProvider({ children }) {
  const DEFAULT_STATE = {
    data: [],
    status: 'idle',
    error: null,
  };

  const [paymentErrors, setPaymentErrors] = useState(DEFAULT_STATE);

  const retryPaymentErrors = () => {
    setPaymentErrors({ data: [], status: 'running', error: null });
    request(`/api/v2/scheduling/patient/manual_visits/retry_payment_errors`, {
      method: 'POST',
      payload: {},
    })
      .then(response => {
        const data = dataFormatter.deserialize(response);
        setPaymentErrors({ data, status: 'ready', error: null });
      })
      .catch(error => setPaymentErrors({ status: 'error', error }));
  };

  const fetchTransactionsList = async (id, params) => {
    return request(`/api/v2/payments/patient/transactions?patient_id=${id}${params}`).then(res => {
      return {
        data: dataFormatter.deserialize(res),
        meta: res?.meta,
      };
    });
  };

  const fetchTransaction = async id => {
    return request(`/api/v2/payments/patient/transactions/${id}`).then(res =>
      dataFormatter.deserialize(res),
    );
  };

  const setCancelOperationTransaction = (vendor, id) => {
    if (!vendor || !id) {
      throw new Error('not found params');
    }

    return request(`/api/v2/payments/patient/callback/${vendor}/cancel/${id}`);
  };

  const setCompleteOperationTransaction = (vendor, id) => {
    if (!vendor || !id) {
      throw new Error('not found params');
    }

    return request(`/api/v2/payments/patient/callback/${vendor}/complete/${id}`);
  };

  const checkPaymentTransaction = params => {
    const query = params ? `?${compileQuery(params)}` : '';
    return request(`/api/v2/payments/patient/transactions/check${query}`);
  };

  const contextValue = {
    retryPaymentErrors,
    paymentErrors,
    fetchTransactionsList,
    fetchTransaction,
    setCancelOperationTransaction,
    setCompleteOperationTransaction,
    checkPaymentTransaction,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function usePayment() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('usePayment must be used with PaymentProvider');
  }
  return context;
}
