import React from 'react';

const RippleLoader = props => {
  const { color, size, duration } = props;
  const innerDiv = [];
  for (let i = 0; i < 2; i++) {
    innerDiv.push(
      <div
        key={i}
        style={{
          borderColor: color || 'black',
          animationDuration: duration || '1.2s',
        }}
      ></div>,
    );
  }
  const style = size ? {
    width: size + 'px',
    height: size + 'px'
  } : {}
  return <div style={style} className="ripple-loader">{innerDiv}</div>;
};

export default RippleLoader;
