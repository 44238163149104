import React, { createContext, useContext } from 'react';
import { request } from 'modules/client';

import { useRouter } from 'hooks/router.hook';

export const StateContext = createContext(null);

export default function LoggingProvider({ children }) {
  const { isLocalStage } = useRouter();

  const sendLog = async payload => {
    if (isLocalStage) {
      console.log(`Send Log:`, payload);
      return;
    }

    return request(`/logger`, { method: 'POST', payload });
  };

  const contextValue = {
    sendLog,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useLogging() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useLogging must be used with LoggingProvider');
  }
  return context;
}
