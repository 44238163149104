import { useCallback } from 'react';

export const useMessage = () => {
  return useCallback((text, displayLength = 7000, completeCallback, dismissAll) => {
    if (dismissAll) {
      window.M.Toast.dismissAll();
    }

    if (window.M && text) {
      window.M.toast({
        html: `<div style="max-width: 650px">${text}</div>`,
        displayLength,
        completeCallback,
      });
    }
  }, []);
};
