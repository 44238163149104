const pathEnum = [
  {
    path: 'vshop-signIN',
    name: 'vshop_signin',
  },
  {
    path: 'vshop-schedule',
    name: 'vshop_schedule',
  },
];
export const defaultTitle = (location, translate) => {
  const pathType = location?.split('/')?.filter(Boolean)[1];
  const titleType = pathEnum.filter(path => path.path === pathType);
  const pathName = titleType[0]?.name
    ? titleType[0]?.name
    : location?.split('/')?.filter(Boolean)[2];
  return (
    translate?.[pathName]?.title ||
    pathName
      ?.split('_')
      ?.map(n => `${n[0].toUpperCase()}${n.slice(1)}`)
      ?.join(' ')
  );
};
