import React from 'react';

export default function Message({ width, height, style }) {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 18 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2 1H1.8c-.442 0-.8.38-.8.848v11.304c0 .468.358.848.8.848h14.4c.442 0 .8-.38.8-.848V1.848C17 1.38 16.642 1 16.2 1zm.267 12.152c0 .156-.12.283-.267.283H1.8c-.147 0-.267-.127-.267-.283V1.848c0-.156.12-.283.267-.283h14.4c.147 0 .267.127.267.283v11.304z"
        fill="var(--primaryColor)"
        stroke="var(--primaryColor)"
        strokeWidth=".2"
      />
      <path
        d="M15.69 2.131a.257.257 0 00-.195.066L9.343 7.673a.51.51 0 01-.686 0L2.505 2.197a.255.255 0 00-.263-.05.279.279 0 00-.171.217c-.017.1.018.2.09.265l6.153 5.477a1.02 1.02 0 001.372 0l6.152-5.476a.294.294 0 00.033-.398.262.262 0 00-.181-.1zM6.129 8.067a.258.258 0 00-.257.088l-3.734 4.24a.295.295 0 00-.064.277.274.274 0 00.197.195.258.258 0 00.257-.087l3.734-4.24a.295.295 0 00.064-.277.274.274 0 00-.197-.196zM12.128 8.155a.258.258 0 00-.257-.088.274.274 0 00-.197.196.294.294 0 00.064.278l3.734 4.239c.101.11.267.114.373.009a.295.295 0 00.017-.395l-3.734-4.239z"
        fill="var(--primaryColor)"
        stroke="var(--primaryColor)"
        strokeWidth=".2"
      />
    </svg>
  );
}
