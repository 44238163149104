import React, { Children, cloneElement, useRef, useEffect, useState } from 'react';
import cx from 'classnames';
import idgen from 'utils/idgen';
import constants from 'utils/constants';

const Select = ({
  id = `Select-${idgen()}`,
  s,
  m,
  l,
  xl,
  noLayout,
  browserDefault,
  icon,
  label,
  className,
  success,
  error,
  validate,
  children,
  multiple,
  options,
  value,
  onChange,
  firstEmpty,
  firstEmplyLabel = '',
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const _selectRef = useRef(null);
  const _formSelectInstance = useRef(null);

  useEffect(() => {
    _formSelectInstance.current = window.M.FormSelect.init(_selectRef.current, options);

    return () => {
      _formSelectInstance.current && _formSelectInstance.current.destroy();
    };
  }, [options, children]);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const sizes = { s, m, l, xl };
  let responsiveClasses = {};

  if (!noLayout) {
    responsiveClasses = { col: true };
    constants.SIZES.forEach(size => {
      responsiveClasses[size + sizes[size]] = sizes[size];
    });
  }

  const renderOption = child => cloneElement(child, { key: child.props.value });

  const handleChange = e => {
    let value = e.target.value;

    if (multiple) {
      value = _formSelectInstance.current.getSelectedValues();
    }

    onChange && onChange(value);

    setSelectedValue(value);
  };

  const preventPropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <div className={cx('input-field', responsiveClasses)}>
      {Boolean(icon) && cloneElement(icon, { className: 'prefix' })}
      <select
        {...props}
        type="select"
        id={id}
        value={selectedValue}
        ref={_selectRef}
        onChange={handleChange}
        onClick={preventPropagation}
        onBlur={event => event.preventDefault()}
        className={cx(className, {
          validate,
          multiple,
          ['browser-default']: browserDefault,
        })}
        multiple={multiple}
      >
        {firstEmpty ? <option value="">{firstEmplyLabel}</option> : null}
        {Children.map(children, renderOption)}
      </select>
      {Boolean(label) && (
        <label data-success={success} data-error={error} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

Select.defaultProps = {
  multiple: false,
  options: {
    classes: '',
    dropdownOptions: {
      alignment: 'left',
      autoTrigger: true,
      constrainWidth: true,
      coverTrigger: true,
      closeOnClick: true,
      hover: false,
      inDuration: 150,
      outDuration: 250,
      onOpenStart: null,
      onOpenEnd: null,
      onCloseStart: null,
      onCloseEnd: null,
    },
  },
};

export default Select;
