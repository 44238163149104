import React, { createContext, useContext } from 'react';

import useLocalStorage from 'hooks/localStorage.hook';
import { dataFormatter } from 'utils/connectPartnerConfigs';
import { request, compileQuery, getRequestError } from 'modules/client';

export const StateContext = createContext(null);

export default function VShopScheduleStateProvider({ children }) {
  const [_, setToken] = useLocalStorage('token');

  const createPatientFromWidget = async patientUser => {
    return fetch('/api/v2/registration/widget/patients', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ patient_user: patientUser }),
    }).then(async response => {
      if (response.status > 399) {
        return getRequestError(response);
      } else {
        const token = response?.headers?.get('authorization');
        setToken(token);

        // Need delete Bearer because browser address string has symbols for spaces
        const cleanToken = token.replace(/^Bearer\s*/, '');

        const data = await response.json();

        return { ...dataFormatter.deserialize(data), token: cleanToken };
      }
    });
  };

  const checkOnExistPatient = async payload => {
    return fetch(`/api/v2/registration/widget/patients/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...payload }),
    }).then(async response => {
      if (response.status > 399) {
        return getRequestError(response);
      } else {
        const token = response?.headers?.get('authorization');
        setToken(token);

        // Need delete Bearer because browser address string has symbols for spaces
        const cleanToken = token.replace(/^Bearer\s*/, '');

        const data = await response.json();

        return { ...dataFormatter.deserialize(data), token: cleanToken };
      }
    });
  };

  const fetchConfirmationMessage = async query => {
    const params = query ? `?${compileQuery(query)}` : '';

    return request(`/api/v2/scheduling/patient/visits/widget/confirmation_message${params}`).then(
      response => response,
    );
  };

  const contextValue = {
    createPatientFromWidget,
    checkOnExistPatient,
    fetchConfirmationMessage,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useVShopScheduleState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useVShopScheduleState must be use within PartnerConfigsStateProvider');
  }

  return context;
}
