import React from 'react';

export default function UploadImage({ width, height, style }) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      fill="none"
      viewBox="0 0 49 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.393 17.369a3.353 3.353 0 100-6.706 3.353 3.353 0 000 6.706z" fill="var(--primaryColor)" />
      <path
        d="M45.913 26.356a10.324 10.324 0 00-5.774-2.748V6.566c0-1.816-.745-3.446-1.91-4.657C37.02.7 35.39 0 33.574 0H6.566C4.75 0 3.12.745 1.909 1.91.7 3.12 0 4.75 0 6.565V34.364c0 1.817.745 3.447 1.91 4.657 1.21 1.211 2.84 1.91 4.656 1.91h26.122c1.723 1.397 3.865 2.281 6.24 2.281a9.851 9.851 0 006.985-2.887A9.85 9.85 0 0048.8 33.34a9.851 9.851 0 00-2.887-6.984zM2.468 6.566c0-1.118.466-2.142 1.21-2.84a4.086 4.086 0 012.888-1.212h27.007c1.118 0 2.142.466 2.887 1.211a4.086 4.086 0 011.211 2.887V21.14l-6.892-6.891a1.25 1.25 0 00-1.77 0l-10.383 10.43-7.031-7.077a1.25 1.25 0 00-1.77 0l-7.357 7.45V6.566zm4.051 31.99v-.093a4.086 4.086 0 01-2.887-1.211c-.698-.745-1.164-1.77-1.164-2.887V28.59l8.242-8.289 7.031 7.032c.466.465 1.258.465 1.77 0l10.384-10.431 6.798 6.845-.419.14a4.32 4.32 0 00-.605.186c-.187.047-.373.14-.56.186-.139.047-.232.093-.372.187-.186.093-.326.14-.465.232l-.699.42c-.14.093-.233.14-.372.232-.093.047-.14.093-.233.14-.42.28-.792.605-1.118.978a9.85 9.85 0 00-2.887 6.985c0 .698.093 1.35.233 2.048.047.187.093.326.14.513.14.465.28.93.466 1.397v.046c.186.373.372.792.605 1.118H6.519zm37.578 0c-1.35 1.35-3.166 2.142-5.215 2.142a7.345 7.345 0 01-5.076-2.05c-.186-.185-.372-.418-.559-.605-.14-.14-.28-.325-.419-.465-.186-.233-.326-.512-.465-.792-.093-.186-.187-.326-.28-.512a3.93 3.93 0 01-.233-.792c-.046-.186-.14-.419-.186-.605a7.71 7.71 0 01-.14-1.49c0-2.049.839-3.865 2.142-5.215 1.304-1.35 3.167-2.142 5.216-2.142 2.049 0 3.865.838 5.215 2.142 1.35 1.35 2.142 3.166 2.142 5.215 0 2.002-.838 3.818-2.142 5.169z"
        fill="var(--primaryColor)"
      />
      <path
        d="M39.767 28.358a1.628 1.628 0 00-.42-.28 1.359 1.359 0 00-.419-.093h-.093c-.14 0-.28.047-.419.094a1.1 1.1 0 00-.419.279l-2.887 2.887c-.466.466-.466 1.257 0 1.77.466.465 1.257.465 1.77 0l.744-.746v5.123c0 .698.56 1.257 1.258 1.257s1.257-.559 1.257-1.257v-5.123l.745.745c.466.466 1.257.466 1.77 0 .465-.465.465-1.257 0-1.769l-2.888-2.887z"
        fill="var(--primaryColor)"
      />
    </svg>
  );
}
