import keyMirror from 'fbjs/lib/keyMirror';

export const ActionTypes = keyMirror({
  SHOW_MESSAGE: undefined,
  SHOW_ALERT: undefined,
  HIDE_ALERT: undefined,
  INITIAL_SETUP: undefined,
  INITIAL_SETUP_SUCCESS: undefined,
  INITIAL_SETUP_FAIL: undefined,
  APPLY_DISCOUNT_CODE: undefined,
  APPLY_DISCOUNT_CODE_SUCCESS: undefined,
  APPLY_DISCOUNT_CODE_FAIL: undefined,
  FETCH_CREDIT_CARD_TOKEN: undefined,
  FETCH_CREDIT_CARD_TOKEN_SUCCESS: undefined,
  FETCH_CREDIT_CARD_TOKEN_FAIL: undefined,
  CREATE_CREDIT_CARD: undefined,
  CREATE_CREDIT_CARD_SUCCESS: undefined,
  CREATE_CREDIT_CARD_FAIL: undefined,
  FETCH_CREDIT_CARD_DETAILS: undefined,
  FETCH_CREDIT_CARD_DETAILS_SUCCESS: undefined,
  FETCH_CREDIT_CARD_DETAILS_FAIL: undefined,
  DESTROY_CREDIT_CARD: undefined,
  DESTROY_CREDIT_CARD_SUCCESS: undefined,
  DESTROY_CREDIT_CARD_FAIL: undefined,
  SET_PAYMENT_METHOD: undefined,
  DELETE_LAST_COUPON: undefined,
  CREATE_PURCHASE: undefined,
  CREATE_PURCHASE_SUCCESS: undefined,
  CREATE_PURCHASE_FAIL: undefined,
  CREATE_CREDIT_CARD_AND_PURCHASE: undefined,
  CREATE_CREDIT_CARD_AND_PURCHASE_FAIL: undefined,
  DESTROY_CREDIT_CARD_AND_FETCH_NEW_TOKEN: undefined,
  DESTROY_CREDIT_CARD_AND_FETCH_NEW_TOKEN_FAIL: undefined,
  ADD_PATIENT_SELECT_ADDRESS: undefined,
  FETCH_EVALUATION_COUNTRY_AND_STATES: undefined,
  FETCH_EVALUATION_COUNTRY_AND_STATES_SUCCESS: undefined,
  FETCH_EVALUATION_COUNTRY_AND_STATES_FAIL: undefined,
  UPDATE_PATIENT_PERSONAL: undefined,
  UPDATE_PATIENT_PERSONAL_SUCCESS: undefined,
  UPDATE_PATIENT_PERSONAL_FAIL: undefined,
  RESET_ORDER_STATE: undefined,
});

export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};
