import { useAppState } from 'state';

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export default function useConnectionOptions() {
  const { settings } = useAppState();

  const connectionOptions = {
    bandwidthProfile: {
      video: {
        mode: settings.bandwidthProfileMode,
        dominantSpeakerPriority: settings.dominantSpeakerPriority,
        trackSwitchOffMode: settings.trackSwitchOffMode,
        contentPreferenceMode: settings.contentPreferenceMode,
        clientTrackSwitchOffControl: settings.clientTrackSwitchOffControl,
      },
    },
    dominantSpeaker: true,
    networkQuality: { local: 1, remote: 2 },
    maxAudioBitrate: Number(settings.maxAudioBitrate),
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: false }],
    region: 'us2',
  };

  if (isMobile && connectionOptions?.bandwidthProfile?.video) {
    connectionOptions.bandwidthProfile.video['maxSubscriptionBitrate'] = 2500000;
  }

  return connectionOptions;
}
