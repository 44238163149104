import { IGNORE_401_ROUTES } from 'constants/auth';
import { getItemFromLocalStorage, getUrlParamValue } from './common';

const PAGES_WITH_JWT_TOKEN = [
  '/f/patient_admin/reset_password',
  '/f/patient_admin/intermediate/credit_card',
  '/f/patient_admin/intermediate/check_in',
  '/f/patient_admin/intermediate/patient_id',
  '/f/patient_admin/intermediate/document',
  '/f/vshop-schedule',
  '/f/vshop-schedule/confirmation',
  '/f/vshop-book_appt',
  '/f/patient_admin/intermediate/manage_appt',
];

export class ServerError extends Error {
  constructor(message) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerError);
    }

    this.name = 'ServerError';

    return this;
  }
}

export const parseError = error => {
  return error || 'Something went wrong';
};

export const parseErrorsArray = errorsMsgs => {
  const parsedErrors = []
  errorsMsgs.forEach(error => {
    if (typeof error === 'string') {
      parsedErrors.push(error);
    } else {
      Object.keys(error).forEach(key => {
        error[key]?.forEach(text => {
          parsedErrors.push(text);
        })
      })
    }
  })

  return parsedErrors
};

export const compileQuery = params =>
  Object.keys(params)
    .map(key => {
      if (typeof params[key] === 'object') {
        return Object.keys(params[key])
          .map(ikey => `${key}[${ikey}]=${params[key][ikey]}`)
          .join('&');
      }
      return `${key}=${params[key]}`;
    })
    .join('&');

export const encodeQueryData = data => {
  const ret = [];
  for (let d in data) {
    if (typeof data[d] === 'object' || typeof data[d] === 'array') {
      for (let arrD in data[d]) {
        ret.push(`${encodeURIComponent(d)}[]=${encodeURIComponent(data[d][arrD])}`);
      }
    } else if (typeof data[d] === 'null' || typeof data[d] === 'undefined') {
      ret.push(encodeURIComponent(d));
    } else {
      ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
    }
  }
  return ret.join('&');
};

export const getRequestError = async response => {
  const error = {};
  error.status = response.status;
  error.response = await response.json();

  throw error;
};

export const request = (url, options) => {
  const tokenFromUrl = getUrlParamValue('token');
  const token = tokenFromUrl ? `Bearer ${tokenFromUrl}` : getItemFromLocalStorage('token');
  const hasJWTToken = PAGES_WITH_JWT_TOKEN?.includes(window.location.pathname?.toLowerCase());

  const config = {
    method: 'GET',
    ...options,
  };

  const errors = [];

  if (!url) {
    errors.push('url');
  }

  if (
    !config.payload &&
    !config.formData &&
    (config.method !== 'GET' && config.method !== 'DELETE')
  ) {
    errors('payload or formData');
  }

  if (errors.length) {
    throw new Error(`Error! You mus pass \`${errors.join('`, `')}\``);
  }

  const headers = {
    Accept: config.formData ? '*/*' : 'application/json',
    'Content-Type': 'application/json',
    ...config.headers,
  };

  if (token && hasJWTToken) {
    headers['Authorization'] = token;
  }

  if (config.formData) {
    delete headers['Content-Type'];
  }

  const params = {
    headers,
    method: config.method,
  };

  if (params.method !== 'GET') {
    params.body = config.formData || JSON.stringify(config.payload);
  }

  return fetch(url, params).then(async response => {
    const contentType = response.headers.get('content-type');

    if (response.status === 204) {
      return true;
    }

    if (response.status > 399) {
      const error = new ServerError(response.statusText);
      error.status = response.status;

      if (response.status === 401 && !IGNORE_401_ROUTES.some(r => window.location.pathname.includes(r))) {
        window.location.href = '/patient_admin/patient_users/sign_in';
      }

      if (contentType && contentType.includes('application/json')) {
        error.response = await response.json();
      } else {
        error.response = await response.text();
      }

      throw error;
    } else {
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      }

      return response.text();
    }
  });
};

export const setPaymentMethod = (visitType, allowedPaymentMethods, skipOnlinePayment) => {
  if (visitType === 'online' && skipOnlinePayment) {
    return 'cash';
  } else if (visitType === 'online' && !skipOnlinePayment) {
    return 'card';
  } else if (
    visitType === 'offline' &&
    allowedPaymentMethods.length === 1 &&
    allowedPaymentMethods.indexOf('cash') >= 0
  ) {
    return 'cash';
  } else if (
    visitType === 'offline' &&
    allowedPaymentMethods.length === 1 &&
    allowedPaymentMethods.indexOf('card') >= 0
  ) {
    return 'card';
  } else {
    return 'card';
  }
};

export const transformErrorsFromApi = data => {
  return data?.response?.errors;
};
