import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/patient';

export const {
  fetchPartnerVisitTypes,
  fetchFormsVisitTypes,
  fetchVisitTypes,
  fetchFreePositions,
  fetchFreeDays,
  fetchFreeDoctors,
  fetchScheduleVisits,
  createScheduleVisit,
  updateScheduleVisit,
  cancelScheduleVisit,
  setOptionsForDirection,
  fetchPatientSession,
  createPatientFromPartnerWithPreset,
  fetchPatientVisits,
  updatePatientSession,
  resetStateFreeDays,
  resetStateVisit,
  fetchProviders,
  setProviderOnScheduling,
  iframeFetchPartnerVisitTypes,
  iframeFetchFreeDays,
  iframeFetchFreeDoctors,
  fetchPartnerVisitType,
  iframeFetchPartnerVisitType,
} = createActions({
  [ActionTypes.FETCH_PARTNER_VISIT_TYPES]: params => ({ params }),
  [ActionTypes.FETCH_FORMS_VISIT_TYPES]: () => ({}),
  [ActionTypes.FETCH_VISIT_TYPES]: () => ({}),
  [ActionTypes.FETCH_FREE_POSITIONS]: (address_id, visit_type_id, day) => ({
    address_id,
    visit_type_id,
    day,
  }),
  [ActionTypes.FETCH_FREE_DAYS]: params => params,
  [ActionTypes.FETCH_FREE_DOCTORS]: params => params,
  [ActionTypes.FETCH_SCHEDULE_VISITS]: params => ({ params }),
  [ActionTypes.CREATE_SCHEDULE_VISIT]: schedule_visit => ({ schedule_visit }),
  [ActionTypes.UPDATE_SCHEDULE_VISIT]: (schedule_visit, id) => ({ schedule_visit, id }),
  [ActionTypes.CANCEL_SCHEDULE_VISIT]: id => ({ id }),
  [ActionTypes.SET_OPTIONS_FOR_DIRECTION]: options => ({ options }),
  [ActionTypes.FETCH_PATIENT_SESSION]: query => ({ query }),
  [ActionTypes.CREATE_PATIENT_FROM_PARTNER_WITH_PRESET]: (preset, partner) => ({
    preset,
    partner,
  }),
  [ActionTypes.FETCH_PATIENT_VISITS]: query => ({ query }),
  [ActionTypes.UPDATE_PATIENT_SESSION]: values => ({ values }),
  [ActionTypes.RESET_STATE_FREE_DAYS]: () => ({}),
  [ActionTypes.RESET_STATE_VISIT]: () => ({}),
  [ActionTypes.FETCH_PROVIDERS]: params => ({ params }),
  [ActionTypes.SET_PROVIDER_ON_SCHEDULING]: data => ({ data }),
  [ActionTypes.IFRAME_FETCH_PARTNER_VISIT_TYPES]: params => ({ params }),
  [ActionTypes.IFRAME_FETCH_FREE_DAYS]: params => params,
  [ActionTypes.IFRAME_FETCH_FREE_DOCTORS]: params => params,
  [ActionTypes.FETCH_PARTNER_VISIT_TYPE]: (id, params) => ({ id, params }),
  [ActionTypes.IFRAME_FETCH_PARTNER_VISIT_TYPE]: (id, params) => ({ id, params }),
});
