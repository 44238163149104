import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/lib/constants';

import { ActionTypes, STATUS } from '../constants';
import { parseError } from 'modules/client';

export const baseState = {
  latest_addresses: {
    data: [],
    status: STATUS.IDLE,
  },
  alerts: [],
  evaluationCountryAndStates: {
    data: {},
    status: STATUS.IDLE,
    code: 204,
    errors: {
      message: {
        title: '',
        description: ''
      },
      data: {},
    },
  }
};

export default {
  base: handleActions(
    {
      [ActionTypes.SHOW_MESSAGE]: (state, { payload }) =>
        immutable(state, {
          message: { $set: payload },
        }),

      [ActionTypes.SHOW_ALERT]: (state, { payload }) =>
        immutable(state, {
          alerts: { $push: [payload] }
        }),

      [ActionTypes.HIDE_ALERT]: (state, { payload: { id } }) => {
        const alerts = state.alerts.filter(alert => alert.id !== id);

        return immutable(state, {
          alerts: { $set: alerts },
        });
      },

      [ActionTypes.ADD_PATIENT_SELECT_ADDRESS]: (state, { payload }) => {
        if (state.latest_addresses) {
          const latestAddresses = state.latest_addresses.data.filter((address) => address.attributes.name !== payload.address.attributes.name);

          if (state.latest_addresses.data.length > 0 || latestAddresses.length === 0) {
            if (latestAddresses.length === 5) {
              latestAddresses[0] = payload.address;
            } else if (latestAddresses.length === state.latest_addresses.data.length) {
              latestAddresses.unshift(payload.address);
            } else if (latestAddresses.length === 0) {
              latestAddresses.push(payload.address)
            }


            return immutable(state, {
              latest_addresses: {
                data: { $set: latestAddresses }
              }
            })
          }
          return immutable(state, {
            latest_addresses: {
              data: { $push: [payload.address] }
            }
          })
        } else {
          return immutable(state, {
            latest_addresses: { $set: { data: [payload.address], status: STATUS.IDLE } }
          })
        }
      },

      [ActionTypes.FETCH_EVALUATION_COUNTRY_AND_STATES]: (state, _) => {
        if (!state.evaluationCountryAndStates) {
          return immutable(state, {
            evaluationCountryAndStates: {
              $set: {
                status: { $set: STATUS.RUNNING },
                data: { $set: {} },
                code: { $set: 204 },
                errors: {
                  message: {
                    title: { $set: '' },
                    description: { $set: '' }
                  },
                  data: { $set: {} },
                }
              }
            }
          })
        }
        return immutable(state, {
          evaluationCountryAndStates: {
            status: { $set: STATUS.RUNNING },
            data: { $set: {} },
            code: { $set: 204 },
            errors: {
              message: {
                title: { $set: '' },
                description: { $set: '' }
              },
              data: { $set: {} },
            }
          }
        })
      },

      [ActionTypes.FETCH_EVALUATION_COUNTRY_AND_STATES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          evaluationCountryAndStates: {
            status: { $set: STATUS.READY },
            data: { $set: payload.data },
            code: { $set: 200 }
          }
        }),

      [ActionTypes.FETCH_EVALUATION_COUNTRY_AND_STATES_FAIL]: (state, { payload }) =>
        immutable(state, {
          evaluationCountryAndStates: {
            status: { $set: STATUS.ERROR },
            data: { $set: {} },
            code: { $set: payload?.status },
            errors: {
              message: {
                title: { $set: payload?.code === 422 || payload?.status === 422 ? '' : 'Error' },
                description: { $set: payload?.code === 422 || payload?.status === 422 ? '' : parseError(payload?.response?.error) }
              },
              data: { $set: payload?.code === 422 || payload?.status === 422 ? payload?.response?.errors : {} }
            }
          }
        }),
    },
    baseState,
  ),
};
