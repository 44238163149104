import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';

import PartnerConfigsStateProvider from 'state/partnerConfigs';

import RippleLoader from 'components/Preloader/RippleLoader';
import Message from 'components/Message';

import { useRouter } from 'hooks/router.hook';
import LangContext from 'state/language';

import MainRoutes from './Patient';
import PatientAuthRouter from './Patient/Intermediate';
import PatientVShopRouter from './Patient/vShop';

const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.45);
  transition: all 0.3s ease-in-out;
  z-index: 82;
`;

const Routes = () => {
  const router = useRouter();
  const { loadingLang, fetchLanguage, languageError } = useContext(LangContext);

  useEffect(() => {
    fetchLanguage();
  }, []);

  if (languageError) {
    const { status } = languageError;

    if (status === 401) {
      const subheader = (
        <span>
          Your session has ended. Try{' '}
          <a onClick={() => window.location.reload()}>reloading the page </a> or return to the{' '}
          <a onClick={() => (window.location.href = '/patient_admin/patient_users/sign_in')}>
            login page
          </a>
          .
        </span>
      );
      return <Message type="warning" header="warning" subheader={subheader} />;
    }

    return (
      <Message
        type="error"
        header="we are sorry, the page has unexpected error!"
        subheader="Please contact support via live chat below."
      />
    );
  }

  if (loadingLang) {
    return (
      <CenterContainer>
        <RippleLoader color="var(--primaryColor)" />
      </CenterContainer>
    );
  }

  return (
    <Switch>
      {router.isPatient && (
        <Route
          render={props => (
            <MainRoutes {...props} subdomain={router.subdomain} query={router.query} />
          )}
        />
      )}
      {router.isVshop && (
        <PartnerConfigsStateProvider>
          <Route
            render={props => (
              <PatientVShopRouter {...props} subdomain={router.subdomain} query={router.query} />
            )}
          />
          <PatientAuthRouter />
        </PartnerConfigsStateProvider>
      )}
    </Switch>
  );
};

export default Routes;
