import React, { Component, Fragment, Children } from 'react';
import cx from 'classnames';

class Navbar extends Component {
  componentDidMount() {
    const { options } = this.props;

    if (typeof window.M !== 'undefined') {
      this.instance = window.M.Sidenav.init(this._sidenav, options);
    }
  }

  componentWillUnmount() {
    if (this.instance) {
      this.instance.destroy();
    }
  }

  render() {
    const {
      children,
      brand,
      className,
      extendWith,
      fixed,
      alignLinks,
      centerLogo,
      search,
      menuIcon,
      sidenav,
      profileItems,
      centeredChildren,
      styleProfileItems,
      arrowBack,
      arrowNext,
      customMenuItem,
      language,
    } = this.props;

    const brandClasses = cx({
      'brand-logo': true,
      center: centerLogo,
    });

    const navCSS = cx({ 'nav-extended': extendWith }, className);

    const navMobileCSS = cx('hide-on-med-and-down', [alignLinks]);

    const centeredEl = centeredChildren && cx('center-children-el');

    const links = Children.map(children, (link, index) => <li key={index}>{link}</li>);

    const profileLinks = Children.map(profileItems, (link, index) => <li key={index}>{link}</li>);

    const sidenavLinks = sidenav
      ? sidenav
      : Children.map(children, (link, index) => {
          const clonedLink =
            link && link.props && link.props.id
              ? React.cloneElement(link, {
                  ...link.props,
                  id: `sidenav-${link.props.id}`,
                })
              : link;
          return <li key={index}>{clonedLink}</li>;
        });

    let navbar = (
      <nav className={navCSS}>
        <div className="nav-wrapper">
          {search ? null : (
            <>
              {brand &&
                React.cloneElement(brand, {
                  className: cx(brand.props.className, brandClasses),
                })}
              <a href="#!" data-target="mobile-nav" className="sidenav-trigger">
                {menuIcon}
              </a>
              {customMenuItem}
              {arrowBack}
              {language}
              {arrowNext}
              <ul className={(navMobileCSS, centeredEl)}>{links}</ul>
              {profileLinks && (
                <ul style={styleProfileItems} className={navMobileCSS}>
                  {profileLinks}
                </ul>
              )}
            </>
          )}
        </div>
        {extendWith && <div className="nav-content">{extendWith}</div>}
      </nav>
    );

    if (fixed) {
      navbar = <div className="navbar-fixed">{navbar}</div>;
    }

    return (
      <>
        {navbar}

        <ul
          id="mobile-nav"
          className={cx('sidenav', [alignLinks])}
          ref={ul => {
            this._sidenav = ul;
          }}
        >
          {sidenavLinks}
        </ul>
      </>
    );
  }
}

export default Navbar;
