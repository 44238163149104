import React from 'react';
import styled from 'styled-components';

import Warning from 'components/Alert/Warning';

const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.45);
  transition: all 0.3s ease-in-out;
  z-index: 82;
`;

const Message = styled.div`
  font-size: 13px;
  color: #324243;
  font-style: normal;
  font-weight: 400;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
  text-transform: uppercase;
  @media (min-width: 768px) {
    text-align: unset;
  }
`;

const NotFound = ({ message }) => {
  return (
    <CenterContainer>
      <Warning />
      <div>
        <Message>{message}</Message>
      </div>
    </CenterContainer>
  );
};

export default NotFound;
