import Jsona from 'jsona';

export const globalHeallyGTM = 'GTM-MRCKMQ2';

export const createZopimChat = () => {
  const defaultCode =
    'window.$zopim || (function (d, s) { var z = $zopim = function (c) { z._.push(c) }, $ = z.s = d.createElement(s), e = d.getElementsByTagName(s)[0]; z.set = function (o) { z.set._.push(o) }; z._ = []; z.set._ = []; $.async = !0; $.setAttribute("charset", "utf-8"); $.src = "//v2.zopim.com/?3oyxoPqJnjZRvn5m49ANQLOddjockqiZ"; z.t = +new Date; $.type = "text/javascript"; e.parentNode.insertBefore($, e) })(document, "script");';
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = defaultCode;

  document.body.appendChild(script);
};

export const dataFormatter = new Jsona();
