import React, { useEffect, Suspense, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';

import { useRouter } from 'hooks/router.hook';
import useLocalStorage from 'hooks/localStorage.hook';

import { fetchPatientSession, createPatientFromPartnerWithPreset } from 'actions';
import HomePatientProvider from 'state/patient/home';

import { createZopimChat } from 'utils/connectPartnerConfigs';
import {
  sendOtherAnalyticsAndServicesForPartner,
  connectPixelSiteScout,
  GTM_TAGS_FOR_TELELEAF_PARTNER,
} from 'modules/analytics';

import MedicalDocumentProvider from 'state/patient/medicalDocument';
import { usePartnerSettingState } from 'state/partnerSettingState';
import PatientProvider from 'state/patient';
import VisitFlowProvider from 'state/visitFlow';
import DocumentFlowProvider from 'state/documentFlow';
import PaymentProvider from 'state/patient/payment';
import SchedulingAvailableTimesProvider from 'state/scheduling/availableTimes';
import PatientProfileCouponProvider from 'state/patient/profile/coupon';
import SchedulingVisitTypesProvider from 'state/scheduling/visitTypes';
import SchedulingDoctorsProvider from 'state/scheduling/doctors';
import SchedulingPatientsProvider from 'state/scheduling/patients';
import VShopScheduleStateProvider from 'state/patient/vshopSchedule';

import { useGtagManager } from 'state/gTagManager';
import { useTrackingPixels } from 'state/trackingPixels';

import Loader from 'components/Loader/Loader';
import RippleLoader from 'components/Preloader/RippleLoader';
import { ErrorBoundaryCmp } from 'components/ErrorBoundary';

import PatientMainLayout from 'containers/Patient/PatientMainLayout';
import VisitContainer from 'containers/Visit';

import LangContext from 'state/language';

import { lazyWithRetry } from 'utils/rendering';
import { isValidColor } from 'utils/checkValidColor';
import { defaultTitle } from 'utils/defaultTitle';
import { isEmpty } from 'modules/validationFields';

import { STATUS } from '../../../constants';
import { IGNORE_401_ROUTES, SIGN_IN_URL } from 'constants/auth';

const CardDetails = lazyWithRetry(() => import('pages/Patient/CCardDetails'));
const Visits = lazyWithRetry(() => import('pages/ScheduleVisits'));
const ViewVisits = lazyWithRetry(() => import('pages/ScheduleVisits/View'));
const CreateVisit = lazyWithRetry(() => import('pages/ScheduleVisits/Create'));
const Home = lazyWithRetry(() => import('pages/Patient/Home'));
const FillForm = lazyWithRetry(() => import('pages/Patient/FillForm'));
const FillDynamicForm = lazyWithRetry(() => import('pages/Patient/FillDynamicForm'));
const AcceptVisit = lazyWithRetry(() => import('pages/Patient/AcceptVisit'));
const UploadId = lazyWithRetry(() => import('pages/Patient/UploadId'));
const Visit = lazyWithRetry(() => import('pages/Patient/Visit'));
const UploadMedDoc = lazyWithRetry(() => import('pages/Patient/UploadMedDoc'));
const PhoneDetails = lazyWithRetry(() => import('pages/ScheduleVisits/PhoneDetails'));
const CreateVisitByProvider = lazyWithRetry(() =>
  import('pages/ScheduleVisits/Create/createByProvider'),
);
const UpdateId = lazyWithRetry(() => import('pages/Patient/UpdateId'));
const BelugaHealth = lazyWithRetry(() => import('pages/Patient/Integration/BelugaHealth'));
const Documents = lazyWithRetry(() => import('pages/Patient/Documents'));
const Transactions = lazyWithRetry(() => import('pages/Patient/Transactions'));
const DownloadDocWithCode = lazyWithRetry(() => import('pages/Patient/DownloadDocWithCode'));
const BookAppt = lazyWithRetry(() => import('pages/Patient/BookAppt'));

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Subheader = styled.div`
  font-size: 13px;
  color: #324243;
  font-style: normal;
  font-weight: 300;
`;

const Message = styled.div`
  font-size: 13px;
  color: #324243;
  font-style: normal;
  font-weight: 400;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
  @media (min-width: 768px) {
    text-align: unset;
  }
`;

const PatientMainRouter = props => {
  let currentPathname = null;
  let currentSearch = null;
  const userScope = 'patient_admin';
  const { dispatch, patient, subdomain, query, order } = props;
  const { replace, pathname, history } = useRouter();
  const {
    loadingLang,
    currentLangData: { main_layout, visit_layout, titles },
    lang,
    changeLanguage,
    languageError,
    languageConfigs,
  } = useContext(LangContext);
  const { setBrandColor } = usePartnerSettingState();

  const { initGtagData } = useGtagManager();
  const { initRedditPixel, initFacebookPixel } = useTrackingPixels();

  const [_, setPatientEmail] = useLocalStorage('patientEmail');

  const ignore401 = IGNORE_401_ROUTES.some(r => pathname.includes(r));

  useEffect(() => {
    history.listen((newLocation, action) => {
      if (action === 'PUSH') {
        if (newLocation.pathname !== currentPathname || newLocation.search !== currentSearch) {
          currentPathname = newLocation.pathname;
          currentSearch = newLocation.search;

          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
          });
        }
      } else {
        if (currentPathname === '/f/patient_admin/visit') {
          window.location.href = '/f/patient_admin/home';
        }
      }
    });
  }, []);

  useEffect(() => {
    if (query?.preset) {
      dispatch(createPatientFromPartnerWithPreset(query?.preset, subdomain));
    } else {
      const query =
        '?include=partner_user.partner_branding,partner_user.partner_config,partner_user.schedule_visit_types.form_template,patient_card,patient_personal,patient_config,patient_medical_info,patient_personal.default_visit_type';

      dispatch(fetchPatientSession(query));
    }
  }, []);

  useEffect(() => {
    if (patient?.session?.status === STATUS.READY) {
      let faviconLink = document.querySelector("link[rel~='icon']");
      const partnerUser = patient?.session?.data?.partner_user;
      const partnerBranding = partnerUser?.partner_branding;
      const partnerConfig = partnerUser?.partner_config;
      const basicSubdomain = partnerBranding?.basic_subdomain || subdomain;

      if (partnerBranding) {
        const nameService = 'wickedreports';
        const color = isValidColor(partnerBranding?.button_color);

        setBrandColor(color);
        document.documentElement.style.setProperty('--primaryColor', color);
        faviconLink.href = partnerBranding?.favicon;

        sendOtherAnalyticsAndServicesForPartner(basicSubdomain, nameService);
      }

      if (partnerConfig?.show_chat_widget) {
        createZopimChat();
      }

      /* Start block with connect ads metrics */

      if (partnerConfig?.gtm_code) {
        // This's statemenet need for teleleaf partner with connect several gtm codes
        if (partnerUser?.id === '592') {
          GTM_TAGS_FOR_TELELEAF_PARTNER.forEach(gtmCode =>
            TagManager.initialize({ gtmId: gtmCode }),
          );
        }
        TagManager.initialize({ gtmId: partnerConfig?.gtm_code });

        if (
          partnerConfig?.aw_conversion_id &&
          partnerConfig?.tracking?.some(item => !isEmpty(item?.snippet))
        ) {
          initGtagData({
            awConversionID: partnerConfig?.aw_conversion_id,
            tracking: partnerConfig?.tracking,
            isEnabledTracking: true,
          });
        }
      }

      if (partnerConfig?.ga_code) {
        ReactGA.initialize(partnerConfig?.ga_code);
      }

      // Need replace on partner ID
      if (
        basicSubdomain?.toLowerCase() === 'teleleaf' ||
        basicSubdomain?.toLowerCase() === 'teleleafstates'
      ) {
        connectPixelSiteScout();
      }

      if (partnerUser?.id === '1027') {
        initRedditPixel('a2_di3gm1c592uo', { debug: false });
      }

      if (partnerUser?.id === '1162') {
        initFacebookPixel('1599067277505529');
      }

      // Needed for the partner to collect statistics or metrics
      if (patient?.session?.data?.email) {
        setPatientEmail(patient?.session?.data?.email);
      }

      /* End block with connect ads metrics */

      if (query?.preset) {
        replace(pathname);
      }
    }

    if (patient.session.status === STATUS.ERROR) {
      if (patient.session.code === 401 && !ignore401) {
        window.location.href = SIGN_IN_URL;
      }
    }
  }, [patient.session.status, pathname]);

  useEffect(() => {
    if (patient?.session?.status === STATUS.READY) {
      const partnerUser = patient?.session?.data?.partner_user;
      const partnerName = partnerUser?.name || 'Heally';
      const title = titles ? defaultTitle(pathname, titles) : null;
      document.title = title ? `${title} | ${partnerName}` : partnerName;
    }
  }, [patient.session.status, pathname, titles]);

  const goToPage = href => {
    window.location.href = href;
  };

  if (
    patient?.session?.status === STATUS.RUNNING ||
    patient?.session?.status === STATUS.IDLE ||
    (patient.session.status === STATUS.ERROR && patient.session.code === 401 && !ignore401) ||
    loadingLang
  ) {
    return (
      <LoaderContainer>
        <RippleLoader color={'#ebebeb'} />
      </LoaderContainer>
    );
  }

  if (
    (patient.session.status === STATUS.ERROR &&
      (patient.session.code >= 402 && patient.session.code <= 505)) ||
    languageError
  ) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className="f-modal-icon f-modal-warning scaleWarning">
          <span className="f-modal-body pulseWarningIns"></span>
          <span className="f-modal-dot pulseWarningIns"></span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <Message>WE ARE SORRY, THE PAGE HAS UNEXPECTED ERROR!</Message>
          </div>
          <div style={{ marginTop: '0.5rem' }}>
            <Subheader>
              We are working on it at the moment. Please retry or get back later.
            </Subheader>
          </div>
        </div>
      </div>
    );
  }

  const renderVisitContent = ({ component: Component }) => (
    <ErrorBoundaryCmp>
      <VisitContainer translate={visit_layout}>
        <Component translate={main_layout?.content?.visit} />
      </VisitContainer>
    </ErrorBoundaryCmp>
  );

  const patientUnathorized = patient?.session?.code === 401;

  const renderLayout = children => {
    if (patientUnathorized) return children;
    else
      return (
        <PatientMainLayout
          userSession={patient?.session?.data}
          goToPage={goToPage}
          lang={lang}
          translate={main_layout}
          handleChangeLanguage={value => changeLanguage(value, userScope)}
        >
          {children}
        </PatientMainLayout>
      );
  };

  /* Need make PatientProvider for all routes.  */
  return (
    <>
      {renderLayout(
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route
              path="/f/patient_admin/home"
              render={() => (
                <ErrorBoundaryCmp>
                  <PatientProvider>
                    <PaymentProvider>
                      <Home
                        userSession={patient?.session?.data}
                        dispatch={dispatch}
                        listAppointment={patient?.schedule_visits?.list_schedule_visits}
                        listVisits={patient?.visits}
                        translate={main_layout?.content}
                        visitTypes={patient?.schedule_visits?.partner_visit_types}
                        formVisitTypes={patient?.form_visit_types}
                      />
                    </PaymentProvider>
                  </PatientProvider>
                </ErrorBoundaryCmp>
              )}
            />
            {/* <Route
              path="/f/patient_admin/fill_form/:id"
              render={location => (
                <ErrorBoundaryCmp>
                  <FillForm
                    userSession={patient?.session?.data}
                    dispatch={dispatch}
                    translate={main_layout?.content}
                    visitTypes={patient?.schedule_visits?.partner_visit_types}
                    formVisitTypes={patient?.form_visit_types}
                    location={location}
                  />
                </ErrorBoundaryCmp>
              )}
            /> */}
            <Route
              path="/f/patient_admin/fill_form/:id"
              render={location => (
                <ErrorBoundaryCmp>
                  <PatientProvider>
                    <DocumentFlowProvider>
                      <FillDynamicForm
                        userSession={patient?.session?.data}
                        dispatch={dispatch}
                        translate={main_layout?.content}
                        visitTypes={patient?.schedule_visits?.partner_visit_types}
                        formVisitTypes={patient?.form_visit_types}
                        location={location}
                      />
                    </DocumentFlowProvider>
                  </PatientProvider>
                </ErrorBoundaryCmp>
              )}
            />
            <Route
              path="/f/patient_admin/accept_visit"
              render={() => (
                <ErrorBoundaryCmp>
                  <VisitFlowProvider>
                    <PatientProvider>
                      <PaymentProvider>
                        <PatientProfileCouponProvider>
                          <AcceptVisit
                            userSession={patient?.session?.data}
                            dispatch={dispatch}
                            visitTypes={patient?.schedule_visits?.partner_visit_types}
                            order={order}
                            translate={main_layout?.content?.accept_visit}
                          />
                        </PatientProfileCouponProvider>
                      </PaymentProvider>
                    </PatientProvider>
                  </VisitFlowProvider>
                </ErrorBoundaryCmp>
              )}
            />
            <Route
              path="/f/patient_admin/upload_id"
              render={() => (
                <ErrorBoundaryCmp>
                  <UploadId
                    translate={main_layout?.content}
                    userSession={patient?.session?.data}
                    dispatch={dispatch}
                    visitTypes={patient?.schedule_visits?.partner_visit_types}
                    formVisitTypes={patient?.form_visit_types}
                  />
                </ErrorBoundaryCmp>
              )}
            />
            <Route
              path="/f/patient_admin/async_payment"
              render={() => (
                <ErrorBoundaryCmp>
                  <BelugaHealth
                    userSession={patient?.session?.data}
                    dispatch={dispatch}
                    translate={main_layout?.content?.async_payment}
                  />
                </ErrorBoundaryCmp>
              )}
            />
            <Route
              path="/f/patient_admin/upload_medical_doc"
              render={() => (
                <ErrorBoundaryCmp>
                  <MedicalDocumentProvider>
                    <UploadMedDoc
                      userSession={patient?.session?.data}
                      dispatch={dispatch}
                      translate={main_layout?.content?.upload_document}
                    />
                  </MedicalDocumentProvider>
                </ErrorBoundaryCmp>
              )}
            />
            <Route
              path="/f/patient_admin/update_id"
              render={() => (
                <ErrorBoundaryCmp>
                  <PatientProvider>
                    <UpdateId
                      userSession={patient?.session?.data}
                      dispatch={dispatch}
                      translate={main_layout?.content?.update_id}
                    />
                  </PatientProvider>
                </ErrorBoundaryCmp>
              )}
            />
            <Route
              path="/f/patient_admin/card_details"
              render={() => (
                <ErrorBoundaryCmp>
                  <CardDetails
                    status={patient.session.status}
                    translate={main_layout?.content?.credit_card}
                    partnerConfig={patient?.session?.data?.partner_user?.partner_config}
                  />
                </ErrorBoundaryCmp>
              )}
            />
            <Route
              exact
              path="/f/patient_admin/schedule"
              render={props => (
                <ErrorBoundaryCmp>
                  <Visits {...props} translate={main_layout?.content}>
                    <ViewVisits
                      translate={main_layout?.content?.my_schedule_visits}
                      history={history}
                    />
                  </Visits>
                </ErrorBoundaryCmp>
              )}
              status={patient.session.status}
            />
            <Route
              exact
              path="/f/patient_admin/schedule/create"
              render={props => (
                <ErrorBoundaryCmp>
                  <Visits {...props} translate={main_layout?.content}>
                    <VShopScheduleStateProvider>
                      <SchedulingAvailableTimesProvider>
                        <CreateVisit
                          translate={main_layout?.content?.schedule_visit_create}
                          history={history}
                          languageConfigs={languageConfigs}
                          formVisitTypes={patient?.form_visit_types}
                        />
                      </SchedulingAvailableTimesProvider>
                    </VShopScheduleStateProvider>
                  </Visits>
                </ErrorBoundaryCmp>
              )}
              status={patient.session.status}
            />
            <Route
              exact
              path="/f/patient_admin/schedule/book_appt"
              render={() => (
                <ErrorBoundaryCmp>
                  <SchedulingPatientsProvider>
                    <SchedulingDoctorsProvider>
                      <SchedulingVisitTypesProvider>
                        <VShopScheduleStateProvider>
                          <SchedulingAvailableTimesProvider>
                            <VisitFlowProvider>
                              <BookAppt
                                translate={main_layout?.content?.schedule_visit_create}
                                languageConfigs={languageConfigs}
                                userSession={patient?.session?.data}
                                dispatch={dispatch}
                                formVisitTypes={patient?.form_visit_types}
                              />
                            </VisitFlowProvider>
                          </SchedulingAvailableTimesProvider>
                        </VShopScheduleStateProvider>
                      </SchedulingVisitTypesProvider>
                    </SchedulingDoctorsProvider>
                  </SchedulingPatientsProvider>
                </ErrorBoundaryCmp>
              )}
              status={patient.session.status}
            />
            <Route
              exact
              path="/f/patient_admin/schedule/create_by_provider"
              render={props => (
                <ErrorBoundaryCmp>
                  <Visits {...props} translate={main_layout?.content}>
                    <VShopScheduleStateProvider>
                      <SchedulingAvailableTimesProvider>
                        <CreateVisitByProvider
                          translate={main_layout?.content?.schedule_visit_create}
                          history={history}
                          languageConfigs={languageConfigs}
                          formVisitTypes={patient?.form_visit_types}
                        />
                      </SchedulingAvailableTimesProvider>
                    </VShopScheduleStateProvider>
                  </Visits>
                </ErrorBoundaryCmp>
              )}
              status={patient.session.status}
            />
            <Route
              exact
              path="/f/patient_admin/schedule/payment"
              render={() => (
                <ErrorBoundaryCmp>
                  <VisitFlowProvider>
                    <PatientProvider>
                      <PaymentProvider>
                        <PhoneDetails
                          userSession={patient?.session?.data}
                          dispatch={dispatch}
                          order={order}
                          translate={main_layout?.content?.schedule_visit_payment}
                          languageConfigs={languageConfigs}
                          visitTypes={patient.schedule_visits.partner_visit_types}
                          formVisitTypes={patient?.form_visit_types}
                          statusOfLastСreateVisit={patient?.schedule_visits?.create_visit?.status}
                          statusOfLastUpdateVisit={patient?.schedule_visits?.update_visit?.status}
                        />
                      </PaymentProvider>
                    </PatientProvider>
                  </VisitFlowProvider>
                </ErrorBoundaryCmp>
              )}
              status={patient.session.status}
            />
            <Route
              exact
              path="/f/patient_admin/visit"
              render={() =>
                renderVisitContent({
                  component: Visit,
                })
              }
            />
            <Route
              exact
              path="/f/patient_admin/schedule/reschedule/:apptID"
              render={location => (
                <ErrorBoundaryCmp>
                  <Visits translate={main_layout?.content}>
                    <VShopScheduleStateProvider>
                      <SchedulingAvailableTimesProvider>
                        <CreateVisit
                          history={history}
                          location={location}
                          translate={main_layout?.content?.schedule_visit_create}
                          languageConfigs={languageConfigs}
                        />
                      </SchedulingAvailableTimesProvider>
                    </VShopScheduleStateProvider>
                  </Visits>
                </ErrorBoundaryCmp>
              )}
              status={patient.session.status}
            />
            <Route
              exact
              path="/f/patient_admin/signed_documents"
              render={props => (
                <ErrorBoundaryCmp>
                  <PatientProvider>
                    <DocumentFlowProvider>
                      <Documents
                        userSession={patient?.session?.data}
                        translate={main_layout?.content?.signed_documents}
                        partnerUser={patient.session?.data?.partner_user}
                      />
                    </DocumentFlowProvider>
                  </PatientProvider>
                </ErrorBoundaryCmp>
              )}
              status={patient.session.status}
            />
            <Route
              exact
              path="/f/patient_admin/transactions"
              render={props => (
                <ErrorBoundaryCmp>
                  <PaymentProvider>
                    <Transactions
                      translate={main_layout?.content?.transactions}
                      userSession={patient?.session?.data}
                    />
                  </PaymentProvider>
                </ErrorBoundaryCmp>
              )}
              status={patient.session.status}
            />
            <Route
              exact
              path="/f/patient_admin/document_files/:documentId"
              render={props => (
                <ErrorBoundaryCmp>
                  <MedicalDocumentProvider>
                    <DownloadDocWithCode
                      translate={main_layout?.content?.document_files}
                      patientUnathorized={patientUnathorized}
                    />
                  </MedicalDocumentProvider>
                </ErrorBoundaryCmp>
              )}
              status={patient.session.status}
            />
            <Redirect from="/f/patient_admin" to="/f/patient_admin/home" />
          </Switch>
        </Suspense>,
      )}
    </>
  );
};

const mapStateToProps = state => ({
  patient: state.patient,
  partner: state?.partner,
  order: state?.order,
});

export default connect(mapStateToProps)(PatientMainRouter);
