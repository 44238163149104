import React from 'react';
import styled from 'styled-components';

const ReloadWrapper = styled.div`
  button {
    pointer-events: all;
  }
`;

const Reload = () => (
  <ReloadWrapper>
    <h3>There's a new version of this app!</h3>
    <button onClick={() => window.location.reload()}>Reload</button>
  </ReloadWrapper>
);

export default Reload;
