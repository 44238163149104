import { all, fork } from 'redux-saga/effects';

import app from './base';
import order from './order';
import patient from './patient';
import auth from './auth';
import partner from './partner';
import patientIdCheck from 'pages/Patient/UploadId/American/PatientIdCheckSaga';

export default function* root() {
  yield all([
    fork(app),
    fork(order),
    fork(patient),
    fork(auth),
    fork(partner),
    fork(patientIdCheck),
  ]);
}
