import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants';

export const {
  initialSetup,
  applyDiscountCode,
  fetchCreditCardToken,
  createCreditCard,
  fetchCreditCardDetails,
  destroyCreditCard,
  setPaymentMethod,
  deleteLastCoupon,
  createPurchase,
  createCreditCardAndPurchase,
  destroyCreditCardAndFetchNewToken,
  updatePatientPersonal,
  resetOrderState,
} = createActions({
  [ActionTypes.INITIAL_SETUP]: query => ({ query }),
  [ActionTypes.APPLY_DISCOUNT_CODE]: value => ({ value }),
  [ActionTypes.FETCH_CREDIT_CARD_TOKEN]: () => ({}),
  [ActionTypes.CREATE_CREDIT_CARD]: payload => payload,
  [ActionTypes.FETCH_CREDIT_CARD_DETAILS]: () => ({}),
  [ActionTypes.DESTROY_CREDIT_CARD]: () => ({}),
  [ActionTypes.SET_PAYMENT_METHOD]: value => ({ value }),
  [ActionTypes.DELETE_LAST_COUPON]: () => ({}),
  [ActionTypes.CREATE_PURCHASE]: () => ({}),
  [ActionTypes.CREATE_CREDIT_CARD_AND_PURCHASE]: (nonce, cardDetails) => ({ nonce, cardDetails }),
  [ActionTypes.DESTROY_CREDIT_CARD_AND_FETCH_NEW_TOKEN]: () => ({}),
  [ActionTypes.UPDATE_PATIENT_PERSONAL]: visitTypeId => ({ visitTypeId }),
  [ActionTypes.RESET_ORDER_STATE]: () => ({}),
});
