import Jsona from 'jsona';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { request, transformErrorsFromApi } from 'modules/client';

import { ActionTypes } from './PatientIdCheckConstants';

const baseUrl = '/v1/patient_admin';

const dataFormatter = new Jsona();

export function* fetchPatientIdentification() {
  try {
    const response = yield call(request, `${baseUrl}/identification/common`);

    yield put({
      type: ActionTypes.FETCH_PATIENT_IDENTIFICATION_SUCCESS,
      payload: dataFormatter.deserialize(response),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_PATIENT_IDENTIFICATION_FAIL,
      payload: error,
    });
  }
}

export function* updatePatientIdNumber({ payload }) {
  const {
    value: { idNumber },
  } = payload;

  try {
    const response = yield call(request, `${baseUrl}/identification/id_number`, {
      method: 'PATCH',
      payload: {
        id_number: idNumber,
      },
    });

    yield put({
      type: ActionTypes.UPDATE_PATIENT_ID_NUMBER_SUCCESS,
      payload: {
        idNumber,
        code: response.code,
      },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.UPDATE_PATIENT_ID_NUMBER_FAIL,
      payload: error,
    });
  }
}

export function* updatePatientFacePhoto({ payload }) {
  const { value, preview } = payload;
  try {
    const response = yield call(request, `${baseUrl}/identification/face_foto`, {
      method: 'PATCH',
      formData: value,
    });

    yield put({
      type: ActionTypes.UPDATE_PATIENT_FACE_PHOTO_SUCCESS,
      payload: dataFormatter.deserialize(response),
      code: response.code,
      preview,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.UPDATE_PATIENT_FACE_PHOTO_FAIL,
      payload: error,
    });
  }
}

export function* updatePatientIdPhoto({ payload }) {
  const { value, preview } = payload;

  try {
    const response = yield call(request, `${baseUrl}/identification/id_photo`, {
      method: 'PATCH',
      formData: value,
    });

    yield put({
      type: ActionTypes.UPDATE_PATIENT_ID_PHOTO_SUCCESS,
      payload: dataFormatter.deserialize(response),
      code: response.code,
      preview,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.UPDATE_PATIENT_ID_PHOTO_FAIL,
      payload: error,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.FETCH_PATIENT_IDENTIFICATION, fetchPatientIdentification),
    takeLatest(ActionTypes.UPDATE_PATIENT_ID_NUMBER, updatePatientIdNumber),
    takeLatest(ActionTypes.UPDATE_PATIENT_FACE_PHOTO, updatePatientFacePhoto),
    takeLatest(ActionTypes.UPDATE_PATIENT_ID_PHOTO, updatePatientIdPhoto),
  ]);
}
