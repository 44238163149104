import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/auth';

export const {
  signUpPartnerPatient,
  createMedicalFormWithPatientData
} = createActions({
  [ActionTypes.SIGN_UP_PARTNER_PATIENT]: (patientUser) => ({ patientUser }),
  [ActionTypes.CREATE_MEDICAL_FORM_WITH_PATIENT_DATA]: (values, medicalForm, setErrors) => ({ values, medicalForm, setErrors }),
});