import React from 'react';
import styled from 'styled-components';

import { useProfile } from 'state/profile';

import Navbar from 'components/Navbar';

const StyledNavbar = styled(Navbar)`
  background-color: #ffffff;
  box-shadow: none;
`;

const StyledImage = styled.img`
  height: 26px;
  width: auto;
  filter: ${({ offFilter }) => (offFilter ? 'none' : 'brightness(1000%)')};
`;

const Link = styled.a`
  position: absolute;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  cursor: pointer;
`;

const IntermediateHeader = () => {
  const { profile } = useProfile();

  const partnerLogo = profile?.data?.partner_user?.partner_branding?.logo;

  const subdomain = profile?.data?.partner_user?.partner_branding?.subdomain;
  const href = subdomain
    ? `https://${subdomain}.getheally.com/patient_admin/patient_users/sign_in`
    : 'http://getheally.localhost/patient_admin/patient_users/sign_in';

  return (
    <StyledNavbar
      brand={
        subdomain ? (
          <Link href={href}>
            <StyledImage offFilter={partnerLogo && true} src={partnerLogo} />
          </Link>
        ) : (
          <></>
        )
      }
      centerLogo
      options={{
        draggable: false,
        edge: 'left',
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: null,
        onOpenEnd: null,
        onOpenStart: null,
        outDuration: 200,
        preventScrolling: true,
      }}
    />
  );
};

export default IntermediateHeader;
