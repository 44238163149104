import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootSaga from 'sagas';
import rootReducer from 'reducers';

import middleware, { sagaMiddleware } from './middleware';

const reducer = persistReducer(
  {
    key: 'gHrp',
    storage,
    whitelist: [''],
    version: 1,
    migrate: (state) => {
      console.log('Migration Running!')
      return Promise.resolve(state)
    }
  },
  combineReducers({ ...rootReducer }),
);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configStore = (initialState = {}) => {
  const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(...middleware)));

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('reducers', () => {
      store.replaceReducer(require('reducers/index').default);
    });
  }

  return {
    persistor: persistStore(store),
    store,
  };
};

const { store, persistor } = configStore();

global.store = store;

export { store, persistor };
