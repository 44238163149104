import { isEmpty } from 'modules/validationFields';
import React, { useState, createContext, useContext } from 'react';

export const StateContext = createContext(null);

export default function GTagManagerProvider({ children }) {
  window.dataLayer = window.dataLayer || [];

  /*
    state for gtagData:

    awConversionID: string;
    tracking: array;
    isEnabledTracking: boolean;
  */
  const [gtagData, setGtagData] = useState(null);

  const gtag = () => {
    window.dataLayer?.push(arguments);
  };

  const checkOnLocalStage = () => {
    const hostname = window.location.hostname;

    return hostname.indexOf('stage') >= 1 || hostname.indexOf('localhost') >= 1;
  };

  /*  
    List pages with tracking:

    payment,
    short_registration,
    patient_registration_on_iframe,
    book_appointment,
    dynamic_form_viewed,
    dynamic_form_submitted,
    id_form_viewed,
    id_uploaded,
    payment_form_viewed,
    schedule_viewed,
  */

  const parseStringToObject = str => {
    const keyValuePairs = str.match(/(\w+): ([^,}]+)/g);
    const obj = {};
    if (keyValuePairs) {
      keyValuePairs.forEach(pair => {
        const [key, value] = pair.split(':').map(item => item.trim());
        obj[key] = value;
      });
    }
    return obj;
  };

  const getParams = inputString => {
    const splitArray = inputString.split(',').map(item => item.replace(/'/g, '').trim());

    if (splitArray.length > 3) {
      const lastElement = splitArray.slice(2).join(', ');
      splitArray.splice(2);
      splitArray.push(lastElement);
    }

    return splitArray;
  };

  const parseGtagFn = data => {
    try {
      const gtagString = data?.replace('gtag(', '')?.replace(');', '');
      const params = getParams(gtagString);

      if (params?.length > 1) {
        const copyParams = [...params];
        const param3Object = parseStringToObject(copyParams[2]);

        const mappingParams = copyParams?.map((el, index) => (index === 2 ? param3Object : el));

        return mappingParams;
      }

      return [parseStringToObject(params[0])];
    } catch (e) {
      console.log(e);

      return [];
    }
  };

  const sendToGTM = (event, data) => {
    if (gtag) {
      const awConversionID = gtagData?.awConversionID;
      const tracking = gtagData?.tracking?.find(track => track?.place === event);
      const isLocalStage = checkOnLocalStage();

      if (isLocalStage) {
        console.log(`✅ Send ${event} to gtag`);
      }

      if (!tracking || isEmpty(tracking?.snippet)) {
        console.warn(`this ${event} does not exist`);
        return;
      }

      gtag('js', new Date());
      gtag('config', awConversionID);

      // send tracking?.snippet
      const gtagParams = parseGtagFn(tracking?.snippet);

      /* 
        gtag('event', 'event_name', {
          'key': 'value',
        });

        -- OR --

        { event: 'value' }
      */

      if (gtagParams?.length > 1) {
        gtag(gtagParams[0], gtagParams[1], {
          ...gtagParams[3],
          ...data,
        });
      } else {
        window.dataLayer.push({
          ...gtagParams[0],
          ...data,
        });
      }
    } else {
      console.warn(`dataLayer ${window.dataLayer} does not exist, has script be initialized`);
    }
  };

  const initGtagData = data => setGtagData(data);

  const contextValue = {
    sendToGTM,
    initGtagData,
    gtagData,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useGtagManager() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useGtagManager must be used with GTagManagerProvider');
  }
  return context;
}
