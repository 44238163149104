import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components';

import { useRouter } from 'hooks/router.hook';
import useLocalStorage from 'hooks/localStorage.hook';

import { useProfile } from 'state/profile';

import { ErrorBoundaryCmp } from 'components/ErrorBoundary';
import Message from 'components/Message';
import RippleLoader from 'components/Preloader/RippleLoader';

import IntermediateHeader from 'containers/Patient/Header/IntermediateHeader';

import ProfileProvider from 'state/profile';

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  @media only screen and (max-width: 649px) {
    min-height: 460px;
    align-items: initial;
    flex: auto;
  }
`;

const CenterContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background: rgba(255, 255, 255, 0.85);
`;

const App = ({ children }) => {
  const { fetchProfileInformation, profile } = useProfile();

  useEffect(() => {
    const query =
      '?include=partner_user.partner_branding,partner_user.partner_config,patient_personal.default_visit_type';
    fetchProfileInformation(query);
  }, []);

  if (profile?.status === 'idle' || profile?.status === 'running') {
    return (
      <CenterContainer>
        <RippleLoader color="var(--primaryColor)" />
      </CenterContainer>
    );
  }

  if (profile?.status === 'error') {
    return (
      <Message
        type="error"
        header="we are sorry, the page has unexpected error!"
        subheader={profile?.data?.partner_user?.partner_config?.error_messages?.contact_support}
      />
    );
  }

  return children || null;
};

const IntermediateLayout = ({ children }) => {
  const { query } = useRouter();
  const [_, setToken] = useLocalStorage('token');

  const isToken = useMemo(() => {
    if (query?.token) {
      setToken(`Bearer ${query?.token}`);
      return true;
    }

    return false;
  }, []);

  if (!isToken) {
    return (
      <Message
        type="error"
        header="we are sorry, the page has unexpected error!"
        subheader="Please contact support via live chat below."
      />
    );
  }

  return (
    <ProfileProvider>
      <IntermediateHeader />
      <Main>
        <ErrorBoundaryCmp>
          <App children={children} />
        </ErrorBoundaryCmp>
      </Main>
    </ProfileProvider>
  );
};

export default IntermediateLayout;
