import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import { parseError } from 'modules/client';

import { ActionTypes, STATUS } from '../constants/patient';

export const patientState = {
  form_visit_types: {
    data: [],
    status: STATUS.IDLE,
    header: '',
    message: '',
  },
  schedule_visits: {
    partner_visit_types: {
      data: [],
      status: STATUS.IDLE,
      header: '',
      message: '',
    },
    visit_types: {
      data: [],
      status: STATUS.IDLE,
      header: '',
      message: '',
    },
    free_positions: {
      data: {},
      status: STATUS.IDLE,
      header: '',
      message: '',
    },
    free_days: {
      data: [],
      status: STATUS.IDLE,
      header: '',
      message: '',
      error: {},
    },
    free_doctors: {
      data: [],
      status: STATUS.IDLE,
      header: '',
      message: '',
      time: null,
    },
    list_schedule_visits: {
      data: [],
      visitInformation: {
        data: [],
      },
      status: STATUS.IDLE,
      header: '',
      message: '',
    },
    create_visit: {
      data: {},
      status: STATUS.IDLE,
      header: '',
      message: '',
      code: 204,
      error: null,
    },
    update_visit: {
      data: {},
      status: STATUS.IDLE,
      header: '',
      message: '',
      code: 204,
      error: null,
    },
    cancel_visit: {
      data: [],
      status: STATUS.IDLE,
      header: '',
      message: '',
      current_visit_id: null,
    },
    providers: {
      data: [],
      status: STATUS.IDLE,
      header: '',
      message: '',
    },
  },
  direction: {
    options: {},
  },
  session: {
    data: {},
    header: '',
    message: '',
    status: STATUS.IDLE,
    code: null,
  },
  visits: {
    data: [],
    header: '',
    message: '',
    status: STATUS.IDLE,
    code: null,
  },
  status: STATUS.IDLE,
  header: '',
  message: '',
  code: 204,
};

export default {
  patient: handleActions(
    {
      [ActionTypes.IFRAME_FETCH_PARTNER_VISIT_TYPES]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            partner_visit_types: {
              status: { $set: STATUS.RUNNING },
            },
          },
        }),
      [ActionTypes.FETCH_PARTNER_VISIT_TYPES]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            partner_visit_types: {
              status: { $set: STATUS.RUNNING },
            },
          },
        }),
      [ActionTypes.FETCH_PARTNER_VISIT_TYPES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            partner_visit_types: {
              status: { $set: STATUS.READY },
              data: { $set: payload.data },
            },
          },
        }),
      [ActionTypes.FETCH_PARTNER_VISIT_TYPES_FAIL]: (state, { payload }) => {
        if (payload?.status === 401 || payload?.status === 403) {
          return immutable(state, {
            code: { $set: payload?.status },
            status: { $set: payload?.status },
            header: { $set: parseError(payload.message) },
            message: { $set: parseError(payload?.response?.error) },
          });
        }
        return immutable(state, {
          schedule_visits: {
            partner_visit_types: {
              status: { $set: STATUS.ERROR },
              header: { $set: parseError(payload.message) },
              message: { $set: parseError(payload.response && payload.response.error) },
            },
          },
        });
      },

      [ActionTypes.FETCH_PARTNER_VISIT_TYPE]: state =>
        immutable(state, {
          schedule_visits: {
            partner_visit_types: {
              status: { $set: STATUS.RUNNING },
            },
          },
        }),
      [ActionTypes.FETCH_PARTNER_VISIT_TYPE_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            partner_visit_types: {
              status: { $set: STATUS.READY },
              data: { $set: payload.data },
            },
          },
        }),
      [ActionTypes.FETCH_PARTNER_VISIT_TYPE_FAIL]: (state, { payload }) => {
        if (payload?.status === 401 || payload?.status === 403) {
          return immutable(state, {
            code: { $set: payload?.status },
            status: { $set: payload?.status },
            header: { $set: parseError(payload.message) },
            message: { $set: parseError(payload?.response?.error) },
          });
        }
        return immutable(state, {
          schedule_visits: {
            partner_visit_types: {
              status: { $set: STATUS.ERROR },
              header: { $set: parseError(payload.message) },
              message: { $set: parseError(payload.response && payload.response.error) },
            },
          },
        });
      },

      [ActionTypes.FETCH_FORMS_VISIT_TYPES]: (state, { payload }) =>
        immutable(state, {
          form_visit_types: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.FETCH_FORMS_VISIT_TYPES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          form_visit_types: {
            status: { $set: STATUS.READY },
            data: { $set: payload.data },
          },
        }),
      [ActionTypes.FETCH_FORMS_VISIT_TYPES_FAIL]: (state, { payload }) => {
        if (payload?.status === 401 || payload?.status === 403) {
          return immutable(state, {
            code: { $set: payload?.status },
            status: { $set: payload?.status },
            header: { $set: parseError(payload.message) },
            message: { $set: parseError(payload?.response?.error) },
          });
        }
        return immutable(state, {
          form_visit_types: {
            status: { $set: STATUS.ERROR },
            header: { $set: parseError(payload.message) },
            message: { $set: parseError(payload.response && payload.response.error) },
          },
        });
      },

      [ActionTypes.FETCH_VISIT_TYPES]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            visit_types: {
              status: { $set: STATUS.RUNNING },
            },
          },
        }),
      [ActionTypes.FETCH_VISIT_TYPES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            visit_types: {
              status: { $set: STATUS.READY },
              data: { $set: payload.data },
            },
          },
        }),
      [ActionTypes.FETCH_VISIT_TYPES_FAIL]: (state, { payload }) => {
        if (payload?.status === 401 || payload?.status === 403) {
          return immutable(state, {
            code: { $set: payload?.status },
            status: { $set: payload?.status },
            header: { $set: parseError(payload.message) },
            message: { $set: parseError(payload?.response?.error) },
          });
        }
        return immutable(state, {
          schedule_visits: {
            visit_types: {
              status: { $set: STATUS.ERROR },
              header: { $set: parseError(payload.message) },
              message: { $set: parseError(payload.response && payload.response.error) },
            },
          },
        });
      },

      [ActionTypes.FETCH_FREE_POSITIONS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            free_positions: {
              status: { $set: STATUS.RUNNING },
            },
          },
        }),
      [ActionTypes.FETCH_FREE_POSITIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            free_positions: {
              status: { $set: STATUS.READY },
              data: { $set: payload.data },
            },
            free_doctors: {
              time: { $set: null },
            },
          },
        }),
      [ActionTypes.FETCH_FREE_POSITIONS_FAIL]: (state, { payload }) => {
        if (payload?.status === 401 || payload?.status === 403) {
          return immutable(state, {
            code: { $set: payload?.status },
            status: { $set: payload?.status },
            header: { $set: parseError(payload.message) },
            message: { $set: parseError(payload?.response?.error) },
          });
        }
        return immutable(state, {
          schedule_visits: {
            free_positions: {
              data: { $set: null },
              status: { $set: STATUS.ERROR },
              header: { $set: parseError(payload.message) },
              message: { $set: parseError(payload.response && payload.response.error) },
            },
          },
        });
      },

      [ActionTypes.IFRAME_FETCH_FREE_DAYS]: state =>
        immutable(state, {
          schedule_visits: {
            free_days: {
              status: { $set: STATUS.RUNNING },
            },
          },
        }),

      [ActionTypes.FETCH_FREE_DAYS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            free_days: {
              status: { $set: STATUS.RUNNING },
            },
          },
        }),
      [ActionTypes.FETCH_FREE_DAYS_SUCCESS]: (state, { payload }) => {
        // const convertDate = payload.data.map(v => new Date(v));

        return immutable(state, {
          schedule_visits: {
            free_days: {
              status: { $set: STATUS.READY },
              data: { $set: payload },
            },
          },
        });
      },
      [ActionTypes.FETCH_FREE_DAYS_FAIL]: (state, { payload }) => {
        if (payload?.status === 401 || payload?.status === 403) {
          return immutable(state, {
            code: { $set: payload?.status },
            status: { $set: payload?.status },
            header: { $set: parseError(payload.message) },
            message: { $set: parseError(payload?.response?.error) },
          });
        }
        return immutable(state, {
          schedule_visits: {
            free_days: {
              data: { $set: [] },
              status: { $set: STATUS.ERROR },
              header: { $set: parseError(payload.message) },
              message: { $set: parseError(payload.response && payload.response.error) },
              error: { $set: payload?.response },
            },
          },
        });
      },

      [ActionTypes.IFRAME_FETCH_FREE_DOCTORS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            free_doctors: {
              status: { $set: STATUS.RUNNING },
              data: {
                $set: [],
              },
              time: {
                $set: payload.time,
              },
            },
          },
        }),

      [ActionTypes.FETCH_FREE_DOCTORS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            free_doctors: {
              status: { $set: STATUS.RUNNING },
              data: {
                $set: [],
              },
              time: {
                $set: payload.time,
              },
            },
          },
        }),
      [ActionTypes.FETCH_FREE_DOCTORS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            free_doctors: {
              status: { $set: STATUS.READY },
              data: {
                $set: payload.data,
              },
              time: {
                $set: payload.time,
              },
            },
            // free_doctors: {
            //   status: { $set: STATUS.READY },
            //   data: {
            //     $set: payload.time === state.schedule_visits.free_doctors.time ? [] : payload.data,
            //   },
            //   time: {
            //     $set:
            //       payload.time === state.schedule_visits.free_doctors.time ? null : payload.time,
            //   },
            // },
          },
        }),
      [ActionTypes.FETCH_FREE_DOCTORS_FAIL]: (state, { payload }) => {
        if (payload?.status === 401 || payload?.status === 403) {
          return immutable(state, {
            code: { $set: payload?.status },
            status: { $set: payload?.status },
            header: { $set: parseError(payload.message) },
            message: { $set: parseError(payload?.response?.error) },
          });
        }
        return immutable(state, {
          schedule_visits: {
            free_doctors: {
              status: { $set: STATUS.ERROR },
              header: { $set: parseError(payload.message) },
              message: { $set: parseError(payload.response && payload.response.error) },
            },
          },
        });
      },

      [ActionTypes.FETCH_SCHEDULE_VISITS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            list_schedule_visits: {
              status: { $set: STATUS.RUNNING },
            },
            create_visit: {
              data: { $set: {} },
              status: { $set: STATUS.IDLE },
              header: { $set: '' },
              message: { $set: '' },
              code: { $set: 204 },
              error: { $set: null },
            },
          },
          code: { $set: 204 },
          header: { $set: '' },
          message: { $set: '' },
        }),
      [ActionTypes.FETCH_SCHEDULE_VISITS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            list_schedule_visits: {
              status: { $set: STATUS.READY },
              data: { $set: payload.data },
              visitInformation: {
                data: { $set: payload.visitInformation },
              },
            },
          },
        }),
      [ActionTypes.FETCH_SCHEDULE_VISITS_FAIL]: (state, { payload }) => {
        if (payload?.status === 401 || payload?.status === 403) {
          return immutable(state, {
            code: { $set: payload?.status },
            status: { $set: payload?.status },
            header: { $set: parseError(payload.message) },
            message: { $set: parseError(payload?.response?.error) },
          });
        }
        return immutable(state, {
          schedule_visits: {
            list_schedule_visits: {
              status: { $set: STATUS.ERROR },
              header: { $set: parseError(payload.message) },
              message: { $set: parseError(payload.response && payload.response.error) },
            },
          },
        });
      },

      [ActionTypes.CREATE_SCHEDULE_VISIT]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            create_visit: {
              status: { $set: STATUS.RUNNING },
            },
            free_doctors: {
              status: { $set: STATUS.IDLE },
              data: { $set: [] },
              header: { $set: '' },
              message: { $set: '' },
              time: { $set: null },
            },
          },
        }),
      [ActionTypes.CREATE_SCHEDULE_VISIT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            create_visit: {
              status: { $set: STATUS.READY },
              data: { $set: payload.data },
            },
          },
        }),
      [ActionTypes.CREATE_SCHEDULE_VISIT_FAIL]: (state, { payload }) => {
        if (payload?.status === 401 || payload?.status === 403) {
          return immutable(state, {
            code: { $set: payload?.status },
            status: { $set: payload?.status },
            header: { $set: parseError(payload.message) },
            message: { $set: parseError(payload?.response?.error) },
          });
        }
        return immutable(state, {
          schedule_visits: {
            create_visit: {
              status: { $set: STATUS.ERROR },
              header: { $set: parseError(payload.message) },
              message: {
                $set: parseError(
                  payload.response && (payload.response.error || payload.response.errors),
                ),
              },
              code: { $set: payload?.status },
              error: { $set: payload?.response },
            },
          },
        });
      },

      [ActionTypes.UPDATE_SCHEDULE_VISIT]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            update_visit: {
              status: { $set: STATUS.RUNNING },
            },
            free_doctors: {
              status: { $set: STATUS.IDLE },
              data: { $set: [] },
              header: { $set: '' },
              message: { $set: '' },
              time: { $set: null },
            },
          },
        }),
      [ActionTypes.UPDATE_SCHEDULE_VISIT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            update_visit: {
              status: { $set: STATUS.READY },
              data: { $set: payload.data },
            },
          },
        }),
      [ActionTypes.UPDATE_SCHEDULE_VISIT_FAIL]: (state, { payload }) => {
        if (payload?.status === 401 || payload?.status === 403) {
          return immutable(state, {
            code: { $set: payload?.status },
            status: { $set: payload?.status },
            header: { $set: parseError(payload.message) },
            message: { $set: parseError(payload?.response?.error) },
          });
        }
        return immutable(state, {
          schedule_visits: {
            update_visit: {
              status: { $set: STATUS.ERROR },
              header: { $set: parseError(payload.message) },
              message: {
                $set: parseError(
                  payload.response && (payload.response.error || payload.response.errors),
                ),
              },
              code: { $set: payload?.status },
              error: { $set: payload?.response },
            },
          },
        });
      },

      [ActionTypes.CANCEL_SCHEDULE_VISIT]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            cancel_visit: {
              status: { $set: STATUS.RUNNING },
              current_visit_id: { $set: payload.id },
            },
          },
        }),
      [ActionTypes.CANCEL_SCHEDULE_VISIT_SUCCESS]: (state, { payload }) => {
        const visitIds = [...state.schedule_visits.cancel_visit.data, payload.id];

        return immutable(state, {
          schedule_visits: {
            cancel_visit: {
              status: { $set: STATUS.READY },
              data: { $set: visitIds },
              current_visit_id: { $set: null },
            },
          },
        });
      },
      [ActionTypes.CANCEL_SCHEDULE_VISIT_FAIL]: (state, { payload }) => {
        if (payload?.status === 401 || payload?.status === 403) {
          return immutable(state, {
            code: { $set: payload?.status },
            status: { $set: payload?.status },
            header: { $set: parseError(payload.message) },
            message: { $set: parseError(payload?.response?.error) },
          });
        }
        return immutable(state, {
          schedule_visits: {
            cancel_visit: {
              status: { $set: STATUS.ERROR },
              header: { $set: parseError(payload.message) },
              message: { $set: parseError(payload.response && payload.response.error) },
              current_visit_id: { $set: null },
            },
          },
        });
      },

      [ActionTypes.SET_OPTIONS_FOR_DIRECTION]: (state, { payload }) =>
        immutable(state, {
          direction: {
            options: { $set: payload.options },
          },
        }),

      [ActionTypes.FETCH_PATIENT_SESSION]: (state, { payload }) =>
        immutable(state, {
          session: {
            data: { $set: payload },
            header: { $set: '' },
            message: { $set: '' },
            status: { $set: STATUS.RUNNING },
            code: { $set: null },
          },
        }),
      [ActionTypes.FETCH_PATIENT_SESSION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          session: {
            data: { $set: payload },
            status: { $set: STATUS.READY },
            code: { $set: 200 },
          },
        }),
      [ActionTypes.FETCH_PATIENT_SESSION_FAIL]: (state, { payload }) =>
        immutable(state, {
          session: {
            header: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload.response && payload.response.error) },
            code: { $set: payload.status },
          },
        }),

      [ActionTypes.CREATE_PATIENT_FROM_PARTNER_WITH_PRESET]: (state, { payload }) =>
        immutable(state, {
          session: {
            data: { $set: payload },
            header: { $set: '' },
            message: { $set: '' },
            status: { $set: STATUS.RUNNING },
            code: { $set: null },
          },
        }),
      [ActionTypes.CREATE_PATIENT_FROM_PARTNER_WITH_PRESET_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          session: {
            data: { $set: payload },
            status: { $set: STATUS.READY },
            code: { $set: 200 },
          },
        }),
      [ActionTypes.CREATE_PATIENT_FROM_PARTNER_WITH_PRESET_FAIL]: (state, { payload }) =>
        immutable(state, {
          session: {
            header: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload.response && payload.response.error) },
            code: { $set: payload.status },
          },
        }),

      [ActionTypes.FETCH_PATIENT_VISITS]: (state, { payload }) =>
        immutable(state, {
          visits: {
            data: { $set: [] },
            header: { $set: '' },
            message: { $set: '' },
            status: { $set: STATUS.RUNNING },
            code: { $set: null },
          },
        }),
      [ActionTypes.FETCH_PATIENT_VISITS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          visits: {
            data: { $set: payload },
            status: { $set: STATUS.READY },
            code: { $set: 200 },
          },
        }),
      [ActionTypes.FETCH_PATIENT_VISITS_FAIL]: (state, { payload }) =>
        immutable(state, {
          visits: {
            header: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload.response && payload.response.error) },
            code: { $set: payload.status },
          },
        }),

      [ActionTypes.UPDATE_PATIENT_SESSION]: (state, { payload }) =>
        immutable(state, {
          session: {
            data: { $set: { ...state.session.data, ...payload.values } },
          },
        }),

      [ActionTypes.RESET_STATE_FREE_DAYS]: state =>
        immutable(state, {
          schedule_visits: {
            free_days: {
              status: { $set: STATUS.IDLE },
            },
            free_doctors: {
              status: { $set: STATUS.IDLE },
              data: {
                $set: [],
              },
              time: {
                $set: null,
              },
            },
          },
        }),
      [ActionTypes.RESET_STATE_VISIT]: state =>
        immutable(state, {
          schedule_visits: {
            create_visit: {
              data: { $set: {} },
              status: { $set: STATUS.IDLE },
              header: { $set: '' },
              message: { $set: '' },
              code: { $set: 204 },
              error: { $set: null },
            },
            update_visit: {
              data: { $set: {} },
              status: { $set: STATUS.IDLE },
              header: { $set: '' },
              message: { $set: '' },
              code: { $set: 204 },
              error: { $set: null },
            },
          },
        }),
      [ActionTypes.FETCH_PROVIDERS]: (state, _) =>
        immutable(state, {
          schedule_visits: {
            providers: {
              data: { $set: [] },
              header: { $set: '' },
              message: { $set: '' },
              status: { $set: STATUS.RUNNING },
            },
          },
        }),
      [ActionTypes.FETCH_PROVIDERS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            providers: {
              data: { $set: payload },
              status: { $set: STATUS.READY },
            },
          },
        }),
      [ActionTypes.FETCH_PROVIDERS_FAIL]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            providers: {
              header: { $set: parseError(payload.message) },
              status: { $set: STATUS.ERROR },
              message: { $set: parseError(payload.response && payload.response.error) },
            },
          },
        }),
      [ActionTypes.SET_PROVIDER_ON_SCHEDULING]: (state, { payload }) =>
        immutable(state, {
          schedule_visits: {
            free_doctors: {
              data: { $set: payload?.data?.provider },
              time: {
                $set: payload?.data?.time,
              },
              status: { $set: STATUS.READY },
            },
          },
        }),
    },
    patientState,
  ),
};
