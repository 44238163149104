import React from 'react';
import ReactDOM from 'react-dom/client';
import smoothscroll from 'smoothscroll-polyfill';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import * as Sentry from '@sentry/react';
import { CaptureConsole, ExtraErrorData, HttpClient } from '@sentry/integrations';

import { store, persistor } from './store';
import { showMessage } from './actions';

import App from './containers/App';

import Reload from './components/Reload';
import RippleLoader from 'components/Preloader/RippleLoader';

import './index.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './styles/materialize.scss';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';

smoothscroll.polyfill();

export const app = {
  run() {
    this.render(App);

    if (process.env.NODE_ENV === 'production') {
      this.initOfflinePlugin();
      // this.initSentry();
    }
  },

  initOfflinePlugin() {
    const OfflinePlugin = require('offline-plugin/runtime');

    OfflinePlugin.install({
      onUpdateReady: () => {
        OfflinePlugin.applyUpdate();
      },
      onUpdated: () => {
        store.dispatch(showMessage(<Reload />, { id: '', icon: '', timeout: 0 }));
      },
    });
  },

  initSentry() {
    Sentry.init({
      dsn:
        'https://a523c3fb4ae7ab82fdb31a5136293d63@o4506115403874304.ingest.sentry.io/4506115496017920',
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        new Sentry.BrowserTracing({
          traceFetch: false,
        }),
        new CaptureConsole({ levels: ['error'] }),
        new ExtraErrorData(),
        new HttpClient(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        /^https?:\/\/[a-z]+\.getheally\.com/,
        /^https?:\/\/[a-z]+\.videovisitmd\.com/,
        /^https?:\/\/[a-z]+\.teleleaf\.com/,
        /^https?:\/\/[a-z]+\.xivix\.com/,
        /^https?:\/\/[a-z]+\.joinminimal\.com/,
        /^https?:\/\/[a-z]+\.leafymate\.com/,
        /^https?:\/\/[a-z]+\.leafydoc\.com/,
        /^https?:\/\/[a-z]+\.wellnesswag\.com/,
      ],
    });
  },

  render(Component) {
    const container = document.getElementById('root');
    const root = ReactDOM.createRoot(container);

    if (root) {
      root.render(
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={<RippleLoader color="#5cc2b1" />}>
            <Component />
          </PersistGate>
          <div id="react-portal-modal-container"></div>
        </Provider>,
      );
    }
  },
};

app.run();
