const checkOnLocalStage = () => {
  const hostname = window.location.hostname;

  return hostname.indexOf('stage') >= 1 || hostname.indexOf('localhost') >= 1;
};

const getDataEvents = key => {
  if (key === 'payment')
    return {
      event: 'purchase',
      transaction_type: 'Patient visit',
      currency: 'USD',
    };

  return {};
};

const viewImagePixel = imageSrc => {
  const image = document.createElement('img');
  image.src = imageSrc;
  image.width = 1;
  image.height = 1;
  image.alt = '';

  document.body.appendChild(image);
};

export const connectPixelSiteScout = () => {
  const script = document.createElement('script');

  script.src = 'https://cdn01.basis.net/assets/up.js?um=1';

  document.body.appendChild(script);
};

export const GTM_TAGS_FOR_TELELEAF_PARTNER = ['GTM-M8VC2QX', 'GTM-5FBGNZ8', 'GTM-WDM7XKZ6'];

export const sendGTAGAdword = (partner, data, key = '') => {
  const MOBILEIV_PARNTERS = [
    'holistic',
    'solhotyogasfca',
    'davedavedave',
    'satori',
    'movementbymaddy',
    'hotpilatessacramento',
  ];

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer?.push(arguments);
  }

  if (window.dataLayer) {
    console.log(`✅ Added google tag manager for ${partner} partner`);

    if (partner === 'medcardpenn' || partner === 'md') {
      gtag('set', 'linker', {
        domains: ['pennmedcard.com', 'medcardpenn.getheally.com', 'md.videovisitmd.com'],
        accept_incoming: true,
        decorate_forms: true,
      });
      gtag('js', new Date());

      gtag('config', 'AW-528024115');

      gtag('event', 'conversion', {
        send_to: 'AW-528024115/f0hPCLvp984DELOE5PsB',
      });
    }

    if (key === 'payment') {
      if (partner === 'teleleaf' || partner === 'teleleafstates') {
        gtag('js', new Date());

        gtag('config', 'AW-11303154548');
        gtag('event', 'conversion', {
          ...data,
          send_to: 'AW-11303154548/oWa6CJamitMYEPTm4Y0q',
          currency: 'USD',
        });
      }

      if (partner === 'verte') {
        const imageSrc =
          'https://ad.doubleclick.net/ddm/activity/src=12972466;type=conver;cat=verte001;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=;gdpr_consent=;ord=1;num=1?';

        gtag('js', new Date());

        gtag('config', 'DC-12972466');
        gtag('event', 'conversion', {
          allow_custom_scripts: true,
          send_to: 'DC-12972466/conver/verte001+unique',
        });

        viewImagePixel(imageSrc);
      }

      if (partner === 'cannacaredocs' || partner === 'relaxed' || partner === 'ccdp') {
        gtag('js', new Date());

        gtag('config', 'AW-581941981');

        gtag('event', 'conversion', {
          ...data,
          send_to: 'AW-581941981/tcwwCLqH2ZQYEN31vpUC',
          currency: 'USD',
        });
      }

      if (partner === 'greenmindphysicians') {
        gtag('js', new Date());

        gtag('config', 'AW-651300844');

        gtag('event', 'conversion', {
          ...data,
          send_to: 'AW-651300844/GBB_CPPfspoYEOyfyLYC',
          currency: 'USD',
        });
      }

      if (MOBILEIV_PARNTERS.includes(partner)) {
        gtag('js', new Date());

        gtag('config', 'AW-10976039410');

        gtag('event', 'conversion', {
          ...data,
          send_to: 'AW-10976039410/WevyCLf_oJwYEPKj5PEo',
          currency: 'USD',
        });
      }

      if (partner === 'fastesa' || partner === 'mymmj' || partner === 'mymmm') {
        gtag('js', new Date());

        gtag('config', 'AW-10976039410');

        gtag('event', 'conversion', {
          ...data,
          send_to: 'AW-10976039410/689PCNuSrr0YEPKj5PEo',
          currency: 'USD',
        });
      }

      // This is partners for testing
      if (partner === 'batman' || partner === 'yourclinic') {
        console.log(`✅ Send gtag adwords with payment conversion for ${partner} partner`);
      }
    }

    if (key === 'shortRegistration') {
      if (partner === 'verte') {
        const imageSrc =
          'https://ad.doubleclick.net/ddm/activity/src=12972466;type=conver;cat=verte000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=;gdpr_consent=;ord=1;num=1?';

        gtag('js', new Date());

        gtag('config', 'DC-12972466');

        gtag('event', 'conversion', {
          allow_custom_scripts: true,
          send_to: 'DC-12972466/conver/verte000+unique',
        });

        viewImagePixel(imageSrc);
      }

      // This is partners for testing
      if (partner === 'batman' || partner === 'yourclinic') {
        console.log(`✅ Send gtag adwords with short reg conversion for ${partner} partner`);
      }
    }

    if (key === 'registrationPatientOnIframeScheduling') {
      if (partner === 'greenteamdoctors') {
        gtag('js', new Date());

        gtag('config', 'AW-795767125');

        gtag('event', 'conversion', {
          ...data,
          send_to: 'AW-795767125/wsnfCMW6m5UYENXiufsC',
          currency: 'USD',
        });
      }

      // This is partners for testing
      if (partner === 'batman' || partner === 'yourclinic') {
        console.log(
          `✅ Send gtag adwords with iframe registration patient at conversion for ${partner} partner`,
        );
      }
    }

    if (key === 'bookedAppt') {
      if (partner === 'greenmindphysicians') {
        gtag('js', new Date());

        gtag('config', 'AW-651300844');
        gtag('event', 'conversion', { send_to: 'AW-651300844/7-iOCJzg15oZEOyfyLYC' });
      }

      if (MOBILEIV_PARNTERS.includes(partner)) {
        gtag('js', new Date());

        gtag('config', 'AW-10976039410');

        gtag('event', 'conversion', {
          send_to: 'AW-10976039410/HX4zCLT_oJwYEPKj5PEo',
        });
      }

      if (partner === 'cannacaredocs' || partner === 'relaxed' || partner === 'ccdp') {
        gtag('js', new Date());

        gtag('config', 'AW-581941981');

        gtag('event', 'conversion', {
          send_to: 'AW-581941981/eRsUCPj1h6AYEN31vpUC',
        });
      }

      if (partner === 'fastesa' || partner === 'mymmj' || partner === 'mymmm') {
        gtag('js', new Date());

        gtag('config', 'AW-10976039410');

        gtag('event', 'conversion', {
          ...data,
          send_to: 'AW-10976039410/hF1wCOadrr0YEPKj5PEo',
        });
      }

      // This is partners for testing
      if (partner === 'batman' || partner === 'yourclinic') {
        console.log(`✅ Send gtag adwords with book appt for ${partner} partner`);
      }
    }

    if (key === 'dynamicFormViewed') {
      // This is partners for testing
      if (partner === 'batman' || partner === 'yourclinic') {
        console.log(`✅ Send gtag adwords with view dynamic form for ${partner} partner`);
      }
    }

    if (key === 'dynamicFormSubmitted') {
      // This is partners for testing
      if (partner === 'batman' || partner === 'yourclinic') {
        console.log(`✅ Send gtag adwords with submit dynamic form for ${partner} partner`);
      }
    }

    if (key === 'IDFormViewed') {
      // This is partners for testing
      if (partner === 'batman' || partner === 'yourclinic') {
        console.log(`✅ Send gtag adwords with viewed form widt ID's for ${partner} partner`);
      }
    }

    if (key === 'IDUploaded') {
      // This is partners for testing
      if (partner === 'batman' || partner === 'yourclinic') {
        console.log(`✅ Send gtag adwords with id uploaded for ${partner} partner`);
      }
    }

    if (key === 'paymentFormViewed') {
      // This is partners for testing
      if (partner === 'batman' || partner === 'yourclinic') {
        console.log(`✅ Send gtag adwords with viewed form payment for ${partner} partner`);
      }
    }

    if (key === 'scheduledViewed') {
      // This is partners for testing
      if (partner === 'batman' || partner === 'yourclinic') {
        console.log(`✅ Send gtag adwords with scheduled viewed for ${partner} partner`);
      }
    }
  }
};

export const sendGTAGAnalyticForHeally = (data, key) => {
  const isLocalStage = checkOnLocalStage();

  if (!isLocalStage) {
    const dataEvents = getDataEvents(key);

    const pushData = { ...dataEvents, ...data };

    window.dataLayer?.push(pushData);
  }
};

export const sendOtherAnalyticsAndServicesForPartner = (partner, nameService, data = {}) => {
  if (partner === 'teleleafstates' || partner === 'teleleaf') {
    if (nameService === 'wickedreports') {
      console.log('✅ Enabled wickedreports for teleleaf partner');
      const src = 'https://widget.wickedreports.com/v2/4094/wr-7c0524d33bd1b4108e294be2b82c9f2b.js';
      const script = document.createElement('script');
      script.src = src;
      document.head.appendChild(script);
    }

    if (nameService === 'sitescout') {
      const ssaUrl = `https://clickserv.sitescout.com/conv/81b436762e775b97/${data?.amount}?transactionId=${data?.patientId}`;
      new Image().src = ssaUrl;
      (() => {
        const syncUrl = `https://pixel.sitescout.com/dmp/asyncPixelSync`;
        const iframe = document.createElement('iframe');
        iframe.style.cssText = 'width: 0; height: 0; border: 0;';
        iframe.src = 'javascript:false';
        document.body.appendChild(iframe);
        const doc = iframe.contentWindow.document;
        doc.open().write(`<body onload="window.location.href='${syncUrl}'">`);
        doc.close();
      })(document);
    }

    if (nameService === 'cntrUpTag') {
      console.log(`✅ Pixel for ${partner?.toLowerCase()} enabled`);
      const cntrData = {
        cntr_revenue: data?.amount,
        cntr_transactionId: data?.transaction_id,
      };

      if (window.cntrUpTag) {
        window.cntrUpTag.track(cntrData, 'cc51ad49763c94c5');
      }
    }
  }

  // Add batman partner for testing
  if (partner === 'batman') {
    console.log('✅ Enabled other services for batman partner');
  }
};
