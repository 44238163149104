import { useEffect, useRef } from 'react';
import useLocalVideoToggle from 'hooks/twilio/localVideoToggle.hook';
import useVideoContext from 'hooks/twilio/videoContext.hook';

const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

const AttachVisibilityHandler = () => {
  const { room } = useVideoContext();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const shouldRepublishVideoOnForeground = useRef(false);

  useEffect(() => {
    if (isMobile) {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden' && isVideoEnabled) {
          shouldRepublishVideoOnForeground.current = true;
          toggleVideoEnabled();
        } else if (shouldRepublishVideoOnForeground.current) {
          shouldRepublishVideoOnForeground.current = false;
          toggleVideoEnabled();
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, [isVideoEnabled, room, toggleVideoEnabled]);

  return null;
};

export default AttachVisibilityHandler;
