import React, { Suspense, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';

import VShopScheduleStateProvider from 'state/patient/vshopSchedule';
import SchedulingGuestProvider from 'state/scheduling/guest';
import PatientScheduleApptsProvider from 'state/patient/scheduleAppts';
import VisitProvider from 'state/visit';
import PatientProvider from 'state/patient';
import PatientProfileCouponProvider from 'state/patient/profile/coupon';
import PaymentProvider from 'state/patient/payment';
import MarketingProvider from 'state/marketing';

import { usePartnerConfigsState } from 'state/partnerConfigs';

import RippleLoader from 'components/Preloader/RippleLoader';
import { ErrorBoundaryCmp } from 'components/ErrorBoundary';

import AuthHeader from 'containers/Patient/PatientMainLayout/auth';

import LangContext from 'state/language';

import { lazyWithRetry } from 'utils/rendering';

const VShopSchedule = lazyWithRetry(() => import('pages/Patient/VShopSchedule'));
const VShopBookAppt = lazyWithRetry(() => import('pages/Patient/VShop/BookAppt'));
const VShopScheduleConfirmation = lazyWithRetry(() =>
  import('pages/Patient/VShopSchedule/Activate'),
);
const VShopIframeTest = lazyWithRetry(() => import('pages/Patient/VShop/IframeTest'));

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  margin: 0rem;
  position: relative;
  height: 100%;
  @media only screen and (max-width: 649px) {
    min-height: 460px;
    margin: 0.5rem 0rem 1.5rem 0rem;
    align-items: initial;
    flex: auto;
  }
`;

const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.45);
  transition: all 0.3s ease-in-out;
  z-index: 82;
`;

const PatientAuthRoutes = ({ dispatch }) => {
  const userScope = 'patient_admin';
  const { partnerConfigs } = usePartnerConfigsState();

  const { loadingLang, lang, changeLanguage } = useContext(LangContext);

  if (loadingLang) {
    return (
      <CenterContainer>
        <RippleLoader color="var(--primaryColor)" />
      </CenterContainer>
    );
  }

  return (
    <>
      <AuthHeader
        partnerCropProfile={partnerConfigs}
        lang={lang}
        handleChangeLanguage={value => changeLanguage(value, userScope)}
        disableLink
      />
      <VShopScheduleStateProvider>
        <Main>
          <Suspense
            fallback={
              <CenterContainer>
                <RippleLoader color="var(--primaryColor)" />
              </CenterContainer>
            }
          >
            <Switch>
              <Route
                exact
                path="/f/vshop-schedule"
                render={() => (
                  <ErrorBoundaryCmp>
                    <PatientScheduleApptsProvider>
                      <SchedulingGuestProvider>
                        <PaymentProvider>
                          <PatientProvider>
                            <PatientProfileCouponProvider>
                              <VisitProvider>
                                <MarketingProvider>
                                  <VShopBookAppt />
                                </MarketingProvider>
                              </VisitProvider>
                            </PatientProfileCouponProvider>
                          </PatientProvider>
                        </PaymentProvider>
                      </SchedulingGuestProvider>
                    </PatientScheduleApptsProvider>
                  </ErrorBoundaryCmp>
                )}
              />
              <Route
                exact
                path="/f/vshop-book_appt"
                render={() => (
                  <ErrorBoundaryCmp>
                    <PatientScheduleApptsProvider>
                      <SchedulingGuestProvider>
                        <PaymentProvider>
                          <PatientProvider>
                            <PatientProfileCouponProvider>
                              <VisitProvider>
                                <MarketingProvider>
                                  <VShopBookAppt />
                                </MarketingProvider>
                              </VisitProvider>
                            </PatientProfileCouponProvider>
                          </PatientProvider>
                        </PaymentProvider>
                      </SchedulingGuestProvider>
                    </PatientScheduleApptsProvider>
                  </ErrorBoundaryCmp>
                )}
              />
              <Route
                exact
                path="/f/vshop-iframe_test"
                render={() => (
                  <ErrorBoundaryCmp>
                    <VShopIframeTest />
                  </ErrorBoundaryCmp>
                )}
              />
              <Route
                exact
                path="/f/vshop-schedule/confirmation"
                render={() => (
                  <ErrorBoundaryCmp>
                    <VShopScheduleConfirmation />
                  </ErrorBoundaryCmp>
                )}
              />
            </Switch>
          </Suspense>
        </Main>
      </VShopScheduleStateProvider>
    </>
  );
};

const mapStateToProps = state => ({
  partner: state?.partner,
  auth: state?.auth,
});

export default connect(mapStateToProps)(PatientAuthRoutes);
