import React from 'react';

export default function SvgComponent({ width, height, style }) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      fill="none"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.663 7l5.2-5.2a.469.469 0 10-.663-.663L7 6.337l-5.2-5.2a.469.469 0 10-.663.663l5.2 5.2-5.2 5.2a.469.469 0 10.663.663l5.2-5.2 5.2 5.2a.467.467 0 00.663 0 .469.469 0 000-.663L7.663 7z"
        fill="#ED673C"
        stroke="#ED673C"
        strokeWidth=".2"
      />
    </svg>
  );
}
