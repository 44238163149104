import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';

import { ActionTypes, STATUS } from '../constants/partner';
import { parseError } from 'modules/client';

export const partnerState = {
  cropProfile: {
    data: {},
    status: STATUS.IDLE,
    code: 204,
    message: {
      title: '',
      description: '',
    },
  },
};

export default {
  partner: handleActions(
    {
      [ActionTypes.FETCH_PARTNER_PROFILE_CROPPED]: (state, _) =>
        immutable(state, {
          cropProfile: {
            status: { $set: STATUS.RUNNING },
            data: { $set: {} },
            code: { $set: 204 },
            message: {
              title: { $set: '' },
              description: { $set: '' },
            },
          },
        }),
      [ActionTypes.FETCH_PARTNER_PROFILE_CROPPED_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          cropProfile: {
            status: { $set: STATUS.READY },
            data: { $set: payload },
            code: { $set: 200 },
          },
        }),
      [ActionTypes.FETCH_PARTNER_PROFILE_CROPPED_FAIL]: (state, { payload }) =>
        immutable(state, {
          cropProfile: {
            status: { $set: STATUS.ERROR },
            data: { $set: {} },
            code: { $set: payload?.status },
            message: {
              title: { $set: 'Error' },
              description: { $set: parseError(payload?.response?.error) },
            },
          },
        }),
    },
    partnerState,
  ),
};
