import immutable from 'immutability-helper';
import { parseError, setPaymentMethod } from 'modules/client';
import { handleActions } from 'redux-actions';

import { ActionTypes, STATUS } from '../constants';

export const orderState = {
  initial: {
    data: {},
    primaryPaymentMethod: null,
    status: STATUS.IDLE,
    header: '',
    message: '',
    code: 201,
  },
  coupon: {
    data: {},
    status: STATUS.IDLE,
    header: '',
    message: '',
  },
  card: {
    data: {
      cardCreate: {
        data: {},
        status: STATUS.IDLE,
      },
      cardToken: {
        token: null,
        status: STATUS.IDLE,
      },
      cardDetails: {
        details: null,
        status: STATUS.IDLE,
      },
      cardDestroy: {
        status: STATUS.IDLE,
      },
      purchase: {
        data: {},
        status: STATUS.IDLE,
      },
    },
    status: STATUS.IDLE,
    header: '',
    message: '',
  },
};

export default {
  order: handleActions(
    {
      [ActionTypes.INITIAL_SETUP]: (state, { payload }) => {
        return immutable(state, {
          initial: {
            data: { $set: payload },
            header: { $set: '' },
            status: { $set: STATUS.RUNNING },
            message: { $set: '' },
          },
          coupon: {
            status: { $set: STATUS.IDLE },
          },
          card: {
            data: {
              cardCreate: {
                status: { $set: STATUS.IDLE },
              },
              cardDetails: {
                status: { $set: STATUS.IDLE },
              },
            },
          },
        });
      },
      [ActionTypes.INITIAL_SETUP_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          initial: {
            data: { $set: payload.data.attributes || [] },
            primaryPaymentMethod: {
              $set: setPaymentMethod(
                payload.query,
                payload.data.attributes.allowed_payment_methods,
                payload.data.attributes.skip_online_payment,
              ),
            },
            status: { $set: STATUS.READY },
          },
        }),
      [ActionTypes.INITIAL_SETUP_FAIL]: (state, { payload }) =>
        immutable(state, {
          initial: {
            header: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload.response && payload.response.error) },
          },
        }),

      [ActionTypes.APPLY_DISCOUNT_CODE]: (state, { payload }) =>
        immutable(state, {
          coupon: {
            status: { $set: STATUS.RUNNING },
            header: { $set: '' },
            message: { $set: '' },
          },
        }),
      [ActionTypes.APPLY_DISCOUNT_CODE_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          coupon: {
            data: { $set: payload.data.attributes || [] },
            status: { $set: STATUS.READY },
          },
          initial: {
            data: {
              visit_price_without_coupon: {
                $set: payload.data.attributes.visit_price_without_coupon,
              },
              visit_price: { $set: payload.data.attributes.visit_price },
              last_coupon: { $set: payload.data.attributes.last_coupon },
            },
          },
        }),
      [ActionTypes.APPLY_DISCOUNT_CODE_FAIL]: (state, { payload }) =>
        immutable(state, {
          coupon: {
            data: { $set: {} },
            header: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload.response && payload.response.error) },
          },
        }),

      [ActionTypes.FETCH_CREDIT_CARD_TOKEN]: (state, { payload }) => {
        return immutable(state, {
          card: {
            data: {
              cardToken: {
                token: { $set: null },
                status: { $set: STATUS.RUNNING },
              },
            },
            status: { $set: STATUS.IDLE },
            header: { $set: '' },
            message: { $set: '' },
          },
        });
      },
      [ActionTypes.FETCH_CREDIT_CARD_TOKEN_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardToken: {
                token: { $set: payload.data.attributes.client_token },
                status: { $set: STATUS.READY },
              },
            },
          },
        }),
      [ActionTypes.FETCH_CREDIT_CARD_TOKEN_FAIL]: (state, { payload }) =>
        immutable(state, {
          card: {
            header: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload.response && payload.response.error) },
          },
        }),

      [ActionTypes.FETCH_CREDIT_CARD_DETAILS]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardDetails: {
                details: { $set: null },
                status: { $set: STATUS.RUNNING },
              },
            },
          },
        }),
      [ActionTypes.FETCH_CREDIT_CARD_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardDetails: {
                details: { $set: payload.data.attributes },
                status: { $set: STATUS.READY },
              },
            },
          },
        }),
      [ActionTypes.FETCH_CREDIT_CARD_DETAILS_FAIL]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardDetails: {
                details: { $set: null },
                status: { $set: STATUS.IDLE },
              },
            },
            header: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload.response && payload.response.error) },
          },
        }),

      [ActionTypes.DESTROY_CREDIT_CARD]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardDestroy: {
                status: { $set: STATUS.RUNNING },
              },
            },
          },
        }),
      [ActionTypes.DESTROY_CREDIT_CARD_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardDetails: {
                details: { $set: null },
                status: { $set: STATUS.IDLE },
              },
              cardDestroy: {
                status: { $set: STATUS.READY },
              },
            },
          },
        }),
      [ActionTypes.DESTROY_CREDIT_CARD_FAIL]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardDetails: {
                status: { $set: STATUS.IDLE },
              },
            },
            header: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload.response && payload.response.error) },
          },
        }),

      [ActionTypes.SET_PAYMENT_METHOD]: (state, { payload }) =>
        immutable(state, {
          initial: {
            primaryPaymentMethod: { $set: payload.value },
          },
        }),

      [ActionTypes.CREATE_CREDIT_CARD]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardCreate: {
                status: { $set: STATUS.RUNNING },
              },
              cardDestroy: {
                status: { $set: STATUS.IDLE },
              },
            },
            header: { $set: '' },
            status: { $set: STATUS.IDLE },
            message: { $set: '' },
          },
        }),

      [ActionTypes.CREATE_CREDIT_CARD_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardCreate: {
                data: { $set: payload.data.attributes },
                status: { $set: STATUS.READY },
              },
            },
          },
        }),
      [ActionTypes.CREATE_CREDIT_CARD_FAIL]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardCreate: {
                status: { $set: STATUS.ERROR },
              },
            },
            header: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload.response && payload.response.error) },
          },
        }),

      [ActionTypes.DELETE_LAST_COUPON]: (state, { payload }) =>
        immutable(state, {
          initial: {
            data: {
              last_coupon: { $set: null },
            },
          },
        }),

      [ActionTypes.CREATE_PURCHASE]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              purchase: {
                status: { $set: STATUS.RUNNING },
              },
            },
          },
        }),

      [ActionTypes.CREATE_PURCHASE_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              purchase: {
                status: { $set: STATUS.READY },
                data: { $set: payload.data.attributes },
              },
              cardDestroy: {
                status: { $set: STATUS.IDLE },
              },
            },
          },
        }),

      [ActionTypes.CREATE_PURCHASE_FAIL]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              purchase: {
                data: { $set: {} },
                status: { $set: STATUS.ERROR },
              },
            },
            header: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload.response && payload.response.error) },
          },
        }),

      [ActionTypes.CREATE_CREDIT_CARD_AND_PURCHASE]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardCreate: {
                status: { $set: STATUS.RUNNING },
              },
              purchase: {
                status: { $set: STATUS.RUNNING },
              },
            },
          },
        }),

      [ActionTypes.CREATE_CREDIT_CARD_AND_PURCHASE_FAIL]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardCreate: {
                data: {},
                status: STATUS.IDLE,
              },
              purchase: {
                data: {},
                status: STATUS.IDLE,
              },
            },
            header: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload.response && payload.response.error) },
          },
        }),

      [ActionTypes.DESTROY_CREDIT_CARD_AND_FETCH_NEW_TOKEN]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardDestroy: {
                status: { $set: STATUS.RUNNING },
              },
              cardToken: {
                token: { $set: null },
                status: { $set: STATUS.RUNNING },
              },
            },
          },
        }),

      [ActionTypes.DESTROY_CREDIT_CARD_AND_FETCH_NEW_TOKEN_FAIL]: (state, { payload }) =>
        immutable(state, {
          card: {
            data: {
              cardDestroy: {
                status: { $set: STATUS.IDLE },
              },
              cardToken: {
                token: { $set: null },
                status: { $set: STATUS.IDLE },
              },
            },
          },
          header: { $set: parseError(payload.message) },
          status: { $set: STATUS.ERROR },
          message: { $set: parseError(payload.response && payload.response.error) },
        }),

      [ActionTypes.UPDATE_PATIENT_PERSONAL_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          initial: {
            data: {
              visit_price_without_coupon: {
                $set: payload.data.attributes.visit_price_without_coupon,
              },
              visit_price: { $set: payload.data.attributes.visit_price },
              last_coupon: { $set: payload.data.attributes.last_coupon },
            },
            status: { $set: STATUS.SUCCESS },
            code: { $set: 200 },
            message: { $set: '' },
          },
        }),

      [ActionTypes.UPDATE_PATIENT_PERSONAL_FAIL]: (state, { payload }) =>
        immutable(state, {
          initial: {
            data: {
              visit_price_without_coupon: { $set: '' },
              visit_price: { $set: '' },
              last_coupon: { $set: '' },
            },
            status: { $set: STATUS.ERROR },
            code: { $set: payload?.status },
            message: { $set: payload?.response?.errors },
          },
        }),
      [ActionTypes.RESET_ORDER_STATE]: (state, _) =>
        immutable(state, {
          card: {
            data: {
              cardCreate: {
                status: { $set: STATUS.IDLE },
              },
            },
          },
        }),
    },
    orderState,
  ),
};
