import keyMirror from 'fbjs/lib/keyMirror';

export const ActionTypes = keyMirror({
  SIGN_UP_PARTNER_PATIENT: undefined,
  SIGN_UP_PARTNER_PATIENT_SUCCESS: undefined,
  SIGN_UP_PARTNER_PATIENT_FAIL: undefined,
  CREATE_MEDICAL_FORM_WITH_PATIENT_DATA: undefined,
  CREATE_MEDICAL_FORM_WITH_PATIENT_DATA_SUCCESS: undefined,
  CREATE_MEDICAL_FORM_WITH_PATIENT_DATA_FAIL: undefined,
});

export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const IGNORE_401_ROUTES = ['/f/patient_admin/document_files/']

export const SIGN_IN_URL = '/patient_admin/patient_users/sign_in?with_redirect_back=1';