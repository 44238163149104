import { SIGN_IN_URL } from 'constants/auth';
import { useMessage } from 'hooks/message.hook';
import Jsona from 'jsona';
import { request } from 'modules/client';
import { createContext, useContext, useState } from 'react';
import { STATUS } from '../../constants';

export const StateContext = createContext(null);

const dataFormatter = new Jsona();

export default function MedicalDocumentProvider({ children }) {
  const DEFAULT_STATE = {
    data: [],
    status: 'idle',
    errors: false,
  };
  const [medicalHistoryQuestions, setMedicalHistoryQuestions] = useState({
    ...DEFAULT_STATE,
    data: {},
  });
  const [medicalHistoryFiles, setMedicalHistoryFiles] = useState(DEFAULT_STATE);

  const [infoUploadDocument, setInfoUploadDocument] = useState({
    status: 'idle',
    errors: false,
  });

  const [documentMeta, setDocumentMeta] = useState({
    ...DEFAULT_STATE,
    data: {},
  });

  const message = useMessage();

  const fetchMedicalHistoryFiles = () => {
    setMedicalHistoryFiles(prevProps => ({ ...prevProps, status: 'running' }));
    request('/v1/patient_admin/medical_history_files')
      .then(({ data }) => {
        setMedicalHistoryFiles({
          data,
          status: 'success',
          errors: false,
        });
      })
      .catch(err =>
        setMedicalHistoryFiles(prevProps => ({
          ...prevProps,
          status: 'failed',
          errors: { ...err },
        })),
      );
  };

  const uploadMedicalDocument = async (documentTitle, document) => {
    setInfoUploadDocument({ status: 'running', errors: false });

    const fileName = documentTitle || `medical_history_file_${Date.now()}`;
    const formData = new FormData();

    formData.append('patient_medical_history_file[document_title]', fileName);
    formData.append('patient_medical_history_file[document]', document, `${fileName}.pdf`);

    return request('/v1/patient_admin/medical_history_files', {
      method: 'POST',
      formData,
    })
      .then(() => setInfoUploadDocument({ status: 'success' }))
      .catch(err => setInfoUploadDocument({ status: 'failed', errors: { ...err } }));
  };

  const fetchMedicalHistoryQuestions = async params => {
    setMedicalHistoryQuestions(prevProps => ({ ...prevProps, status: 'running' }));
    return request(`/api/v2/scheduling/patient/medical_history_questions${params || ''}`)
      .then(response => {
        const data = dataFormatter.deserialize(response);
        setMedicalHistoryQuestions({
          data,
          status: 'success',
          errors: false,
        });
      })
      .catch(error =>
        setMedicalHistoryQuestions(prevProps => ({
          ...prevProps,
          status: 'failed',
          errors: { ...error },
        })),
      );
  };

  const fetchDocumentMeta = documentId => {
    setDocumentMeta(prevProps => ({ ...prevProps, status: 'running' }));
    request('/api/v2/document_flow/patient/document_files/' + documentId)
      .then(({ data }) => {
        setDocumentMeta({
          data,
          status: STATUS.SUCCESS,
          errors: false,
        });
      })
      .catch(err =>
        setDocumentMeta(prevProps => ({
          ...prevProps,
          status: STATUS.ERROR,
          errors: { ...err },
        })),
      );
  };

  const requestDocumentCode = documentId => {
    return request('/api/v2/document_flow/access_codes/send_to_requester', {
      method: 'POST',
      payload: {
        document_type: 'document_file',
        document_uuid: documentId,
        requester: 'patient',
      },
    })
      .then(() => {
        message('SMS code has been sent');
      })
      .catch(err => {
        if (err?.response?.errors?.[0]?.includes('requested_times=>3')) {
          message(
            'There are no attempts left, you will be redirected to log in to download the document.',
            7000,
            () => (window.location.href = SIGN_IN_URL),
          );
        } else throw err;
      });
  };

  const confirmDocumentCode = (documentId, access_code) => {
    return request(
      `/api/v2/document_flow/documents/accessed_by_code/${documentId}?type=document_file&requester=patient&access_code=${access_code}`,
    )
      .then(({ data }) => {
        setDocumentMeta({
          data,
          status: STATUS.SUCCESS,
          errors: false,
        });
      })
      .catch(err =>
        setDocumentMeta(prevProps => ({
          ...prevProps,
          status: STATUS.ERROR,
          errors: { ...err },
        })),
      );
  };

  const contextValue = {
    fetchMedicalHistoryFiles,
    medicalHistoryFiles,
    uploadMedicalDocument,
    infoUploadDocument,
    fetchMedicalHistoryQuestions,
    medicalHistoryQuestions,
    documentMeta,
    fetchDocumentMeta,
    requestDocumentCode,
    confirmDocumentCode,
    setDocumentMeta,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useMedicalDocumentState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useMedicalDocumentState must be used within the AppStateProvider');
  }
  return context;
}
