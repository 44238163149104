import { createActions } from 'redux-actions';
import uuid from 'uuid/v4';

import { ActionTypes } from '../constants';

export { goBack, go, push, replace } from 'modules/history';

export const {
  hideMessage,
  showMessage,
  hideAlert,
  showAlert,
  fetchSessionInformation,
  addPatientSelectAddress,
  fetchEvaluationCountryAndStates,
} = createActions({
  [ActionTypes.SHOW_MESSAGE]: message => message,
  [ActionTypes.HIDE_ALERT]: id => ({ id }),
  [ActionTypes.SHOW_ALERT]: ({ header, content, status, id, icon, delay, position, loading }) => {
    const timeout = status === 'error' ? 0 : 5;

    return {
      id,
      icon,
      header,
      content,
      status,
      position: position || 'top-right',
      timeout: typeof delay === 'number' ? delay : timeout,
      loading,
    };
  },
  [ActionTypes.ADD_PATIENT_SELECT_ADDRESS]: (address) => ({ address }),
  [ActionTypes.FETCH_EVALUATION_COUNTRY_AND_STATES]: () => ({})
});
