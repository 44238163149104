import { all, call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'modules/client';

import { ActionTypes } from '../constants';

const baseUrl = '/v1/patient_admin';

export function* initialSetup({ payload }) {
  try {
    const response = yield call(
      request,
      `${baseUrl}/visit/initial_setup?visit_type=${payload.query}`,
    );

    yield put({
      type: ActionTypes.INITIAL_SETUP_SUCCESS,
      payload: { data: response.data, query: payload.query },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.INITIAL_SETUP_FAIL,
      payload: err,
    });
  }
}

export function* applyDiscounCode({ payload }) {
  try {
    const response = yield call(request, `${baseUrl}/visit/coupon_setup`, {
      method: 'POST',
      payload: {
        coupon_code: payload.value,
      },
    });

    yield put({
      type: ActionTypes.APPLY_DISCOUNT_CODE_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.APPLY_DISCOUNT_CODE_FAIL,
      payload: err,
    });
  }
}

export function* fetchCreditCardToken() {
  try {
    const response = yield call(request, `${baseUrl}/visit/card_token`);
    yield put({
      type: ActionTypes.FETCH_CREDIT_CARD_TOKEN_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_CREDIT_CARD_TOKEN_FAIL,
      payload: err,
    });
  }
}

export function* fetchCreditCardDetails() {
  try {
    const response = yield call(request, `${baseUrl}/visit/card`);

    yield put({
      type: ActionTypes.FETCH_CREDIT_CARD_DETAILS_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_CREDIT_CARD_DETAILS_FAIL,
      payload: err,
    });
  }
}

export function* destroyCreditCard() {
  try {
    const response = yield call(request, `${baseUrl}/visit/card`, {
      method: 'DELETE',
    });

    yield put({
      type: ActionTypes.DESTROY_CREDIT_CARD_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.DESTROY_CREDIT_CARD_FAIL,
      payload: err,
    });
  }
}

export function* createCreditCard({ payload }) {
  try {
    const response = yield call(request, `${baseUrl}/visit/card`, {
      method: 'POST',
      payload
    });
    yield put({
      type: ActionTypes.CREATE_CREDIT_CARD_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.CREATE_CREDIT_CARD_FAIL,
      payload: err,
    });
  }
}

export function* createPurchase() {
  try {
    const response = yield call(request, `${baseUrl}/visit/payment`, {
      method: 'POST',
      payload: {},
    });

    yield put({
      type: ActionTypes.CREATE_PURCHASE_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.CREATE_PURCHASE_FAIL,
      payload: err,
    });
  }
}

export function* createCreditCardAndPurchase({ payload }) {
  try {
    if (!payload.cardDetails) {
      yield createCreditCard({ payload });
    }
    yield createPurchase();
  } catch (err) {
    yield put({
      type: ActionTypes.CREATE_CREDIT_CARD_AND_PURCHASE_FAIL,
      payload: err,
    });
  }
}

export function* destroyCreditCardAndFetchNewToken() {
  try {
    yield destroyCreditCard();
    yield fetchCreditCardToken();
  } catch (err) {
    yield put({
      type: ActionTypes.DESTROY_CREDIT_CARD_AND_FETCH_NEW_TOKEN_FAIL,
      payload: err,
    });
  }
}

export function* updatePatientPersonal({ payload }) {
  const { visitTypeId } = payload;

  try {
    const response = yield call(request, `${baseUrl}/visit/visit_type_setup`, {
      method: 'POST',
      payload: {
        visit_type_id: visitTypeId,
      },
    });

    yield put({
      type: ActionTypes.UPDATE_PATIENT_PERSONAL_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    console.log({ ...err });
    yield put({
      type: ActionTypes.UPDATE_PATIENT_PERSONAL_FAIL,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.INITIAL_SETUP, initialSetup),
    takeLatest(ActionTypes.APPLY_DISCOUNT_CODE, applyDiscounCode),
    takeLatest(ActionTypes.FETCH_CREDIT_CARD_TOKEN, fetchCreditCardToken),
    takeLatest(ActionTypes.FETCH_CREDIT_CARD_DETAILS, fetchCreditCardDetails),
    takeLatest(ActionTypes.DESTROY_CREDIT_CARD, destroyCreditCard),
    takeLatest(ActionTypes.CREATE_CREDIT_CARD, createCreditCard),
    takeLatest(ActionTypes.CREATE_PURCHASE, createPurchase),
    takeLatest(ActionTypes.CREATE_CREDIT_CARD_AND_PURCHASE, createCreditCardAndPurchase),
    takeLatest(
      ActionTypes.DESTROY_CREDIT_CARD_AND_FETCH_NEW_TOKEN,
      destroyCreditCardAndFetchNewToken,
    ),
    takeLatest(ActionTypes.UPDATE_PATIENT_PERSONAL, updatePatientPersonal),
  ]);
}
