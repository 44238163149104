import React from 'react';
import cx from 'classnames';

import constants from 'utils/constants';

const Col = ({
  children,
  className,
  node: C = 'div',
  s,
  m,
  l,
  xl,
  offset,
  push,
  pull,
  ...other
}) => {
  let sizes = { s, m, l, xl };
  let classes = {
    col: true
  };

  constants.SIZES.forEach(size => (classes[size + sizes[size]] = sizes[size]));

  if (offset) {
    offset.split(' ').forEach(offset => (classes['offset-' + offset] = true));
  }

  if (push) {
    push.split(' ').forEach(push => (classes['push-' + push] = true));
  }

  if (pull) {
    pull.split(' ').forEach(pull => (classes['pull-' + pull] = true));
  }

  return (
    <C {...other} className={cx(classes, className)}>
      {children}
    </C>
  );
};

export default Col;