import React, { createContext, useState, useContext } from 'react';

export const StateContext = createContext(null);

export default function PartnerSettingStateProvider({ children }) {
  const [brandColor, setBrandColor] = useState(null);

  const contextValue = {
    brandColor,
    setBrandColor,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function usePartnerSettingState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('usePartnerConfigsState mus be use within PartnerConfigsStateProvider');
  }

  return context;
}
