import { useCallback, useEffect, useRef, useState } from 'react';
import Video, { LocalDataTrack } from 'twilio-video';

const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

window.TwilioVideo = Video;

const useRoom = (localTracks, onError, options) => {
  const [room, setRoom] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsRef = useRef(options);

  useEffect(() => {
    optionsRef.current = options;
  }, [options]);
  const connect = useCallback(
    token => {
      setIsConnecting(true);
      return Video.connect(token, {
        ...optionsRef.current,
        tracks: [...localTracks, new LocalDataTrack()],
      }).then(
        newRoom => {
          setRoom(newRoom);
          const disconnect = () => newRoom.disconnect();

          newRoom.setMaxListeners(22);

          newRoom.once('disconnected', () => {
            setTimeout(() => setRoom(null));
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          window.twilioRoom = newRoom;

          newRoom.localParticipant.videoTracks.forEach(publication =>
            publication.setPriority('low'),
          );

          newRoom.localParticipant.setNetworkQualityConfiguration({
            local: 2,
            remote: 1,
          });

          setIsConnecting(false);

          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            window.addEventListener('pagehide', disconnect);
          }
        },
        error => {
          onError(error);
          setIsConnecting(false);
        },
      );
    },
    [localTracks, onError],
  );

  return { room, isConnecting, connect };
};

export default useRoom;
