import React from 'react';

export default function Discover({ styles, width, height }) {
  return (
    <svg
      style={styles}
      width={width}
      height={height}
      viewBox="0 0 780 501"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M409.412 197.758c30.938 0 56.02 23.58 56.02 52.709v.033c0 29.129-25.082 52.742-56.02 52.742-30.941 0-56.022-23.613-56.022-52.742v-.033c0-29.129 25.081-52.709 56.022-52.709z"
        fill="#F47216"
      />
      <path d="M321.433 198.438c8.836 0 16.247 1.785 25.269 6.09v22.752c-8.544-7.863-15.955-11.154-25.757-11.154-19.265 0-34.413 15.015-34.413 34.051 0 20.074 14.681 34.195 35.368 34.195 9.313 0 16.586-3.12 24.802-10.856v22.764c-9.343 4.141-16.912 5.775-25.757 5.775-31.277 0-55.581-22.597-55.581-51.737-.001-28.828 24.95-51.88 56.069-51.88zM224.32 199.064c11.546 0 22.109 3.721 30.942 10.994l-10.748 13.248c-5.351-5.646-10.411-8.027-16.563-8.027-8.854 0-15.301 4.745-15.301 10.988 0 5.354 3.618 8.188 15.944 12.482 23.364 8.043 30.289 15.176 30.289 30.926 0 19.193-14.976 32.554-36.319 32.554-15.631 0-26.993-5.795-36.457-18.871l13.268-12.031c4.73 8.609 12.622 13.223 22.42 13.223 9.163 0 15.947-5.951 15.947-13.984 0-4.164-2.056-7.733-6.158-10.258-2.066-1.195-6.158-2.977-14.199-5.646-19.292-6.538-25.91-13.527-25.91-27.186-.001-16.226 14.213-28.412 32.845-28.412zM459.043 200.793h22.436l28.084 66.592 28.447-66.592h22.266l-45.493 101.686h-11.054zM157.83 200.945h20.541v99.143H157.83zM569.563 200.945h58.252v16.799H590.09v22.006h36.336v16.791H590.09v26.762h37.725v16.785h-58.252zM685.156 258.322c15.471-2.965 23.984-12.926 23.984-28.105 0-18.562-13.576-29.271-37.266-29.271H641.42v99.143h20.516V260.26h2.68l28.43 39.828h25.26l-33.15-41.766zm-17.218-11.736h-6.002v-30.025h6.326c12.791 0 19.744 5.049 19.744 14.697.002 9.966-6.951 15.328-20.068 15.328zM91.845 200.945H61.696v99.143h29.992c15.946 0 27.465-3.543 37.573-11.445 12.014-9.36 19.117-23.467 19.117-38.057.001-29.259-23.221-49.641-56.533-49.641zm23.997 74.479c-6.454 5.484-14.837 7.879-28.108 7.879H82.22v-65.559h5.513c13.271 0 21.323 2.238 28.108 8.018 7.104 5.956 11.377 15.183 11.377 24.682.001 9.513-4.273 19.024-11.376 24.98z" />
    </svg>
  );
}
