import keyMirror from 'fbjs/lib/keyMirror';

export const ActionTypes = keyMirror({
  FETCH_PARTNER_VISIT_TYPES: undefined,
  FETCH_PARTNER_VISIT_TYPES_SUCCESS: undefined,
  FETCH_PARTNER_VISIT_TYPES_READY: undefined,
  FETCH_PARTNER_VISIT_TYPES_FAIL: undefined,

  FETCH_FORMS_VISIT_TYPES: undefined,
  FETCH_FORMS_VISIT_TYPES_SUCCESS: undefined,
  FETCH_FORMS_VISIT_TYPES_READY: undefined,
  FETCH_FORMS_VISIT_TYPES_FAIL: undefined,

  FETCH_VISIT_TYPES: undefined,
  FETCH_VISIT_TYPES_SUCCESS: undefined,
  FETCH_VISIT_TYPES_FAIL: undefined,
  FETCH_FREE_POSITIONS: undefined,
  FETCH_FREE_POSITIONS_SUCCESS: undefined,
  FETCH_FREE_POSITIONS_FAIL: undefined,
  FETCH_FREE_DAYS: undefined,
  FETCH_FREE_DAYS_SUCCESS: undefined,
  FETCH_FREE_DAYS_FAIL: undefined,
  FETCH_FREE_DOCTORS: undefined,
  FETCH_FREE_DOCTORS_SUCCESS: undefined,
  FETCH_FREE_DOCTORS_FAIL: undefined,
  FETCH_SCHEDULE_VISITS: undefined,
  FETCH_SCHEDULE_VISITS_SUCCESS: undefined,
  FETCH_SCHEDULE_VISITS_FAIL: undefined,
  CREATE_SCHEDULE_VISIT: undefined,
  CREATE_SCHEDULE_VISIT_SUCCESS: undefined,
  CREATE_SCHEDULE_VISIT_FAIL: undefined,
  UPDATE_SCHEDULE_VISIT: undefined,
  UPDATE_SCHEDULE_VISIT_SUCCESS: undefined,
  UPDATE_SCHEDULE_VISIT_FAIL: undefined,
  CANCEL_SCHEDULE_VISIT: undefined,
  CANCEL_SCHEDULE_VISIT_SUCCESS: undefined,
  CANCEL_SCHEDULE_VISIT_FAIL: undefined,
  SET_OPTIONS_FOR_DIRECTION: undefined,
  FETCH_PATIENT_SESSION: undefined,
  FETCH_PATIENT_SESSION_SUCCESS: undefined,
  FETCH_PATIENT_SESSION_FAIL: undefined,
  CREATE_PATIENT_FROM_PARTNER_WITH_PRESET: undefined,
  CREATE_PATIENT_FROM_PARTNER_WITH_PRESET_SUCCESS: undefined,
  CREATE_PATIENT_FROM_PARTNER_WITH_PRESET_FAIL: undefined,
  FETCH_PATIENT_VISITS: undefined,
  FETCH_PATIENT_VISITS_SUCCESS: undefined,
  FETCH_PATIENT_VISITS_FAIL: undefined,
  UPDATE_PATIENT_SESSION: undefined,
  RESET_STATE_FREE_DAYS: undefined,
  RESET_STATE_VISIT: undefined,
  FETCH_PROVIDERS: undefined,
  FETCH_PROVIDERS_SUCCESS: undefined,
  FETCH_PROVIDERS_FAIL: undefined,
  SET_PROVIDER_ON_SCHEDULING: undefined,
  IFRAME_FETCH_PARTNER_VISIT_TYPES: undefined,
  IFRAME_FETCH_PARTNER_VISIT_TYPES_SUCCESS: undefined,
  IFRAME_FETCH_PARTNER_VISIT_TYPES_FAIL: undefined,
  IFRAME_FETCH_FREE_DAYS: undefined,
  IFRAME_FETCH_FREE_DAYS_SUCCESS: undefined,
  IFRAME_FETCH_FREE_DAYS_FAIL: undefined,
  IFRAME_FETCH_FREE_DOCTORS: undefined,
  IFRAME_FETCH_FREE_DOCTORS_SUCCESS: undefined,
  IFRAME_FETCH_FREE_DOCTORS_FAIL: undefined,
  FETCH_PARTNER_VISIT_TYPE: undefined,
  FETCH_PARTNER_VISIT_TYPE_SUCCESS: undefined,
  FETCH_PARTNER_VISIT_TYPE_FAIL: undefined,
  IFRAME_FETCH_PARTNER_VISIT_TYPE: undefined,
});

export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};
