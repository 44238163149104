import keyMirror from 'fbjs/lib/keyMirror';

export const ActionTypes = keyMirror({
  FETCH_PARTNER_PROFILE_CROPPED: undefined,
  FETCH_PARTNER_PROFILE_CROPPED_SUCCESS: undefined,
  FETCH_PARTNER_PROFILE_CROPPED_FAIL: undefined,
});

export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};
