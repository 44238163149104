import React from 'react';
import styled from 'styled-components';

const ClearIcon = styled.svg`
  fill: #0000008a;
  width: 0.8em;
  height: 0.8em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
`;

export default function SvgComponent({ style, width, height }) {
  return (
    <ClearIcon
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </ClearIcon>
  );
}
