import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';

import { ActionTypes, STATUS } from '../constants/auth';
import { parseError } from 'modules/client';

export const authState = {
  patient: {
    signUp: {
      status: STATUS.IDLE,
      data: {},
      meta: {},
      message: {
        title: '',
        description: ''
      }
    }
  },
  partnerPatient: {
    signUp: {
      status: STATUS.IDLE,
      data: {},
      code: 204,
      errors: {
        message: {
          title: '',
          description: '',
        },
        data: {}
      }
    },
    medicalForm: {
      status: STATUS.IDLE,
      data: {},
      meta: {},
      code: 204,
      errors: {
        message: {
          title: '',
          description: '',
        },
        data: {}
      }
    }
  }
}

export default {
  auth: handleActions(
    {
      [ActionTypes.SIGN_UP_PARTNER_PATIENT]: (state, _) =>
        immutable(state, {
          partnerPatient: {
            signUp: {
              status: { $set: STATUS.RUNNING },
              data: { $set: {} },
              meta: { $set: {} },
              code: { $set: 204 },
              errors: {
                message: {
                  title: { $set: '' },
                  description: { $set: '' },
                },
                data: { $set: {} }
              }
            }
          }
        }),
      [ActionTypes.SIGN_UP_PARTNER_PATIENT_SUCCESS]: (state, { payload, meta }) =>
        immutable(state, {
          partnerPatient: {
            signUp: {
              status: { $set: STATUS.READY },
              code: { $set: 200 },
              data: { $set: payload },
              meta: { $set: meta }
            }
          }
        }),
      [ActionTypes.SIGN_UP_PARTNER_PATIENT_FAIL]: (state, { payload }) => {
        if (payload?.code === 422 || payload?.status === 422) {
          return immutable(state, {
            partnerPatient: {
              signUp: {
                status: { $set: STATUS.ERROR },
                code: { $set: payload?.status },
                errors: {
                  message: {
                    title: { $set: '' },
                    description: { $set: '' }
                  },
                  data: { $set: payload?.response?.errors },
                }
              }
            }
          })
        }
        return immutable(state, {
          partnerPatient: {
            signUp: {
              status: { $set: STATUS.ERROR },
              data: { $set: {} },
              code: { $set: payload?.status },
              errors: {
                message: {
                  title: { $set: 'Error' },
                  description: { $set: parseError(payload?.response?.error) }
                },
                data: { $set: {} }
              }
            }
          }
        })
      },

      [ActionTypes.CREATE_MEDICAL_FORM_WITH_PATIENT_DATA]: (state, _) =>
        immutable(state, {
          partnerPatient: {
            medicalForm: {
              status: { $set: STATUS.RUNNING },
              data: { $set: {} },
              meta: { $set: {} },
              code: { $set: 204 },
              errors: {
                message: {
                  title: { $set: '' },
                  description: { $set: '' },
                },
                data: { $set: {} }
              }
            }
          }
        }),
      [ActionTypes.CREATE_MEDICAL_FORM_WITH_PATIENT_DATA_SUCCESS]: (state, { payload, meta }) =>
        immutable(state, {
          partnerPatient: {
            medicalForm: {
              status: { $set: STATUS.READY },
              code: { $set: 200 },
              data: { $set: payload },
              meta: { $set: meta }
            }
          }
        }),
      [ActionTypes.CREATE_MEDICAL_FORM_WITH_PATIENT_DATA_FAIL]: (state, { payload }) => {
        if (payload?.code === 422 || payload?.status === 422) {
          return immutable(state, {
            partnerPatient: {
              medicalForm: {
                status: { $set: STATUS.ERROR },
                code: { $set: payload?.status },
                errors: {
                  message: {
                    title: { $set: '' },
                    description: { $set: '' }
                  },
                  data: { $set: payload?.response?.errors },
                }
              }
            }
          })
        }
        return immutable(state, {
          partnerPatient: {
            medicalForm: {
              status: { $set: STATUS.ERROR },
              data: { $set: {} },
              code: { $set: payload?.status },
              errors: {
                message: {
                  title: { $set: 'Error' },
                  description: { $set: parseError(payload?.response?.error) }
                },
                data: { $set: {} }
              }
            }
          }
        })
      }

    },
    authState
  )
}