import { all, call, put, takeLatest } from 'redux-saga/effects';
import { request, transformErrorsFromApi } from 'modules/client';

import { ActionTypes } from '../constants/auth';

const patientUrl = '/v1/patient_admin';
const partnerUrl = '/v1/partner_admin';

export function* signUpPartnerPatient({ payload }) {
  const { patientUser } = payload;

  try {
    const response = yield call(
      request,
      `${partnerUrl}/cropped/signup/basic`,
      {
        method: 'POST',
        payload: {
          patient_user: patientUser
        }
      }
    )

    yield put({
      type: ActionTypes.SIGN_UP_PARTNER_PATIENT_SUCCESS,
      payload: response?.data,
      meta: response?.meta
    })
  } catch (error) {
    yield put({
      type: ActionTypes.SIGN_UP_PARTNER_PATIENT_FAIL,
      payload: error
    })
  }
}

export function* createMedicalFormWithPatientData({ payload }) {
  const { values, medicalForm, setErrors } = payload;

  try {
    const response = yield call(
      request,
      `${partnerUrl}/cropped/signup/more_info`,
      {
        method: 'POST',
        payload: {
          patient_user: values
        }
      }
    )

    yield put({
      type: ActionTypes.CREATE_MEDICAL_FORM_WITH_PATIENT_DATA_SUCCESS,
      payload: response?.data,
      meta: response.meta,
      medicalForm,
    })
  } catch (error) {
    yield put({
      type: ActionTypes.CREATE_MEDICAL_FORM_WITH_PATIENT_DATA_FAIL,
      payload: error
    });

    if (error?.status === 422) {
      setErrors(transformErrorsFromApi(error));
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.SIGN_UP_PARTNER_PATIENT, signUpPartnerPatient),
    takeLatest(ActionTypes.CREATE_MEDICAL_FORM_WITH_PATIENT_DATA, createMedicalFormWithPatientData),
  ])
}