import base from './base';
import patient from './patient';
import order from './order';
import auth from './auth';
import partner from './partner';
import patientIdCheck from 'pages/Patient/UploadId/American/PatientIdCheckReducer';

export default {
  ...base,
  ...order,
  ...patient,
  ...auth,
  ...partner,
  ...patientIdCheck,
};
