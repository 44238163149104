import React, { useState, createContext, useContext } from 'react';
import moment from 'moment-timezone';

import { request, compileQuery, encodeQueryData, parseError } from 'modules/client';
import { dataFormatter } from 'utils/connectPartnerConfigs';
import { isEmpty } from 'modules/validationFields';

export const StateContext = createContext(null);

const newBaseUrl = '/api/v2';

export default function SchedulingAvailableTimesProvider({ children }) {
  const DEFAULT_STATE = {
    data: [],
    status: 'idle',
    message: null,
  };
  const [freeDays, setFreeDays] = useState({
    ...DEFAULT_STATE,
    groupedByDays: [],
    preSelectDate: null,
  });
  const [doctors, setDoctors] = useState(DEFAULT_STATE);

  const groupByDays = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {});
  const groupByTZDays = (data, timezone) =>
    data.reduce((acc, obj) => {
      // Convert time string to moment object with the correct timezone
      const date = moment.tz(obj.time, timezone).format('MM-DD-YYYY'); // Adjust timezone if needed

      // Initialize the array if this date hasn't been seen before
      if (!acc[date]) {
        acc[date] = [];
      }

      // Add the object to the array for this date
      acc[date].push(obj);

      return acc;
    }, {});

  const getPreSelectDate = (currentSelectDate, queryStartDate, firstAvailableDate, timezone) => {
    const momentCurrentSelectDate = timezone
      ? moment.tz(currentSelectDate, timezone)
      : moment(currentSelectDate);

    const momentQueryStartDate = timezone
      ? moment.tz(queryStartDate, timezone)
      : moment(queryStartDate);

    const momentToday = timezone ? moment.tz(timezone) : moment();

    if (currentSelectDate) return momentCurrentSelectDate.format('MM-DD-YYYY');
    if (queryStartDate) {
      // Duplicate set first available date. Because calendar return first date if switch months
      if (firstAvailableDate) return firstAvailableDate;
      if (momentQueryStartDate.isSame(moment(), 'month')) return momentToday.format('MM-DD-YYYY');
      return momentQueryStartDate.format('MM-DD-YYYY');
    }
    if (firstAvailableDate) return firstAvailableDate;
    return momentToday.format('MM-DD-YYYY');
  };

  const fetchFreeDays = async (query, currentSelectDate, formatByTZ) => {
    const params = query ? `?${compileQuery(query)}` : '';

    setFreeDays(prevState => ({ ...prevState, status: 'running', message: null }));
    return request(`${newBaseUrl}/scheduling/patient/available_times${params}`)
      .then(({ data }) => {
        const groupedByDays = formatByTZ
          ? groupByTZDays(data, formatByTZ)
          : groupByDays(data, v => moment(v?.time).format('MM-DD-YYYY'));
        const firstAvailableDate = !isEmpty(groupedByDays) ? Object.keys(groupedByDays)[0] : null;
        const queryStartDate = query?.start_date;

        const preSelectDate = getPreSelectDate(
          currentSelectDate,
          queryStartDate,
          firstAvailableDate,
          formatByTZ,
        );

        setFreeDays({
          data,
          groupedByDays,
          preSelectDate,
          status: 'ready',
          message: null,
        });
      })
      .catch(err => {
        setFreeDays({
          data: [],
          groupedByDays: [],
          preSelectDate: null,
          status: 'error',
          message: parseError(err?.response?.error),
        });
      });
  };

  const fetchAvailableDoctors = async (query = '') => {
    const params = query ? `?${encodeQueryData(query)}` : '';

    setDoctors(prevState => ({ ...prevState, status: 'running', message: null }));
    return request(`${newBaseUrl}/scheduling/patient/available_times/doctors${params}`)
      .then(response => {
        const data = dataFormatter.deserialize(response);
        const sortedDataByLastName = data?.sort((a, b) =>
          a?.last_name?.localeCompare(b?.last_name),
        );

        setDoctors(prevState => ({ ...prevState, data: sortedDataByLastName, status: 'ready' }));
      })
      .catch(err =>
        setDoctors({ data: [], status: 'error', message: parseError(err?.response?.error) }),
      );
  };

  const setDefaultFreeDaysState = () =>
    setFreeDays({ ...DEFAULT_STATE, groupedByDays: [], preSelectDate: null });

  const setDefaultAvailableDoctors = () => setDoctors(DEFAULT_STATE);

  const contextValue = {
    freeDays,
    doctors,
    fetchFreeDays,
    fetchAvailableDoctors,
    setDefaultFreeDaysState,
    setDefaultAvailableDoctors,
  };

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useSchedulingAvailableTimes() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error(
      'useSchedulingAvailableTimes must be used with SchedulingAvailableTimesProvider',
    );
  }
  return context;
}
