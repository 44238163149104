import React, { Suspense, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import RippleLoader from 'components/Preloader/RippleLoader';
import { ErrorBoundaryCmp } from 'components/ErrorBoundary';

import { lazyWithRetry } from 'utils/rendering';

import LangContext from 'state/language';
import PaymentProvider from 'state/patient/payment';

const SezzleSuccess = lazyWithRetry(() =>
  import('pages/Patient/Integration/Sezzle/components/Complete'),
);
const SezzleCancel = lazyWithRetry(() =>
  import('pages/Patient/Integration/Sezzle/components/Cancel'),
);

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  margin: 3.5rem 0rem 0rem 0rem;
  position: relative;
  height: 100%;
  @media only screen and (max-width: 649px) {
    min-height: 460px;
    margin: 2.5rem 0rem 1.5rem 0rem;
    align-items: initial;
    flex: auto;
  }
`;

const IntegrationsRoutes = () => {
  const {
    currentLangData: { main_layout },
  } = useContext(LangContext);

  const renderIntegrationPaymentContent = ({ component: Component }) => {
    return (
      <ErrorBoundaryCmp>
        <PaymentProvider>
          <Main>
            <Component />
          </Main>
        </PaymentProvider>
      </ErrorBoundaryCmp>
    );
  };

  return (
    <>
      <Suspense fallback={<RippleLoader color="var(--primaryColor)" />}>
        <Switch>
          <Route
            path="/f/patient_admin/payments/callback/sezzle/complete"
            render={() => renderIntegrationPaymentContent({ component: SezzleSuccess })}
          />
          <Route
            path="/f/patient_admin/payments/callback/sezzle/cancel"
            render={() => renderIntegrationPaymentContent({ component: SezzleCancel })}
          />
        </Switch>
      </Suspense>
    </>
  );
};

export default IntegrationsRoutes;
