import Jsona from 'jsona';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { compileQuery, request, encodeQueryData } from 'modules/client';

import { ActionTypes } from '../constants/patient';

const dataFormatter = new Jsona();
const baseUrl = '/v1/patient_admin';
const newBaseUrl = '/api/v2';

const createListWithVisitTypesByCategory = (payload, dataFormat) => {
  const uniqueId = Date.now();
  const listCategories = dataFormatter.deserialize({
    data: payload.included.filter(obj => obj.type === 'category'),
  });

  const listVisitTypes = listCategories.map(value => ({
    ...value,
    listVisitTypes: dataFormat.filter(item => item.categories.find(el => el.id === value.id)),
  }));

  const listVisitTypesWithOtherCategory = dataFormat.filter(item => item?.categories?.length === 0);

  const otherCategory = {
    type: 'category',
    id: uniqueId,
    name: 'Other',
    listVisitTypes: listVisitTypesWithOtherCategory,
  };

  if (listVisitTypesWithOtherCategory?.length > 0) return [...listVisitTypes, otherCategory];

  return listVisitTypes;
};

export function* fetchPartnerVisitTypes({ payload }) {
  const { params } = payload;
  const hasCategoriesParam = params.indexOf('categories') >= 0;

  try {
    const response = yield call(request, `${newBaseUrl}/scheduling/patient/visit_types${params}`);
    const data = dataFormatter.deserialize(response);

    const listVisitTypes = hasCategoriesParam
      ? createListWithVisitTypesByCategory(response, data)
      : data;

    yield put({
      type: ActionTypes.FETCH_PARTNER_VISIT_TYPES_SUCCESS,
      payload: { data: listVisitTypes },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_PARTNER_VISIT_TYPES_FAIL,
      payload: err,
    });
  }
}

export function* iframeFetchPartnerVisitTypes({ payload }) {
  const { params } = payload;
  try {
    const response = yield call(request, `${newBaseUrl}/scheduling/guest/visit_types${params}`);
    const data = dataFormatter.deserialize(response);

    const listVisitTypesByCategory = createListWithVisitTypesByCategory(response, data);

    yield put({
      type: ActionTypes.FETCH_PARTNER_VISIT_TYPES_SUCCESS,
      payload: { data: listVisitTypesByCategory },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_PARTNER_VISIT_TYPES_FAIL,
      payload: err,
    });
  }
}

export function* fetchVisitTypes() {
  try {
    const response = yield call(request, `${baseUrl}/schedule_visits/get_visit_types`);

    yield put({
      type: ActionTypes.FETCH_VISIT_TYPES_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_VISIT_TYPES_FAIL,
      payload: err,
    });
  }
}

export function* fetchFormsVisitTypes() {
  try {
    const response = yield call(request, `${newBaseUrl}/visit_flow/patient/visit_types`);

    yield put({
      type: ActionTypes.FETCH_FORMS_VISIT_TYPES_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_FORMS_VISIT_TYPES_FAIL,
      payload: err,
    });
  }
}

export function* fetchFreePositions({ payload }) {
  const { address_id, visit_type_id, day, timeZone } = payload;

  try {
    const response = yield call(
      request,
      `${newBaseUrl}/scheduling/patient/available_times?visit_type_id=${visit_type_id}&time_zone=${timeZone}`,
    );

    yield put({
      type: ActionTypes.FETCH_FREE_POSITIONS_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_FREE_POSITIONS_FAIL,
      payload: err,
    });
  }
}

export function* fetchFreeDoctors({ payload }) {
  const {
    start_date,
    time,
    partner_address_id,
    schedule_visit_type_id,
    appointment_type,
    schedule_visit_id,
    doctor_user_ids,
  } = payload;

  try {
    const scheduleVisitId = schedule_visit_id ? { schedule_visit_id } : {};

    const query = encodeQueryData({
      schedule_visit_type_id,
      partner_address_id,
      start_date,
      appointment_type,
      doctor_user_ids,
      ...scheduleVisitId,
    });

    const params = `${newBaseUrl}/scheduling/patient/available_times/doctors?${query}`;

    const response = yield call(request, params);

    yield put({
      type: ActionTypes.FETCH_FREE_DOCTORS_SUCCESS,
      payload: { data: response.data, time },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_FREE_DOCTORS_FAIL,
      payload: err,
    });
  }
}

export function* iframeFetchFreeDoctors({ payload }) {
  const {
    start_date,
    time,
    partner_address_id,
    schedule_visit_type_id,
    appointment_type,
    partner_user_id,
    evaluationState,
    doctor_user_ids,
  } = payload;

  const query = encodeQueryData({
    schedule_visit_type_id,
    partner_address_id,
    start_date,
    appointment_type,
    partner_user_id,
    evaluation_state: evaluationState,
    doctor_user_ids,
  });

  try {
    const response = yield call(
      request,
      `${newBaseUrl}/scheduling/guest/available_times/doctors?${query}`,
    );

    const data = dataFormatter.deserialize(response);

    yield put({
      type: ActionTypes.FETCH_FREE_DOCTORS_SUCCESS,
      payload: { data, time },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_FREE_DOCTORS_FAIL,
      payload: err,
    });
  }
}

export function* fetchScheduleVisits({ payload }) {
  const { params } = payload;
  try {
    const response = yield call(request, `${newBaseUrl}/scheduling/patient/visits${params}`);

    const data = dataFormatter.deserialize(response);

    const dates = Array.from(new Set(data.map(value => value.start_date)));
    const visitInformation = dates.map(date => ({
      date_visit: date,
      events: [],
    }));

    data.forEach(value => {
      visitInformation.forEach((visit, index) => {
        if (visit.date_visit === value.start_date) {
          visit.events.push({ ...value });
        }
      });
    });

    yield put({
      type: ActionTypes.FETCH_SCHEDULE_VISITS_SUCCESS,
      payload: { data: response.data, visitInformation: visitInformation },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_SCHEDULE_VISITS_FAIL,
      payload: err,
    });
  }
}

export function* createScheduleVisit({ payload }) {
  const { schedule_visit } = payload;

  try {
    const response = yield call(request, `${newBaseUrl}/scheduling/patient/visits`, {
      method: 'POST',
      payload: {
        schedule_visit,
      },
    });

    yield put({
      type: ActionTypes.CREATE_SCHEDULE_VISIT_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.CREATE_SCHEDULE_VISIT_FAIL,
      payload: err,
    });
  }
}

export function* updateScheduleVisit({ payload }) {
  const { id, schedule_visit } = payload;

  try {
    const response = yield call(request, `${newBaseUrl}/scheduling/patient/visits/${id}`, {
      method: 'PUT',
      payload: {
        schedule_visit,
      },
    });

    yield put({
      type: ActionTypes.UPDATE_SCHEDULE_VISIT_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.UPDATE_SCHEDULE_VISIT_FAIL,
      payload: err,
    });
  }
}

export function* cancelScheduleVisit({ payload }) {
  const { id } = payload;

  try {
    const response = yield call(request, `${newBaseUrl}/scheduling/patient/visits/${id}`, {
      method: 'DELETE',
    });

    yield put({
      type: ActionTypes.CANCEL_SCHEDULE_VISIT_SUCCESS,
      payload: { id },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.CANCEL_SCHEDULE_VISIT_FAIL,
      payload: err,
    });
  }
}

export function* fetchPatientSession({ payload }) {
  const { query } = payload;
  try {
    const response = yield call(request, `/v1/patient_admin/session${query}`);

    yield put({
      type: ActionTypes.FETCH_PATIENT_SESSION_SUCCESS,
      payload: dataFormatter.deserialize(response),
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_PATIENT_SESSION_FAIL,
      payload: err,
    });
  }
}

export function* createPatientFromPartnerWithPreset({ payload }) {
  const { preset, partner } = payload;

  try {
    const response = yield call(request, '/v1/patient_admin/short_registration', {
      method: 'POST',
      payload: {
        preset,
        partner,
      },
    });

    yield put({
      type: ActionTypes.CREATE_PATIENT_FROM_PARTNER_WITH_PRESET_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.CREATE_PATIENT_FROM_PARTNER_WITH_PRESET_FAIL,
      payload: err,
    });
  }
}

export function* fetchPatientVisits({ payload }) {
  const { query = '' } = payload;
  try {
    const response = yield call(request, `/v1/patient_admin/visits${query}`);

    yield put({
      type: ActionTypes.FETCH_PATIENT_VISITS_SUCCESS,
      payload: dataFormatter.deserialize(response),
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_PATIENT_VISITS_FAIL,
      payload: err,
    });
  }
}

export function* fetchProviders({ payload }) {
  const { params } = payload;

  try {
    const response = yield call(request, `/api/v2/scheduling/patient/doctors${params}`);

    yield put({
      type: ActionTypes.FETCH_PROVIDERS_SUCCESS,
      payload: response?.data,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_PROVIDERS_FAIL,
      payload: err,
    });
  }
}

export function* fetchPartnerVisitType({ payload }) {
  const { id, params } = payload;

  try {
    const response = yield call(
      request,
      `${newBaseUrl}/scheduling/patient/visit_types/${id}${params}`,
    );
    const data = dataFormatter.deserialize(response);
    const coverToArray = [{ ...data }];

    const listVisitTypesByCategory = createListWithVisitTypesByCategory(response, coverToArray);

    yield put({
      type: ActionTypes.FETCH_PARTNER_VISIT_TYPE_SUCCESS,
      payload: { data: listVisitTypesByCategory },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_PARTNER_VISIT_TYPE_FAIL,
      payload: err,
    });
  }
}

export function* iframeFetchPartnerVisitType({ payload }) {
  const { id, params } = payload;

  try {
    const response = yield call(
      request,
      `${newBaseUrl}/scheduling/guest/visit_types/${id}${params}`,
    );
    const data = dataFormatter.deserialize(response);
    const coverToArray = [{ ...data }];

    const listVisitTypesByCategory = createListWithVisitTypesByCategory(response, coverToArray);

    yield put({
      type: ActionTypes.FETCH_PARTNER_VISIT_TYPE_SUCCESS,
      payload: { data: listVisitTypesByCategory },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_PARTNER_VISIT_TYPE_FAIL,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.FETCH_PARTNER_VISIT_TYPES, fetchPartnerVisitTypes),
    takeLatest(ActionTypes.FETCH_VISIT_TYPES, fetchVisitTypes),
    takeLatest(ActionTypes.FETCH_FORMS_VISIT_TYPES, fetchFormsVisitTypes),
    takeLatest(ActionTypes.FETCH_FREE_POSITIONS, fetchFreePositions),
    takeLatest(ActionTypes.FETCH_FREE_DOCTORS, fetchFreeDoctors),
    takeLatest(ActionTypes.FETCH_SCHEDULE_VISITS, fetchScheduleVisits),
    takeLatest(ActionTypes.CREATE_SCHEDULE_VISIT, createScheduleVisit),
    takeLatest(ActionTypes.UPDATE_SCHEDULE_VISIT, updateScheduleVisit),
    takeLatest(ActionTypes.CANCEL_SCHEDULE_VISIT, cancelScheduleVisit),
    takeLatest(ActionTypes.FETCH_PATIENT_SESSION, fetchPatientSession),
    takeLatest(
      ActionTypes.CREATE_PATIENT_FROM_PARTNER_WITH_PRESET,
      createPatientFromPartnerWithPreset,
    ),
    takeLatest(ActionTypes.FETCH_PATIENT_VISITS, fetchPatientVisits),
    takeLatest(ActionTypes.FETCH_PROVIDERS, fetchProviders),
    takeLatest(ActionTypes.IFRAME_FETCH_PARTNER_VISIT_TYPES, iframeFetchPartnerVisitTypes),
    takeLatest(ActionTypes.IFRAME_FETCH_FREE_DOCTORS, iframeFetchFreeDoctors),
    takeLatest(ActionTypes.FETCH_PARTNER_VISIT_TYPE, fetchPartnerVisitType),
    takeLatest(ActionTypes.IFRAME_FETCH_PARTNER_VISIT_TYPE, iframeFetchPartnerVisitType),
  ]);
}
